<template>
  <modal
    name="aliveDialog"
    ref="aliveDialog"
    class="vue-dialog alive-dialog"
    :width="`${window.innerWidth - 50}px`"
    :height="`${window.innerHeight - 50}px`"
    :shiftX="0.5"
    :shiftY="0.25"
    id="aliveDialog"
  >
    <div class="vue-dialog-content">
      <div class="vue-dialog-content-title">
        {{ $t("components.AliveDialog.dialog.title") }}
      </div>
      <div class="line-15px"></div>
      <div class="content">
        <json-viewer :value="alive" :expand-depth="3" copyable></json-viewer>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button
        v-on:click="hide()"
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.AliveDialog.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";

// Displays a modal dialog with isAlive endpoint data
// @group Dialogs
export default {
  name: "AliveDialog",
  data() {
    return {
      window: window,
      alive: null,
    };
  },
  mixins: [Ui],
  mounted() {
    this.eventHub.$on("aliveDialogUpdate", (alive) => {
      this.alive = alive;
    });
    this.eventHub.$on("aliveDialogShow", (alive) => {
      this.alive = alive;
      this.show();
    });
    this.eventHub.$on("aliveDialogHide", this.hide);
  },
  methods: {
    // @vuese
    // Shows modal window
    // @arg `void`
    show() {
      this.$modal.show("aliveDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("aliveDialog");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";

#aliveDialog.vue-dialog {
  .vue-dialog-content {
    li {
      padding-left: 30px;

      [data-icon="check"] {
        top: 0;
        left: -10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media (min-width: 800px) {
  #aliveDialog::v-deep .vm--modal {
    [data-icon="caret-left"] {
      top: 77px;
    }
  }
}
</style>
<style lang="scss">
.alive-dialog {
  .jv-code {
    height: calc(100vh - 100px - 77px);
    overflow: scroll !important;
  }
}
</style>
