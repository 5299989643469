<template>
  <div v-if="show" class="context-menu" :style="{left: left, top: top}">
    <font-awesome-icon icon="caret-left"></font-awesome-icon>
    <div class="btn" v-on:click="setOrigin">
      <img src="../../../public/img/markers/origin.svg" :alt="$t('components.ContextMenu.label.setOrigin')" />
      {{ $t('components.ContextMenu.label.setOrigin') }}
    </div>
    <div class="divider" />
    <div class="btn" v-on:click="addWaypoint">
      <img src="../../../public/img/markers/origin.svg" :alt="$t('components.ContextMenu.label.addWaypoint')" />
      {{ $t('components.ContextMenu.label.addWaypoint') }}
    </div>
    <div class="btn" v-on:click="setDestination">
      <img src="../../../public/img/markers/destination.svg" :alt="$t('components.ContextMenu.label.setDestination')" />
      {{ $t('components.ContextMenu.label.setDestination') }}
    </div>
  </div>
</template>

<script>

// Component handles `Map` context menu
// @group Map
export default {
  name: "ContextMenu",
  data() {
    return {
      left: "50%",
      top: "50%",
      ll: null,
      show: false
    };
  },
  mounted() {
    this.eventHub.$on("contextMenu", data => {
      this.showAt({
        left: `${data.containerPoint.x + 5}px`,
        top: `${data.containerPoint.y + 5}px`,
        ll: data.latlng
      });
    });

    this.eventHub.$on("mapClick", this.hide);
    this.eventHub.$on("mapZoom", this.hide);
    this.eventHub.$on("moveend", this.hide);
  },
  methods: {
    adjustPosition(pos) {
      this.left = pos.left;
      this.top = pos.top;
    },
    showAt(pos) {
      this.adjustPosition(pos);
      this.show = true;
      this.ll = pos.ll;
    },
    hide() {
      this.show = false;
    },
    setOrigin() {
      // @vuese
      // Set origin to context menu (click) location
      // @arg `Object` LatLng
      this.eventHub.$emit("setOrigin", {ll:this.ll, mode: 'point'});
      // @vuese
      // Signal UI update suggestion
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
      this.hide();
    },
    setDestination() {
      // @vuese
      // Set destination to context menu (click) location
      // @arg `Object` LatLng
      this.eventHub.$emit("setDestination", {ll:this.ll, mode: 'point'});
      // @vuese
      // Signal UI update suggestion
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
      this.hide();
    },
    addWaypoint() {
      let index = this.$store.getters.via.length;
      this.eventHub.$emit(`addWaypoint_${index}`, {point: this.ll, mode: 'point'});
      this.hide();
    }
  }
};
</script>

<style scoped lang="scss">
.context-menu {
  position: absolute;
  width: auto;
  height: auto;
  border-radius: 6px;
  background-color: white;

  .btn {
    position: relative;
    line-height: 1.1;
    font-size: 1rem;
    font-weight: 400;
    height: auto;
    padding: 10px 15px 9px 34px;
    width: 90px;
    cursor: pointer;
    border-radius: 6px;

    img {
      height: 22px;
      position: absolute;
      top: 7px;
      left: 9px;
      width: 20px;
    }

    &:hover {
      background-color: #f3fff6;
      color: black;
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #d2d2d2;
  }

  [data-icon="caret-left"] {
    position: absolute;
    left: -6px;
    top: -18px;
    font-size: 40px;
    color: #349a51;
    z-index: 20;
    transform: rotate(45deg);
  }
}
</style>
