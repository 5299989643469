<script>

// @vuese
// Generic user locator using the in-browser methods
// @group Mixins
export default {
  name: "Locator",
  methods: {
    // @vuese
    // Retrieves user geolocation
    // @arg `Bool` {retry}
    getUserLocation(retry = false) {
      const $self = this;

      if (navigator.geolocation) {
        const opts = {
          timeout: retry ? 30000 : 5000,
          enableHighAccuracy: !retry,
          maximumAge: retry ? Infinity : 0,
        };

        navigator.geolocation.getCurrentPosition(
          (location) => {
            const coords = {
              lat: location.coords.latitude,
              lng: location.coords.longitude,
            };
            // @vuese
            // Update Locator with new coords
            // @arg `Object` LatLng
            $self.eventHub.$emit("updateLocator", coords);
            // @vuese
            // Move map center to new coords
            // @arg `Object` LatLng
            $self.eventHub.$emit("flyTo", coords);
            $self.point = coords;
            $self.commit();

            $self.mode = "myLocation";

            if (navigator.geolocation.watchPosition) {
              if ($self.watchId) {
                navigator.geolocation.clearWatch($self.watchId);
              }

              $self.watchId = navigator.geolocation.watchPosition(
                (pos) => {
                  const posCoords = {
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                  };

                  if ("myLocation" != $self.mode) {
                    return navigator.geolocation.clearWatch($self.watchId);
                  }

                  // @vuese
                  // Update Locator with new coords
                  // @arg `Object` LatLng
                  $self.eventHub.$emit("updateLocator", posCoords);
                  $self.point = posCoords;
                  $self.commit();
                },
                null,
                opts
              );
            }
          },
          function (err) {
            if (true === retry) {
              throw err;
            } else {
              $self.getUserLocation(true);
            }
          },
          opts
        );
      } else {
        console.log("error.geolocationUnsupported.title");
      }
    },
    validateCoordinates(coords) {
      /**
       * @link https://stackoverflow.com/a/18690202/1515746
       */
      return coords.match(
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/ 
      );
    },
  }
};
</script>