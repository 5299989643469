<template>
  <div
    class="plan-found-with-errors"
    v-if="visible"
    :class="{ active: active }"
  >
    <p class="alert alert-warning">
      <font-awesome-icon
        :icon="['fas', 'exclamation-triangle']"
      ></font-awesome-icon>
      {{
        $t("components.PlanFoundWithErrors.message.exceptionsFound", {
          cnt: errorsCnt,
        })
      }}
      <a v-on:click="openModal()">
        {{
        $t("components.PlanFoundWithErrors.buttons.details")
      }}
      </a>
    </p>
  </div>
</template>

<script>
import Converter from "../mixins/Converter.vue";

// Handles switching between different fetched plans
// @group Map
export default {
  name: "PlanFoundWithErrors",
  mixins: [Converter],
  mounted() {
    this.eventHub.$on("getPlans", () => {
      this.hide();
    });
    this.eventHub.$on("planFoundWithErrors", (payload) => {
      this.errorsCnt = payload.errorsCnt;

      this.show();
    });
  },
  data() {
    return {
      visible: false,
      active: false,
      errorsCnt: 0,
    };
  },
  computed: {
    response() {
      return this.$store.getters.lastResponse;
    },
  },
  methods: {
    show() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      } // end if
      this.visible = true;
      this.active = true;
    },
    hide() {
      this.active = false;
      this.timeout = setTimeout(() => {
        this.visible = false;
      }, 1000);
    },
    openModal() {
      this.eventHub.$emit("plannerExceptionsDialogShow", this.response.routingRequestViolations);
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";

.plan-found-with-errors {
  padding-bottom: 15px;

  .alert {
    border-radius: 5px;
    padding: 20px 30px 20px 40px;
    position: relative;
    font-weight: 500;
    font-size: 15px;

    &.alert-warning {
      background: var(--toastlog-yellow-main) !important;
      border-color: var(--toastlog-yellow-main-border) !important;
      box-shadow: var(--toastlog-yellow-main-shadow) 0 2px 6px;
      color: black;
    }

    small {
      font-size: 11px;
      font-family: monospace;
    }
    svg {
      position: absolute;
      left: 11px;
      top: 26px;
      font-size: 18px;
    }
    a {
      color: black;
      text-decoration: underline;
      &:hover {
        color: #27793e;
      }
    }
  }

  &.active {
    animation: fadein 1s;
    animation-fill-mode: forwards;
  }
  &:not(.active) {
    animation: fadeout 1s;
    animation-fill-mode: forwards;
    // opacity: 0;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
</style>
