<script>
// @vuese
// Takes application data and converts them into the format different API endpoints `needs`
// @group Engine/Mixins
export default {
  name: "RequestBuilder",
  methods: {
    buildPayload() {
      const state = this.$store.getters.panelOptions;
      const payload = {};

      payload.origin = this.getOrigin(state);
      payload.destination = this.getDestination(state);

      if (this.$store.getters.via.length) {
        payload.waypoints = this.getWaypoints(this.$store);
      } // end if

      payload.departure = this.getDeparture(state);
      payload.searchTimeDirection = "FORWARD_TIME";
      payload.timeWindowHours = 48;

      payload.modesSettings = {};

      payload.modesSettings.allowedTransportModes = this.getTransport(state);

      if (state.checkboxes.publicTransport) {
        const fareInfo = this.getUserFares(state);
        payload.modesSettings.ptSettings = {
          allowedPtVehicles: this.getPT(state),
          maxPtInterchanges: state.transfers,
          lowFloorTripsOnly: state.loweredFloorOnly,
          stairsFreeWalkSegments: state.stairsFreeWalkSegments,
          transferSpeed: this.getTransferSpeed(state.transferSpeed),
          barrierFreeStopsOnly: state.barrierFreeStopsOnly,
        };
        if (fareInfo) {
          payload.modesSettings.ptSettings.userFareInfo = fareInfo;
        }
      }

      if (state.checkboxes.car) {
        payload.modesSettings.carSettings = {
          maxDistanceMeters: state.drivingKms * 1000,
          pricePerKmInCzk: state.pricePerKmInCzk,
          useTollFreeRoads: state.useTollFreeRoads,
          parkCar: state.parkCar,
          parkingZoneTypes: state.parkingZoneTypes,
          carParkingTypes: state.carParkingTypes,
          maxParkingPriceCzkPerHour: state.maxParkingPriceCzkPerHour,
          expectedParkingTimeMinutes: state.expectedParkingTimeMinutes,
          blockedParkingIds: state.blockedParkingIdsC,
          operatorsAllowedParking: state.parkingProviders,
        };
      }

      if (state.checkboxes.cycling) {
        payload.modesSettings.bicycleSettings = {
          // avgSpeedKmh: this.getCycling(state),
          maxDistanceMeters: state.cyclingKms * 1000,
          parkBicycle: state.parkBicycle,
          bicycleInPt: state.bicycleInPt,
          bicycleSpeed: this.getCycling(state.cycling),
          avoidSteepClimbs: state.avoidSteepClimbsB,
          blockedParkingIds: state.blockedParkingIdsB,
          avoidStairs: state.avoidStairsCycling
        };
      }

      if (state.checkboxes.sharedBikes) {
        payload.modesSettings.sharedBikeSettings = {
          providerSettings: {
            providerIds: state.bikesharing,
            returnBestOnlyRoute: state.returnBestOnlyRouteBS,
          },
          sharedBikeSpeed: this.getCycling(state.cyclingBS),
          maxDistanceMeters: state.cyclingKmsBS * 1000,
          avoidSteepClimbs: state.avoidSteepClimbsBS,
          onlyElectric: state.onlyElectricBS,
          blockedParkingIds: state.blockedParkingIdsBS,
        };
      }
      if (state.checkboxes.sharedScooters) {
        payload.modesSettings.sharedScooterSettings = {
          providerSettings: {
            providerIds: state.scootersharing,
            returnBestOnlyRoute: state.returnBestOnlyRouteSS,
          },
          onlyElectric: state.onlyElectricSS,
          maxDistanceMeters: state.scooterSharingKms * 1000,
        };
      }

      if (state.checkboxes.sharedMopeds) {
        payload.modesSettings.sharedMopedSettings = {
          providerSettings: {
            providerIds: state.mopedsharing,
            returnBestOnlyRoute: state.returnBestOnlyRouteMS,
          },
          allowCombustion: state.allowCombustionMS,
          allowElectric: state.allowElectricMS,
          maxDistanceMeters: state.mopedSharingKms * 1000,
        };
      }

      if (state.checkboxes.sharedCars) {
        payload.modesSettings.sharedCarSettings = {
          providerSettings: {
            providerIds: state.carsharing,
            returnBestOnlyRoute: state.returnBestOnlyRouteCS,
          },
          allowCombustion: state.allowCombustionCS,
          allowElectric: state.allowElectricCS,
          blockedParkingIds: state.blockedParkingIdsCS,
          maxDistanceMeters: state.carSharingKms * 1000,
        };
      }

      if (state.checkboxes.taxi) {
        payload.modesSettings.onDemandSettings = {
          providerSettings: {
            providerIds: state.taxisharing,
            returnBestOnlyRoute: state.returnBestOnlyRouteOD,
          },
          maxDistanceMeters: state.taxiKms * 1000,
        };
      }

      if (state.checkboxes.walking) {
        payload.modesSettings.walkSettings = {
          walkSpeed: this.getWalk(state),
          maxDistanceMeters: state.walkKms * 1000,
          avoidStairs: state.avoidStairsWalk,
        };
      }

      payload.searchSettings = {
        maxResults: 100,
      };

      return payload;
    },
    getOrigin(state) {
      if ("stopIds" == state.origin.mode) {
        let stopIds = state.origin.stopIds.replace(/ /g, "").split(",");
        return {
          stopIds: stopIds,
        };
      } else {
        return {
          geoLocation: {
            lat: state.origin.point.lat,
            lon: state.origin.point.lng,
          },
        };
      } // end if-else-if-else
    },
    getDestination(state) {
      if ("stopIds" == state.destination.mode) {
        let stopIds = state.destination.stopIds.replace(/ /g, "").split(",");
        return {
          stopIds: stopIds,
        };
      } else {
        return {
          geoLocation: {
            lat: state.destination.point.lat,
            lon: state.destination.point.lng,
          },
        };
      } // end if-else-if-else
    },
    getWaypoints(state) {
      let via = [];

      state.getters.via.forEach((waypoint) => {
        if ("stopIds" == waypoint.mode) {
          let stopIds = waypoint.stopIds.replace(/ /g, "").split(",");
          via.push({
            stopIds: stopIds,
          });
        } else {
          via.push({
            geoLocation: {
              lat: waypoint.point.lat,
              lon: waypoint.point.lng,
            },
          });
        } // end if
      });

      return via;
    },
    getDeparture(state) {
      let dt = state.time;
      let offset = dt.getTimezoneOffset();
      let o = "";

      o += dt.getFullYear();
      o += "-";
      o += ("0" + (1 + dt.getMonth()).toString()).slice(-2);
      o += "-";
      o += ("0" + dt.getDate().toString()).slice(-2);
      o += "T";
      o += ("0" + dt.getHours().toString()).slice(-2);
      o += ":";
      o += ("0" + dt.getMinutes().toString()).slice(-2);
      o += ":";
      o += ("0" + dt.getSeconds().toString()).slice(-2);
      o += ".000";
      o +=
        (offset < 0 ? "+" : "-") +
        ("0000" + parseInt(Math.abs(offset / 60))).slice(-2);
      o += ":00";

      return o;
    },
    getWalk(state) {
      switch (state.walk) {
        case "walkSlow":
          return "SLOW";
        case "walkAverage":
          return "MEDIUM";
        case "walkFast":
          return "QUICK";
      }
    },
    getTransferSpeed(speed) {
      switch (speed) {
        case "transferSlow":
          return "SLOW";
        case "transferAverage":
          return "MEDIUM";
        case "transferFast":
          return "QUICK";
      }
    },
    getDriving(state) {
      switch (state.driving) {
        case "driveSlow":
          return 30.0;
        case "driveAverage":
          return 60.0;
        case "driveFast":
          return 90.0;
      }
    },
    getCycling(val) {
      switch (val) {
        case "cycleSlow":
        case "rideSlow":
          return "SLOW";
        case "cycleAverage":
        case "rideAverage":
          return "MEDIUM";
        case "cycleFast":
        case "rideFast":
          return "QUICK";
      } // end switch
    },
    getTransport(state) {
      const modes = [];

      if (state.checkboxes.publicTransport) {
        modes.push("PT");
      }
      if (state.checkboxes.car) {
        modes.push("CAR");
      }
      if (state.checkboxes.cycling) {
        modes.push("BICYCLE");
      }
      if (state.checkboxes.sharedBikes) {
        modes.push("SHARED_BIKE");
      }
      if (state.checkboxes.sharedScooters) {
        modes.push("SHARED_SCOOTER");
      }
      if (state.checkboxes.sharedMopeds) {
        modes.push("SHARED_MOPED");
      }
      if (state.checkboxes.sharedCars) {
        modes.push("SHARED_CAR");
      }
      if (state.checkboxes.taxi) {
        modes.push("TAXI");
      }
      if (state.checkboxes.walking) {
        modes.push("WALK");
      }

      return modes;
    },
    getPT(state) {
      return state.pt;
    },
    getUserFares(state) {
      const fromDt = new Date();
      const toDt = new Date();
      fromDt.setFullYear(fromDt.getFullYear() - 1);
      toDt.setFullYear(toDt.getFullYear() + 1);

      let zones = [];
      state.userFares.forEach((fare) => {
        zones.push(fare);
      });

      const paidZones = {
        customerProfiles: [
          {
            id: state.cp,
            validFrom: fromDt.toISOString(),
            validTo: toDt.toISOString(),
          },
        ],
      };
      if (zones.length) {
        paidZones.coupons = [
          {
            customerProfileId: state.cp,
            validInZones: zones,
            validFrom: fromDt.toISOString(),
            validTo: toDt.toISOString(),
          },
        ];
      } // end if

      return paidZones;
    },
    buildSegmentsPayload() {
      const mapCenter = this.$store.getters.mapCenter;
      const state = this.$store.getters.panelOptions;
      const payload = {};

      payload.lat = mapCenter.lat;
      payload.lon = mapCenter.lng;
      payload.radius = 3000;
      payload.maxCount = 100;
      payload.climbs = this.getClimbs(state);
      payload.traffic = this.getTraffic(state);
      payload.stairs = this.getStairs(state);
      payload.oneways = this.getOneways(state);
      payload.pavements = this.getPavements(state);
      payload.surface = this.getSurface(state);

      return payload;
    },
  },
};
</script>