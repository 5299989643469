<script>
import L from "leaflet";
import "leaflet-polylinedecorator";
import "polyline-encoded";
import GeojsonStyler from "./GeojsonStyler.vue";

// Takes selected plan `json` data and converts them into interactive `Map` layers / paths
// @see Singleton
// @group Map/Mixins
export default {
  name: "Painter",
  mixins: [GeojsonStyler],
  data() {
    return {
      segments: [],
      style: this.appConfig.map.plan,
      cache: {},
      pathwayStructures: {
        STAIRS: {
          icon: "STAIRS.svg",
        },
        ELEVATOR: {
          icon: "ELEVATOR.svg",
        },
        ESCALATOR: {
          icon: "ESCALATOR.svg",
        },
        TRAVELATOR: {
          icon: "TRAVELATOR.svg",
        },
        EXIT: {
          icon: "EXIT.svg",
        },
      }
    };
  },
  mounted() {
    this.eventHub.$on("paintPlans", (plans) => {
      this.paintPlans(plans);
    });
    this.eventHub.$on("paintSegments", (segments) => {
      this.paintSegments(segments);
      this.repaintPlans();
    });
    this.eventHub.$on("togglePlan", () => {
      this.repaintPlans();
    });
    this.eventHub.$on("repaint", () => {
      this.repaintPlans();
    });

    this.eventHub.$on("planMode", () => {
      this.eventHub.$emit("togglePlan", this.$store.getters.plans);
    });
  },
  methods: {
    paintPlans(plans) {
      plans = this.sortPlans(plans);
      const list = [];

      plans.forEach((plan) => {
        plan.layers = {};

        if (plan.id === this.$store.getters.activePlan) {
          plan.background = this.addBackground(plan);
        } // end if

        plan.trajectory = this.addTrajectory(plan);
        plan.traffic = this.addTraffic(plan);
        plan.surface = this.addSurface(plan);
        plan.steepness = this.addSteepness(plan);
        plan.signs = this.addSigns(plan);
        plan.structures = this.addStructures(plan);
        plan.osmLinks = this.addOsmLinks(plan);
        plan.origin = this.addOrigin(plan);
        plan.destination = this.addDestination(plan);

        list.push(plan);
      });
      this.eventHub.$emit("pushPlans", list);
      this.eventHub.$emit("planDetail", this.$store.getters.activePlan);
    },
    sortPlans(plans) {
      const activePlanId = this.$store.getters.activePlan;
      const separatedPlans = {
        active: [],
        bikeFriendly: [],
        fast: [],
        alternative: [],
      };
      plans.forEach((plan) => {
        if (activePlanId == plan.id) {
          separatedPlans.active.push(plan);
        } else if ("bikeFriendly" == plan.type) {
          separatedPlans.bikeFriendly.push(plan);
        } else if ("fast" == plan.type) {
          separatedPlans.fast.push(plan);
        } else {
          separatedPlans.alternative.push(plan);
        }
      });

      let sorted = [];

      sorted = sorted.concat(sorted, separatedPlans.alternative);
      sorted = sorted.concat(sorted, separatedPlans.fast);
      sorted = sorted.concat(sorted, separatedPlans.bikeFriendly);
      sorted = sorted.concat(sorted, separatedPlans.active);

      return sorted;
    },
    repaintPlans() {
      this.eventHub.$emit("clearPlans");
      const plans = this.$store.getters.plans;
      if (plans.length) {
        this.paintPlans(plans);
      }
    },
    paintSegments(segments) {
      this.eventHub.$emit("clearSegments");
      const list = [];
      let i = 0;
      segments.features.forEach((segment) => {
        ++i;
        const coords = [];
        segment.geometry.coordinates.forEach((ll) => {
          coords.push(L.latLng(ll[1], ll[0]));
        });
        const layer = L.polyline(coords, this.appConfig.map.segment);

        layer.on("click", (ev) => {
          ev.originalEvent.preventDefault();

          const popup = L.popup()
            .setLatLng(ev.latlng)
            .setContent(
              `<pre>${JSON.stringify(segment.properties, null, 4)}</pre>`
            );
          this.eventHub.$emit("showPopup", popup);
        });

        list.push(layer);
      });
      const collection = L.featureGroup(list);
      collection.id = `positive_segments`;
      this.eventHub.$emit("pushSegments", collection);
    },
    addBackground(plan) {
      const self = this;
      const layers = [];
      const id = `plan_${plan.id}_background`;

      plan.segments.forEach((segment) => {
        if (
          "DROPOFF" == segment.segmentType ||
          "PICKUP" == segment.segmentType
        ) {
          const mode = segment.transferType ? "TRANSFER" : segment.segmentType;
          const marker = L.marker(
            L.latLng(
              segment.start.geoLocation.lat,
              segment.start.geoLocation.lon
            ),
            {
              icon: L.icon({
                iconUrl: `./img/markers/${mode}.svg`,
                iconSize: [18, 18],
                iconAnchor: [9, 9],
              }),
              title: mode.toLowerCase(),
            }
          );

          layers.push(marker);
        } else {
          if (!this.isPathway(segment) && !this.isBikePushingSegment(segment)) {
            const layer = L.Polyline.fromEncoded(
              segment.geometry,
              this.getOptions("background", plan.id, segment)
            );
            layers.push(layer);
          } // end if
        } // end if-else
      });

      const collection = L.featureGroup(layers);
      collection.id = id;

      collection.on("click", (ev) => {
        ev.originalEvent.preventDefault();
        this.eventHub.$emit("pickPlan", plan);
      });

      return collection;
    },
    addHighlight(plan) {
      const id = `plan_${plan.id}_highlight`;
      const layer = L.polyline(
        plan._.allLatLongsExclWalk,
        this.getOptions("highlight", id)
      );

      const collection = L.featureGroup([layer]);
      collection.id = id;

      collection.on("click", (ev) => {
        ev.originalEvent.preventDefault();
        this.eventHub.$emit("pickPlan", plan);
      });

      return collection;
    },
    addTrajectory(plan) {
      const self = this;

      const layers = [];
      const id = `plan_${plan.id}_trajectory`;

      plan.segments.forEach((segment) => {
        if ("PICKUP" == segment.segmentType) {

          return; // continue;

        } else if (this.isPathway(segment)) {
          const key = "pathwayStructures";
          const optsKey = "walkUnderground";

          const polyLine = L.Polyline.fromEncoded(segment.geometry);
          const lls = polyLine._latlngs;
          let path = [];

          const startIndexes = [];
          const endIndexes = [];

          segment[key].forEach((point) => {
            startIndexes.push(point.startIndex);
            endIndexes.push(point.endIndex);
          });

          const chunks = [];
          let chunk = [];
          let up = true;

          lls.forEach((ll, index) => {
            if (startIndexes.indexOf(index) >= 0) {
              up = false;
            } // end if
            if (endIndexes.indexOf(index) >= 0) {
              up = true;
            } // end if

            chunk.push(ll);

            if (!up) {
              chunks.push(chunk);
              chunk = [];
            } // end if

          });

          if (chunk.length) {
            chunks.push(chunk);
          } // end if

          chunks.forEach((bit) => {

            layers.push(
              L.polyline(
                bit,
                this.getOptions(
                  segment.transportMode.toLowerCase(),
                  plan.id,
                  segment
                )
              )
            );
          });

          if (segment.pathwayStructures) {
              segment.pathwayStructures.forEach((point) => {
                if (point.endIndex && point.endIndex != point.startIndex) {
                  layers.push(self.structureConnection(point, lls, true));
                } // end if
              }); // end foreach
            } // end if

        } else if (this.isBikePushingSegment(segment)) {
          layers.push(
            L.Polyline.fromEncoded(
              segment.geometry,
              this.getOptions(
                segment.transportMode.toLowerCase(),
                plan.id,
                segment
              )
            )
          );

          const key = "walkWithBikeSegments";
          const optsKey = "walkBikePush";
          const polyLine = L.Polyline.fromEncoded(segment.geometry);
          const lls = polyLine._latlngs;
          let path = [];

          const startIndexes = [];
          const endIndexes = [];

          segment[key].forEach((point) => {
            path = lls.slice(point.startIndex, point.endIndex + 1);

            layers.push(
              L.polyline(path, this.getOptions("whiteBackground", plan.id))
            );

            layers.push(L.polyline(path, this.getOptions(optsKey, plan.id)));

            startIndexes.push(point.startIndex);
            endIndexes.push(point.endIndex);
          });
        } else {
          const layer = L.Polyline.fromEncoded(
            segment.geometry,
            this.getOptions(
              segment.transportMode.toLowerCase(),
              plan.id,
              segment
            )
          );
          layers.push(layer);
        } // end if-else
      });

      const collection = L.featureGroup(layers);
      collection.id = id;

      collection.on("click", (ev) => {
        ev.originalEvent.preventDefault();
        this.eventHub.$emit("pickPlan", plan);
      });

      return collection;
    },
    addTraffic(plan) {
      if (plan.id != this.$store.getters.activePlan) {
        return null;
      }

      const layers = [];
      const id = `traffic_${plan.id}`;

      plan.segments.forEach((segment) => {
        if (
          segment.rideDetails &&
          segment.rideDetails.roadSituation &&
          segment.rideDetails.roadSituation.trafficSituation
        ) {
          segment.rideDetails.roadSituation.trafficSituation.forEach(
            (trafficSegment) => {
              const options = this.style.common;
              options.opacity = 0.9;
              switch (trafficSegment.trafficIntensity) {
                case 1:
                  options.color = "hsl(96,100%,50%)";
                  break;
                case 2:
                  options.color = "hsl(72,100%,50%)";
                  break;
                case 3:
                  options.color = "hsl(48,100%,50%)";
                  break;
                case 4:
                  options.color = "hsl(23.999999999999993,100%,50%)";
                  break;
                case 5:
                  options.color = "hsl(0,100%,50%)";
                  break;
              }

              const layer = L.polyline(trafficSegment.locations, options);
              layers.push(layer);
            }
          );
        }
      });

      const collection = L.featureGroup(layers);
      collection.id = id;

      collection.on("click", (ev) => {
        ev.originalEvent.preventDefault();
        this.eventHub.$emit("pickPlan", plan);
      });

      return collection;
    },
    addSurface(plan) {
      if ("surface" != this.$store.getters.planMode) {
        return null;
      } // end if
      if (plan.id != this.$store.getters.activePlan) {
        return null;
      } // end if

      const cached = this.cache.key;

      if (cached) {
        return cached;
      } else {
        const $self = this;
        const id = `plan_${plan.id}_surface`;
        const gJsonLayer = new L.GeoJSON(plan.geoJson);
        gJsonLayer.eachLayer((layer) => {
          layer.setStyle($self.surface(layer));
        });

        const collection = L.featureGroup([gJsonLayer]);
        collection.id = id;

        this.cache[plan._.signature] = collection;

        return collection;
      }
    },
    addSteepness(plan) {
      if ("steepness" != this.$store.getters.planMode) {
        return null;
      } // end if
      if (plan.id != this.$store.getters.activePlan) {
        return null;
      } // end if

      const cached = this.cache.key;

      if (cached) {
        return cached;
      } else {
        const $self = this;
        const id = `plan_${plan.id}_steepness`;
        const gJsonLayer = new L.GeoJSON(plan.geoJson);
        gJsonLayer.eachLayer((layer) => {
          layer.setStyle($self.slope(layer));
        });

        const collection = L.featureGroup([gJsonLayer]);
        collection.id = id;

        this.cache[plan._.signature] = collection;

        return collection;
      }
    },
    addSigns(plan) {
      const id = `plan_${plan.id}_signs`;
      const markers = [];

      const whitelist = [
        "STEPS",
        "STEEP_UPHILL",
        "HIGH_STRESS",
        "BAD_SURFACE",
        "WARNING",
        "FOOTWAY",
        "CROSSING",
        "NO_ENTRY",
      ];
      const map = {
        STEPS: "stair_sign_36dp.png",
        STEEP_UPHILL: "elev_sign_36dp.png",
        HIGH_STRESS: "warning_cars_36.png",
        BAD_SURFACE: "bad_surface_sign_36.png",
        WARNING: "warning_sign_36dp.png",
        FOOTWAY: "dismount_sign_36.png",
        CROSSING: "warning_crossing_36.png",
        NO_ENTRY: "dismount_sign_36.png",
      };

      let i = 0;
      plan._.warnings.forEach((loc) => {
        ++i;

        if (whitelist.indexOf(loc.warning.type) >= 0) {
          const marker = L.marker(L.latLng(loc.lat, loc.lon), {
            icon: L.icon({
              iconUrl: `./img/signs/${map[loc.warning.type]}`,
              iconSize: [38, 38],
              iconAnchor: [19, 19],
            }),
            title: this.$t(`components.Painter.title.${loc.warning.type}`),
          });
          markers.push(marker);
        }
      });

      const collection = L.featureGroup(markers);
      collection.id = id;
      return collection;
    },
    addStructures(plan) {
      const self = this;

      const layers = [];
      const id = `plan_${plan.id}_structures`;

      plan.segments.forEach((segment) => {
        if (this.isPathway(segment)) {
          const polyLine = L.Polyline.fromEncoded(segment.geometry);

          const lls = polyLine._latlngs;
          const usedCoords = [];

          if (segment.pathwayStructures) {
            segment.pathwayStructures.forEach((point) => {
              let locHash = point.startIndex.toString() + (point.endIndex ? "_" + point.endIndex.toString() : "");
              let locIsUsed = usedCoords.indexOf(locHash) >= 0;
              usedCoords.push(locHash);

              let structure = self.structureMarker(point, lls, locIsUsed);
              if (structure) {
                layers.push(structure);
                layers.push(self.structureConnection(point, lls));
              } // end if
            }); // end foreach
          } // end if
        } // end if
      });

      const collection = L.featureGroup(layers);
      collection.id = id;

      collection.on("click", (ev) => {
        ev.originalEvent.preventDefault();
        this.eventHub.$emit("pickPlan", plan);
      });

      return collection;
    },
    addOsmLinks(plan) {
      const id = `plan_${plan.id}_osm`;
      const layers = [];

      if (this.$store.getters.osm) {
        plan.segments.forEach((segment) => {
          if (
            segment.additionalData &&
            segment.additionalData.osmLinks &&
            segment.additionalData.osmLinks.length
          ) {
            segment.additionalData.osmLinks.forEach((link) => {
              if (link.polyline) {
                let bgLayer = L.Polyline.fromEncoded(link.polyline, {
                  color: "#ffd500",
                  weight: 6,
                });
                let layer = L.Polyline.fromEncoded(
                  link.polyline,
                  this.getOsmOptions(link.linkedNetworks)
                );
                layers.push(bgLayer, layer);
              } else {
                const point = new L.CircleMarker(
                  L.latLng(link.mappedLat, link.mappedLon),
                  {
                    radius: 5,
                    color: "#FD4646",
                    interactive: false,
                  }
                );
                layers.push(point);
              } // end if
            });
          } // end if
        });
      } // end if

      if (layers.length) {
        const collection = L.featureGroup(layers);
        collection.id = id;
        return collection;
      } else {
        return null;
      }
    },
    addOrigin(plan) {
      if ("visibleArea" == this.$store.getters.origin.mode) {
        const id = `plan_${plan.id}_origin`;
        const ll = plan._.allLatLongs[0];
        const marker = L.marker(L.latLng(ll.lat, ll.lng), {
          icon: L.icon({
            iconUrl: "./img/markers/origin.svg",
            iconSize: [25, 32],
            iconAnchor: [12.5, 32],
          }),
        });

        const collection = L.featureGroup([marker]);
        collection.id = id;
        return collection;
      } else {
        return null;
      }
    },
    addDestination(plan) {
      if ("oneWay" == this.$store.getters.destination.mode) {
        const id = `plan_${plan.id}_destination`;
        const ll = plan._.allLatLongs[plan._.allLatLongs.length - 1];
        const marker = L.marker(L.latLng(ll.lat, ll.lng), {
          icon: L.icon({
            iconUrl: "./img/markers/destination.svg",
            iconSize: [25, 32],
            iconAnchor: [12.5, 32],
          }),
        });

        const collection = L.featureGroup([marker]);
        collection.id = id;
        return collection;
      } else {
        return null;
      }
    },
    addArrows(plan) {
      if ("trajectory" != this.$store.getters.planMode) {
        return null;
      }
      if (plan.id != this.$store.getters.activePlan) {
        return null;
      }

      let color = this.appConfig.map.plan._special.arrow.color.generic;
      if ("bikeFriendly" == plan._.type) {
        color = this.appConfig.map.plan._special.arrow.color.bikeFriendly;
      } else if ("fast" == plan._.type) {
        color = this.appConfig.map.plan._special.arrow.color.fast;
      }

      const decorator = L.polylineDecorator(plan._.allLatLongsExclWalk, {
        patterns: [
          {
            offset: 0,
            repeat: 20,
            symbol: L.Symbol.arrowHead({
              pixelSize: this.appConfig.map.plan._special.arrow.pixelSize,
              polygon: false,
              pathOptions: {
                stroke: true,
                weight: this.appConfig.map.plan._special.arrow.weight,
                color: color,
              },
            }),
          },
        ],
      });

      const collection = L.featureGroup([decorator]);
      collection.id = "arrows";
      return collection;
    },
    getOptions(type, id, _segment = null, inactive = false) {
      const options = this.style.common;
      options.id = id;

      if (this.style.color[type] && this.$store.getters.activePlan == id) {
        options.color = this.style.color[type];
      } else {
        options.color = this.style.color.generic;
      } // end if-else

      if (this.style.weight[type]) {
        options.weight = this.style.weight[type];
      } else {
        options.weight = this.style.weight.generic;
      } // end if-else

      if ("walkUnderground" == type) {
        options.dashArray = this.style._special.walkUnderground.dashArray;
      } else if ("walkBikePush" == type) {
        options.dashArray = this.style._special.walkBikePush.dashArray;
      } else {
        options.dashArray = undefined;
      } // end if

      if ("structureConnection" == type) {
        options.color = inactive ? this.style.color.generic : "#ff0000";
      } // end if

      return options;
    },
    getOsmOptions(linkedNetworks) {
      const options = {
        weight: 5,
        color: "#fff",
        opacity: 1,
      };

      if (linkedNetworks.includes("WALK_NETWORK")) {
        options.color = "#474747";
      } else if (linkedNetworks.includes("BICYCLE_NETWORK")) {
        options.color = "#74c476";
      } else if (linkedNetworks.includes("ROAD_NETWORK")) {
        options.color = "#E00000";
      } // end if-elseif

      return options;
    },
    isPathway(segment) {
      if (["WALK", "BICYCLE", "SHARED_BIKE", "SHARED_SCOOTER"].indexOf(segment.transportMode) >= 0) {
        if (segment.pathwayStructures && segment.pathwayStructures.length > 0) {
          return true;
        } // end if
      } // end if
      return false;
    },
    isBikePushingSegment(segment) {
      if ("bicycle" == segment.transportMode.toLowerCase()) {
        if (
          segment.walkWithBikeSegments &&
          segment.walkWithBikeSegments.length > 0
        ) {
          return true;
        } // end if
      } // end if
      return false;
    },
    isLocEqual(first, second) {
      if (first.lat == second.lat) {
        if (first.lng == second.lng) {
          return true;
        } // end if
      } // end if
      return false;
    },
    structureMarker(structure, lls, shift = false) {
      let iconOffset = shift ? 10 : 0;

      if (Object.keys(this.pathwayStructures).indexOf(structure.type) == -1) {
        return null;
      } // end if

      let icon = this.pathwayStructures[structure.type].icon;
      var loc = L.latLng(lls[structure.startIndex].lat, lls[structure.startIndex].lng);

      if (structure.endIndex) {
        let avgLat = (lls[structure.startIndex].lat + lls[structure.endIndex].lat) / 2;
        let avgLng = (lls[structure.startIndex].lng + lls[structure.endIndex].lng) / 2;
        loc = L.latLng(avgLat, avgLng);
      } // end if

      let html = "";
      html += `<div class="structure-marker-inner">`;
      html += `  <img src="./img/markers/${icon}" alt="Icon" />`;
      if ("DOWN" == structure.vertical) {
        html += `    <span class="down"><img src="./img/markers/caret-down-solid.svg" /></span>`;
      } else if ("UP" == structure.vertical) {
        html += `    <span class="up"><img src="./img/markers/caret-up-solid.svg" /></span>`;
      } // end if-else
      html += `</div>`;



      const marker = L.marker(loc,
        {
          icon: L.divIcon({
            html: html,
            iconSize: [30, 30],
            iconAnchor: [iconOffset + 12, iconOffset + ( structure.endIndex && structure.endIndex != structure.startIndex ? 12 : 40 )],
            className: "structure-marker"
          }),
          title: structure.type,
        }
      );

      marker.on("click", (ev) => {
        ev.originalEvent.preventDefault();

        const popup = L.popup()
          .setLatLng(ev.latlng)
          .setContent(`<pre>${JSON.stringify(structure, null, 4)}</pre>`);
        this.eventHub.$emit("showPopup", popup);
      });

      return marker;
    },
    structureConnection(structure, lls, inactive = false) {

      if (structure.endIndex && structure.endIndex != structure.startIndex) {
        return L.polyline([
          L.latLng(lls[structure.startIndex].lat, lls[structure.startIndex].lng),
          L.latLng(lls[structure.endIndex].lat, lls[structure.endIndex].lng)
        ], this.getOptions("structureConnection", undefined, null, inactive));  
      } else {
        let opts = this.getOptions("structureConnection", undefined, null, inactive);
        return L.circleMarker(L.latLng(lls[structure.startIndex].lat, lls[structure.startIndex].lng), {
          radius: 8,
          color: opts.color,
        });
      } // end if-else
    }
  },
};
</script>

<style lang="scss">
.map-vehicle {
  width: 20px;
  height: 20px;

  &.is-vehicle {
    border-radius: 50%;
  }

  color: white;

  svg {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 2px;
    top: 2px;
  }
}
.structure-marker:hover {
  z-index: 10000 !important;
}
.structure-marker-inner {
  width: 25px;
  height: 26px;
  background: #359a51;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  .down, .up {
    display: inline-block;
    position: absolute;
    top: 4px;
    right: -12px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}
.structure-marker-inner img {
  width: 25px;
  height: 25px;
}
</style>