<template>
  <div v-if="false" />
</template>

<script>
import L from "leaflet";

// Displays current visitor location as circular map marker
// @group Map
export default {
  name: "Locator",
  props: ["type", "ll"],
  data() {
    return {
      markerId: null,
      marker: null
    };
  },
  watch: {
    ll(coords) {
      if ("object" == typeof coords) {
        if (null === this.markerId) {
          this.createMarker();
        } else {
          this.updateLocation(coords);
        } 
      }
    }
  },
  mounted() {
    this.listen();
  },
  methods: {
    // @vuese
    // Creates L.marker instance and puts it on the map (while saving its reference)
    // @arg `void`
    createMarker() {
      const $self = this;

      const id = "gpx_locator";

      const coords = L.latLng(this.ll.lat, this.ll.lng);

      const div = document.createElement("div");
      div.className = "my-location";

      const img = document.createElement("img");
      img.src = "./img/markers/location.png";
      img.alt = "Locator";
      div.appendChild(img);

      const marker = L.marker(coords, {
        icon: L.divIcon({
          html: div.outerHTML,
          iconSize: [20, 20],
          iconAnchor: [10, 10],
          className: "pointer-events-enable"
        })
      });

      marker.on("click", () => {
        $self.eventHub.$emit("originPickerShow");
      });

      marker.setOpacity(0);
      marker.id = id;

      this.markerId = id;
      this.marker = marker;
      this.eventHub.$emit("addLayer", marker);
    },
    updateLocation(coords) {
      this.marker.setLatLng(coords);
    },
    listen() {
      this.eventHub.$on("showLocator", this.showLocator);
      this.eventHub.$on("hideLocator", this.hideLocator);
      this.eventHub.$on("updateLocator", location => {
        this.updateLocation(location);
        this.showLocator();
      });
    },
    onclick() {
      this.eventHub.$emit("originPickerShow");
    },
    showLocator() {
      this.eventHub.$emit("showLayer", this.markerId);
    },
    hideLocator() {
      this.eventHub.$emit("hideLayer", this.markerId);
    }
  }
};
</script>

<style lang="scss">
#map {
  .leaflet-div-icon {
    background: none;
    border: none;
    border-color: transparent;
  }
  .my-location {
    width: 20px;
    height: 20px;
    animation: heartbeat 6s infinite;

    img {
      width: 20px;
      height: 20px;
    }

    // &:hover {
    //   filter: brightness(110%);
    //   cursor: pointer;
    // }
  }
  @keyframes heartbeat {
    0% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.2);
    }
  }
}
</style>