<template>
  <div
    :class="show ? 'visible' : 'hidden'"
    class="spinner"
  >
    <font-awesome-icon icon="cog" spin></font-awesome-icon>
  </div>
</template>

<script>

// Generic spinner used for async actions
// @group Map
export default {
  name: "Spinner",
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.eventHub.$on("loading", () => {
      this.show = true;
    });
    this.eventHub.$on("loadingDone", () => {
      this.show = false;
    });
  }
};
</script>

<style scoped lang="scss">
.spinner {
  position: absolute;
    // top: 20px;
    left: calc(50% - 25px/2);
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    margin-top: -22px;

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
  }

  [data-icon] {
    width: 15px;
    color: gray;
    height: 15px;
    margin: 5px;
  }

}
</style>

