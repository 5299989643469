<template>
  <modal
    name="optsDialog"
    ref="optsDialog"
    class="vue-dialog opts-dialog"
    :width="`${window.innerWidth - 50}px`"
    :height="`${window.innerHeight - 50}px`"
    :shiftX="0.5"
    :shiftY="0.25"
    id="optsDialog"
  >
    <div class="vue-dialog-content">
      <div class="vue-dialog-content-title">
        {{ $t("components.OptsDialog.dialog.title") }}
      </div>
      <div class="line-15px"></div>
      <div class="content">
        <json-viewer :value="opts" :expand-depth="3" copyable></json-viewer>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button
        v-on:click="hide()"
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.OptsDialog.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";

// Displays a modal dialog with detailed trip opts
// @group Dialogs
export default {
  name: "OptsDialog",
  data() {
    return {
      window: window,
      opts: null,
    };
  },
  mixins: [Ui],
  mounted() {
    this.eventHub.$on("optsDialogUpdate", (opts) => {
      this.opts = opts;
    });
    this.eventHub.$on("optsDialogShow", (opts) => {
      this.opts = opts;
      this.show();
    });
    this.eventHub.$on("optsDialogHide", this.hide);
  },
  methods: {
    // @vuese
    // Shows modal window
    // @arg `void`
    show() {
      this.$modal.show("optsDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("optsDialog");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";

#optsDialog.vue-dialog {
  .vue-dialog-content {
    li {
      padding-left: 30px;

      [data-icon="check"] {
        top: 0;
        left: -10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media (min-width: 800px) {
  #optsDialog::v-deep .vm--modal {
    [data-icon="caret-left"] {
      top: 77px;
    }
  }
}
</style>
<style lang="scss">
.opts-dialog {
  .jv-code {
    height: calc(100vh - 100px - 77px);
    overflow: scroll !important;
  }
}
</style>
