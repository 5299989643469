<template>
    <div class="planner">
        <template>
            <Logger />
            <Panel />
            <PlanDetail />
            <Map ref="map" />
            <WalkPicker ref="walkPicker" />
            <OperatorDetail ref="operatorDetail" />
            <CarPicker ref="carPicker" />
            <CyclingPicker ref="cyclingPicker" />
            <PricePicker ref="pricePicker" />
            <TransportPicker ref="transportPicker" />
            <PublicTransportPicker ref="publicTransportPicker" />
            <BikesharingPicker ref="bikesharingPicker" />
            <ScootersharingPicker ref="scootersharingPicker" />
            <MopedsharingPicker ref="mopedsharingPicker" />
            <CarsharingPicker ref="carsharingPicker" />
            <TaxisharingPicker ref="taxisharingPicker" />
            <TimePicker ref="timePicker" />
            <OriginPicker ref="originPicker" />
            <WaypointPicker v-for="(waypoint, i) in via" v-bind:key="i" :waypointIndex="i" />
            <DestinationPicker ref="destinationPicker" />
            <SegmentDialog />
            <OptsDialog />
            <PlannerExceptionsDialog />
            <AliveDialog />
            <ResponseDialog />
            <CmdDialog />
            <Engine ref="engine" />
            <Analytics />
            <CookieConsent />
        </template>
    </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import PlanDetail from "@/components/PlanDetail.vue";
import Map from "@/components/Map.vue";
import WalkPicker from "@/components/dialogs/WalkPicker";
import OperatorDetail from "@/components/dialogs/OperatorDetail";
import CarPicker from "@/components/dialogs/CarPicker";
import CyclingPicker from "@/components/dialogs/CyclingPicker";
import PricePicker from "@/components/dialogs/PricePicker";
import TransportPicker from "@/components/dialogs/TransportPicker";
import PublicTransportPicker from "@/components/dialogs/PublicTransportPicker";
import BikesharingPicker from "@/components/dialogs/BikesharingPicker";
import ScootersharingPicker from "@/components/dialogs/ScootersharingPicker";
import MopedsharingPicker from "@/components/dialogs/MopedsharingPicker";
import CarsharingPicker from "@/components/dialogs/CarsharingPicker";
import TaxisharingPicker from "@/components/dialogs/TaxisharingPicker";
import TimePicker from "@/components/dialogs/TimePicker";
import OriginPicker from "@/components/dialogs/OriginPicker";
import WaypointPicker from "@/components/dialogs/WaypointPicker";
import DestinationPicker from "@/components/dialogs/DestinationPicker";
import SegmentDialog from "@/components/dialogs/SegmentDialog";
import OptsDialog from "@/components/dialogs/OptsDialog";
import PlannerExceptionsDialog from "@/components/dialogs/PlannerExceptionsDialog";
import AliveDialog from "@/components/dialogs/AliveDialog";
import ResponseDialog from "@/components/dialogs/ResponseDialog";
import CmdDialog from "@/components/dialogs/CmdDialog";
import Engine from "@/components/Engine.vue";
import Analytics from "@/components/Analytics.vue";
import CookieConsent from "@/components/CookieConsent.vue";
import Logger from "@/components/Logger.vue";

// Planner view controls the whole planning application
// @group App
export default {
    name: "Planner",
    components: {
        Panel,
        PlanDetail,
        Map,
        WalkPicker,
        OperatorDetail,
        CarPicker,
        CyclingPicker,
        PricePicker,
        TransportPicker,
        PublicTransportPicker,
        BikesharingPicker,
        ScootersharingPicker,
        MopedsharingPicker,
        CarsharingPicker,
        TaxisharingPicker,
        TimePicker,
        OriginPicker,
        DestinationPicker,
        WaypointPicker,
        SegmentDialog,
        OptsDialog,
        PlannerExceptionsDialog,
        AliveDialog,
        ResponseDialog,
        CmdDialog,
        Engine,
        Analytics,
        CookieConsent,
        Logger,
    },
    computed: {
        via() {
            return this.$store.getters.via;
        },
    },
};
</script>

<style lang="css">
:root {
    --cc-btn-primary-bg: #349a51 !important;
    --cc-btn-primary-hover-bg: #39a959 !important;
}
</style>