<script>
// @vuese
// UI modifier helper mixin
// @group Mixins
export default {
  name: "Ui",
  data() {
    return {
      colors: {
        pt: "#9573d9",
        bikesharing: "#d973cb",
        carsharing: "#73d9cf",
        mopedsharing: "#d973cb",
        scootersharing: "#73d9cf",
        providers: this.appConfig.map.color.providers,
      },
      icons: {
        pt: `fas fa-bus`,
        bikesharing: "fas fa-biking",
        carsharing: "fas fa-car",
        mopedsharing: "fas fa-biking",
        scootersharing: "fas fa-ring",
      },
      svgs: {
        "fas fa-bus": `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill="#fff" d="M488 128h-8V80c0-44.8-99.2-80-224-80S32 35.2 32 80v48h-8c-13.25 0-24 10.74-24 24v80c0 13.25 10.75 24 24 24h8v160c0 17.67 14.33 32 32 32v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h192v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h6.4c16 0 25.6-12.8 25.6-25.6V256h8c13.25 0 24-10.75 24-24v-80c0-13.26-10.75-24-24-24zM112 400c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm16-112c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h256c17.67 0 32 14.33 32 32v128c0 17.67-14.33 32-32 32H128zm272 112c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"/></svg>`,
        "fas fa-biking": `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path fill="#fff" d="M400 96a48 48 0 1 0-48-48 48 48 0 0 0 48 48zm-4 121a31.9 31.9 0 0 0 20 7h64a32 32 0 0 0 0-64h-52.78L356 103a31.94 31.94 0 0 0-40.81.68l-112 96a32 32 0 0 0 3.08 50.92L288 305.12V416a32 32 0 0 0 64 0V288a32 32 0 0 0-14.25-26.62l-41.36-27.57 58.25-49.92zm116 39a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64zM128 256a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64z"/></svg>`,
        "fas fa-car": `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill="#fff" d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z"/></svg>`,
        "fas fa-ring": `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill="#fff" d="M256 64C110.06 64 0 125.91 0 208v98.13C0 384.48 114.62 448 256 448s256-63.52 256-141.87V208c0-82.09-110.06-144-256-144zm0 64c106.04 0 192 35.82 192 80 0 9.26-3.97 18.12-10.91 26.39C392.15 208.21 328.23 192 256 192s-136.15 16.21-181.09 42.39C67.97 226.12 64 217.26 64 208c0-44.18 85.96-80 192-80zM120.43 264.64C155.04 249.93 201.64 240 256 240s100.96 9.93 135.57 24.64C356.84 279.07 308.93 288 256 288s-100.84-8.93-135.57-23.36z"/></svg>`,
        "fas fa-train": `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill="#fff" d="M448 96v256c0 51.815-61.624 96-130.022 96l62.98 49.721C386.905 502.417 383.562 512 376 512H72c-7.578 0-10.892-9.594-4.957-14.279L130.022 448C61.82 448 0 403.954 0 352V96C0 42.981 64 0 128 0h192c65 0 128 42.981 128 96zm-48 136V120c0-13.255-10.745-24-24-24H72c-13.255 0-24 10.745-24 24v112c0 13.255 10.745 24 24 24h304c13.255 0 24-10.745 24-24zm-176 64c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56z"/></svg>`,
        "fas fa-subway": `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill="#fff" d="M448 96v256c0 51.815-61.624 96-130.022 96l62.98 49.721C386.905 502.417 383.562 512 376 512H72c-7.578 0-10.892-9.594-4.957-14.279L130.022 448C61.82 448 0 403.954 0 352V96C0 42.981 64 0 128 0h192c65 0 128 42.981 128 96zM200 232V120c0-13.255-10.745-24-24-24H72c-13.255 0-24 10.745-24 24v112c0 13.255 10.745 24 24 24h104c13.255 0 24-10.745 24-24zm200 0V120c0-13.255-10.745-24-24-24H272c-13.255 0-24 10.745-24 24v112c0 13.255 10.745 24 24 24h104c13.255 0 24-10.745 24-24zm-48 56c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm-256 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"/></svg>`,
        "fas fa-ship": `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path fill="#fff" d="M496.616 372.639l70.012-70.012c16.899-16.9 9.942-45.771-12.836-53.092L512 236.102V96c0-17.673-14.327-32-32-32h-64V24c0-13.255-10.745-24-24-24H248c-13.255 0-24 10.745-24 24v40h-64c-17.673 0-32 14.327-32 32v140.102l-41.792 13.433c-22.753 7.313-29.754 36.173-12.836 53.092l70.012 70.012C125.828 416.287 85.587 448 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24 61.023 0 107.499-20.61 143.258-59.396C181.677 487.432 216.021 512 256 512h128c39.979 0 74.323-24.568 88.742-59.396C508.495 491.384 554.968 512 616 512c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24-60.817 0-101.542-31.001-119.384-75.361zM192 128h256v87.531l-118.208-37.995a31.995 31.995 0 0 0-19.584 0L192 215.531V128z"/></svg>`,
        "fas fa-tram": `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path fill="#fff" d="M288 64c17.7 0 32-14.3 32-32S305.7 0 288 0s-32 14.3-32 32 14.3 32 32 32zm223.5-12.1c-2.3-8.6-11-13.6-19.6-11.3l-480 128c-8.5 2.3-13.6 11-11.3 19.6C2.5 195.3 8.9 200 16 200c1.4 0 2.8-.2 4.1-.5L240 140.8V224H64c-17.7 0-32 14.3-32 32v224c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V256c0-17.7-14.3-32-32-32H272v-91.7l228.1-60.8c8.6-2.3 13.6-11.1 11.4-19.6zM176 384H80v-96h96v96zm160-96h96v96h-96v-96zm-32 0v96h-96v-96h96zM192 96c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32z"/></svg>`,
        "fas fa-question": `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path fill="#fff" d="M202.021 0C122.202 0 70.503 32.703 29.914 91.026c-7.363 10.58-5.093 25.086 5.178 32.874l43.138 32.709c10.373 7.865 25.132 6.026 33.253-4.148 25.049-31.381 43.63-49.449 82.757-49.449 30.764 0 68.816 19.799 68.816 49.631 0 22.552-18.617 34.134-48.993 51.164-35.423 19.86-82.299 44.576-82.299 106.405V320c0 13.255 10.745 24 24 24h72.471c13.255 0 24-10.745 24-24v-5.773c0-42.86 125.268-44.645 125.268-160.627C377.504 66.256 286.902 0 202.021 0zM192 373.459c-38.196 0-69.271 31.075-69.271 69.271 0 38.195 31.075 69.27 69.271 69.27s69.271-31.075 69.271-69.271-31.075-69.27-69.271-69.27z"/></svg>`,
      },
    };
  },
  methods: {
    cumulativeOffset(element) {
      const offsetWidth = element.offsetWidth;
      const offsetHeight = element.offsetHeight;
      var top = 0,
        left = 0;
      do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
      } while (element);

      return {
        top: top,
        left: left,
        right: left + offsetWidth,
        bottom: top + offsetHeight,
      };
    },
    prettyKms(howMany) {
      return howMany.toFixed(2) + " km";
    },
    copyToClipboard(text) {
      if ("object" == typeof text) {
        var cache = [];
        text = JSON.stringify(
          text,
          (_key, value) => {
            if (typeof value === "object" && value !== null) {
              // Duplicate reference found, discard key
              if (cache.includes(value)) return;

              // Store value in our collection
              cache.push(value);
            }
            return value;
          },
          2
        );
      } // end if

      if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
      } else {
        let textArea = document.createElement("textarea");
        textArea.value = text;

        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand("copy");
        } catch (err) {
          /* nobody cares */
        }

        document.body.removeChild(textArea);
      } // end if-else
    }, // end function
    smartColor(mode, providerId) {
      let color = this.colors[mode];

      if (providerId !== null) {
        if (
          this.operators.all[providerId] &&
          this.operators.all[providerId].color
        ) {
          color = this.operators.all[providerId].color;
        } else if (this.colors.providers[providerId]) {
          color = this.colors.providers[providerId];
        } // end if
      } // end if

      // Hard-coded public transport colors
      switch (providerId) {
        case "BUS":
          color = "#a5d46a";
          break;
        case "TRAM":
          color = "#9966ff";
          break;
        case "METRO":
          color = "#ff6680";
          break;
        case "TRAIN":
          color = "#EBBA54";
          break;
        case "FERRY":
          color = "#66ccff";
          break;
        case "FUNICULAR":
          color = "#55CBCD";
          break;
        case "TROLLEYBUS":
          color = "#C870E0";
          break;
      }

      if (undefined === color) {
        color = "#b0b0b0";
      }

      return color;
    },
    smartIcon(mode, providerId) {
      let icon = this.getSvg(this.icons[mode]);

      // switch (mode) {
      //   case "xxxxx": icon = this.getSvg("fas fa-bus"); break;

      // }

      switch (providerId) {
        case "BUS":
          icon = this.getSvg("fas fa-bus");
          break;
        case "TRAM":
          icon = this.getSvg("fas fa-train");
          break;
        case "METRO":
          icon = this.getSvg("fas fa-subway");
          break;
        case "TRAIN":
          icon = this.getSvg("fas fa-train");
          break;
        case "FERRY":
          icon = this.getSvg("fas fa-ship");
          break;
        case "FUNICULAR":
          icon = this.getSvg("fas fa-tram");
          break;
        case "TROLLEYBUS":
          icon = this.getSvg("fas fa-bus");
          break;
      }

      if (undefined === icon) {
        icon = this.getSvg("fas fa-question");
      } // end if

      return icon;
    },
    prepareFakePtOperators() {
      return {
        BUS: { id: "BUS", name: "BUS" },
        TRAM: { id: "TRAM", name: "TRAM" },
        METRO: { id: "METRO", name: "METRO" },
        TRAIN: { id: "TRAIN", name: "TRAIN" },
        FERRY: { id: "FERRY", name: "FERRY" },
        FUNICULAR: { id: "FUNICULAR", name: "FUNICULAR" },
        TROLLEYBUS: { id: "TROLLEYBUS", name: "TROLLEYBUS" },
      };
    },
    getSvg(icon) {
      return this.svgs[icon];
    },
  },
};
</script>