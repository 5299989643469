<template>
  <div :class="minimized ? 'minimized' : 'expanded'" class="settings-minimizer">
    <a v-on:click="toggle">
      {{ minimized ? "Transport modes" : "Transport modes" }}
      <font-awesome-icon v-if="minimized" icon="angle-down"></font-awesome-icon>
      <font-awesome-icon v-if="!minimized" icon="angle-up"></font-awesome-icon>
    </a>
  </div>
</template>

<script>

// `Panel` minimizer button
// @group Map
export default {
  name: "Spinner",
  data() {
    return {
      minimized: true,
    };
  },
  mounted() {
    this.eventHub.$on("loadingDone", () => {
      this.minimized = true;
      this.eventHub.$emit("updatePanelMinimizedStatus", this.minimized);
    });
  },
  methods: {
    toggle() {
      this.minimized = ! this.minimized;
      this.eventHub.$emit("updatePanelMinimizedStatus", this.minimized);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";

.settings-minimizer {
  text-align: center;
  margin: 25px 0 15px 0;

  a {
    text-decoration: none;

  }

  [data-icon] {
    width: 15px;
    height: 15px;
  }
}
</style>

