<script>
// @vuese
// Public transport mode helper methods
// @group Mixins
export default {
  name: "PT",
  methods: {
    // @vuese
    // Picks icon style based on leg type
    // @arg `String` {mode} leg mode
    getIconStyle(mode, type, transferType) {
      if ("DROPOFF" == type) {
        mode = "parking";
      } // end if

      if ("PICKUP" == type && transferType) {
        mode = "transfer";
      } else if ("PICKUP" == type) {
        mode = "pickup";
      } // end if

      const style = this.appConfig.map.plan;
      if (style.color[mode.toLowerCase()] !== undefined) {
        return style.color[mode.toLowerCase()];
      } else {
        return style.color.generic;
      }
    },
    // @vuese
    // Picks icon based on leg type
    // @arg `Object` {segment} plan segment
    getIcon(segment) {
      if ("PT" == segment.transportMode) {
        return this.ptIcons[segment.rideDetails.ptDetails.route.ptVehicle] ? this.ptIcons[segment.rideDetails.ptDetails.route.ptVehicle] : this.ptIcons.UNKNOWN;
      } else {
        return this.icons[segment.transportMode];
      } // end if-else
    },
    // @vuese
    // Detects type of PT icon
    // @arg `Object` {segment} plan segment
    // @return ENUM (fontawesome|vector|bitmap)
    getIconType(segment) {
      let icon;
      if ("PT" == segment.transportMode) {
        icon = this.ptIcons[segment.rideDetails.ptDetails.route.ptVehicle] ? this.ptIcons[segment.rideDetails.ptDetails.route.ptVehicle] : this.ptIcons.UNKNOWN;
      } else {
        icon = this.icons[segment.transportMode];
      } // end if-else

      return 'object' == typeof icon ? 'vector' : 'fontawesome';
    },
  },
};
</script>