<template>
  <div class="pt-stops" :data-expanded="expanded ? 'true' : 'false'">
    <ol class="stops" :start="startIndex ? startIndex : 1">
      <li
        v-for="(item, j) in stops"
        v-bind:key="j"
        :class="{ hidden: isStopHidden(j, stops, expanded) }"
      >
        <span class="stop-name" :class="stopClass(item, 0 == j)">{{ item.stop.gtfsStopName }}</span>
        (<span
          class="arrival"
          :class="delayClass(item)"
          v-html="prettyTime(item, 0 == j, (j+1) == stops.length)"
        ></span
        >)
      </li>
    </ol>
    <a v-on:click="expanded = !expanded">
      {{ stops.length }} stops
      <font-awesome-icon v-if="!expanded" icon="angle-down"></font-awesome-icon>
      <font-awesome-icon v-if="expanded" icon="angle-up"></font-awesome-icon>
    </a>
  </div>
</template>

<script>
// Responsible for painting public transport stops lists in the plan detail, including making it reactive
// @group App
export default {
  name: "PtStops",
  props: ["stops", "startIndex"],
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    isStopHidden(index, stops, expanded) {
      if (0 === index) {
        return false;
      } else if (stops.length - 1 === index) {
        return false;
      } else if (true === expanded) {
        return false;
      } else {
        return true;
      }
    },
    stopClass(item, isFirst) {
      let wontStop = ( (isFirst && item.pickupType == "NOT_AVAILABLE") || (!isFirst && item.dropOffType == "NOT_AVAILABLE") );

      return wontStop ? 'wont-stop' : 'will-stop';
    },
    prettyTime(stop, isFirst, isLast) {
      let arrivalTs = Date.parse(stop.scheduledArrival);
      let departureTs = Date.parse(stop.scheduledDeparture);

      if (null !== stop.arrivalDelaySeconds) {
        arrivalTs += (stop.arrivalDelaySeconds * 1000);
        departureTs += (stop.arrivalDelaySeconds * 1000);
      } // end if

      let arrivalDt = new Date(arrivalTs);
      let arrival = "";
      arrival += ("0" + arrivalDt.getHours()).slice(-2);
      arrival += ":";
      arrival += ("0" + arrivalDt.getMinutes()).slice(-2);
      
      let departureDt = new Date(departureTs);
      let departure = "";
      departure += ("0" + departureDt.getHours()).slice(-2);
      departure += ":";
      departure += ("0" + departureDt.getMinutes()).slice(-2);

      if (isFirst) {
        return departure;
      } else if (isLast) {
        return arrival;
      } else {
        if (arrival == departure) {
          return arrival;
        } else {
          return `${arrival} - ${departure}`;
        } // end if-else
      } // end if-elseif-else
    },
    delayClass(stop) {
      let cls = "on-time";

      if (null !== stop.arrivalDelaySeconds) {
        if (stop.arrivalDelaySeconds > 0) {
          cls = "delayed";
        } else if (stop.arrivalDelaySeconds <= 0) {
          cls = "ahead";
        } // end if-elseif
      } // end if

      return cls;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";

.pt-stops {
  // &[data-expanded="false"] {
  //   .stops li:not(:last-of-type):not(:first-of-type) {
  //     display: none;
  //   }
  // }

  a {
    font-size: 14px;
    text-decoration: none;
    margin-left: 10px;

    [data-icon] {
      margin-bottom: -2px;
    }
  }

  ul,
  ol {
    margin: 20px 10px;
    li {
      padding: 5px 0 5px 0;
      margin: 0 0 0 10px;
      line-height: 1.6;
      font-size: 0.9rem;
      font-weight: 300;
      position: relative;

      &.segment::before {
        content: " ";
        position: absolute;
        width: 2px;
        background-color: black;
        top: 15px;
        bottom: -15px;
        left: -20px;
      }

      &.hidden {
        height: 0;
        visibility: hidden;
      }
      b {
        font-weight: bold;
      }
      
      .wont-stop {
        opacity: .5;
      }
    }
  }

  ol.stops {
    margin: 10px 0 0 0;
    li {
      list-style: auto;
      font-size: 14px;
      padding: 0;
      list-style: decimal inside none;

      .arrival {
        &.ahead {
          color: #359a51;
        }
        &.delayed {
          color: #d6354e;
        }
      }
    }
  }
}
</style>
