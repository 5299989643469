<template>
  <modal
    name="taxisharingPickerDialog"
    ref="taxisharingPickerDialog"
    class="vue-dialog"
    width="400px"
    height="auto"
    :shiftX=".5"
    :shiftY=".25"
    id="taxisharingPickerDialog"
    opened="opened"
  >
    <div class="vue-dialog-content">

      <div class="vue-dialog-content-title">{{ $t('components.TaxisharingPicker.dialog.title') }}</div>
      <div class="line-15px"></div>
      <div class="opts">
        <div class="values">
          <span class="left">How far do you want to ride?</span>
          <span class="right bold pull-right">{{
            kms > 0 ? prettyKms(kms) : "No riding"
          }}</span>
        </div>
        <vue-slider ref="slider" v-model="kms" v-bind="sliderOpts" />
      </div>
      <div class="opts">
        <p class="subheading is-first">Which taxi providers you want to use?</p>
        <ul>
          <li
            v-for="option in options"
            :key="option"
            v-on:click="toggle(option)"
            :class="{active: selected.indexOf(option) >= 0}"
            class="pointer"
          >
            <font-awesome-icon icon="check"></font-awesome-icon>
            <div class="bold">{{ operators.taxi[option].name }}</div>
          </li>
        </ul>
      </div>
      <div class="opts">
        <p class="subheading">Best route only</p>
        <ul>
          <li
            v-on:click="returnBestOnlyRoute = ! returnBestOnlyRoute"
            :class="{ active: returnBestOnlyRoute }"
            class="pointer"
          >
            <font-awesome-icon icon="check"></font-awesome-icon>
            <div class="bold">
              {{ returnBestOnlyRoute ? "Yes" : "No" }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button
        v-on:click="hide();"
        type="button"
        class="vue-dialog-button"
      >{{ $t('components.TaxisharingPicker.buttons.cancel') }}</button>
      <button
        v-on:click="save(); hide()"
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >{{ $t('components.TaxisharingPicker.buttons.okay') }}</button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";
import System from "../mixins/System.vue";

// Modal dialog component for picking TaxiSharing provider(s)
// @group Dialogs
export default {
  name: "TaxisharingPicker",
  mixins: [Ui, System],
  data() {
    return {
      options: [],
      selected: this.$store.getters.taxisharing,
      returnBestOnlyRoute: true,
      kms: this.$store.getters.taxiKms,
      sliderOpts: {
        dotSize: 14,
        width: "auto",
        height: 4,
        contained: false,
        direction: "ltr",
        data: null,
        min: 0,
        max: 80,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: "active",
        tooltipPlacement: "top",
        tooltipFormatter: void 0,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
    };
  },
  mounted() {
    this.eventHub.$on("taxisharingPickerShow", this.show);
    this.eventHub.$on("taxisharingPickerHide", this.hide);
  },
  computed: {
    operators() {
      return this.$store.getters.operators;
    },
  },
  methods: {
    // @vuese
    // Shows modal dialog
    // @arg `void`
    show() {
      this.options = Object.keys(this.operators.taxi);

      this.selected = this.$store.getters.taxisharing;
      this.$modal.show("taxisharingPickerDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("taxisharingPickerDialog");
    },
    // @vuese
    // Saves selected values to local store and suggest UI update
    // @arg `void`
    save() {
      this.$store.commit("taxisharing", this.selected);
      this.$store.commit("taxiKms", this.kms);

      // 1.5
      this.$store.commit("returnBestOnlyRouteOD", this.returnBestOnlyRoute);

      // @vuese
      // Suggest fetching new plans because user has changed one or more options
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
    },
    // @vuese
    // Checks/unchecks clicked values and saves them to the instance
    // @arg `String` {option} selected value
    toggle(option) {
      const index = this.selected.indexOf(option);
      if (index >= 0) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(option);
      } 
    },
    // @vuese
    // Callback called after each modal opening
    // @arg `void`
    opened() {
      return null;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";

#taxisharingPickerDialog.vue-dialog {
  .vue-dialog-content {
    li {
      padding-left: 30px;

      [data-icon="check"] {
        top: 0;
        left: -10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.vue-slider {
  margin: 30px 0 20px 0;
}

@media (min-width: 800px) {
  // #taxisharingPickerDialog::v-deep .vm--modal {
  //   overflow: visible !important;
  //   left: 253px !important;
  //   top: 370px !important;

  //   [data-icon="taxiet-left"] {
  //     top: 205px;
  //   }
  // }
}
</style>
