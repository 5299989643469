<template>
  <modal
    name="cmdDialog"
    ref="cmdDialog"
    class="vue-dialog"
    width="640px"
    height="499px"
    :shiftX="0.5"
    :shiftY="0.25"
    id="cmdDialog"
    v-on:opened="onOpened()"
  >
    <div class="vue-dialog-content">
      <div class="vue-dialog-content-title">Custom request</div>
      <div class="line-15px"></div>
      <div class="content">
        <textarea v-model="request" placeholder="Insert valid JSON" ref="payload"></textarea>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button v-on:click="hide()" type="button" class="vue-dialog-button">
        Close
      </button>
      <button
        v-on:click="submit()"
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        Submit
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";

// Displays a modal dialog with detailed trip segment
// @group Dialogs
export default {
  name: "CmdDialog",
  data() {
    return {
      segment: null,
      request: null,
    };
  },
  mixins: [Ui],
  mounted() {
    this.eventHub.$on("cmdDialogShow", this.show);
    this.eventHub.$on("cmdDialogHide", this.hide);
  },
  methods: {
    submit() {
      if (0 == this.$refs.payload.value.length) {
        return alert("The payload was empty.");
      } 

      try {
        const payload = JSON.parse(this.$refs.payload.value);
        
        this.$store.commit("autoPlan", false);

        this.eventHub.$emit("getPlans", payload);

        setTimeout(() => {
          this.$store.commit("autoPlan", true);
        }, 2000);

        this.hide();
      } catch (ex) {
        this.eventHub.$emit("logMessage", {
          type: "error",
          message: "Could not parse custom request.",
        });
      } 
      
    },
    // @vuese
    // Shows modal window
    // @arg `void`
    show() {
      let request = this.$parent.$refs.engine.buildPayload();
      this.request = JSON.stringify(request, null, 2);
      this.$modal.show("cmdDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("cmdDialog");
    },
    // @vuese
    // Select textarea content on modal open to allow direct cmd|ctrl+v
    // @arg `void`
    onOpened() {
      this.$refs.payload.select();
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";

#cmdDialog.vue-dialog {
  .vue-dialog-content {
    li {
      padding-left: 30px;

      [data-icon="check"] {
        top: 0;
        left: -10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media (min-width: 800px) {
  #cmdDialog::v-deep .vm--modal {
    [data-icon="caret-left"] {
      top: 77px;
    }
  }
}
</style>
<style lang="scss">
textarea {
  width: 100%; height: 364px;
}
</style>
