<template>
  <div>
    <div v-if="btnActive" class="operators-toggler toggle">
      <a v-on:click="active = !active">
        <font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
      </a>
    </div>
  </div>
</template>

<script>
// Generates a list of toggleable operators for each "parent" toggle button
// @group Map
export default {
  name: "OperatorsToggle",
  props: ["mode", "operators", "geofencing", "initiator", "icon", "title", "filters", "stations", "ptStations"],
  data() {
    return {
      selectedVehicles: [],
      selectedStations: [],
      selectedGeofencing: [],
      filterModel: {},
      active: false,
      cooldown: null,
      btnActive: false,
      ptStationsEnabled: false,
    };
  },
  mounted() {
    this.selectedVehicles = Object.keys(this.operators);
  },
  watch: {
    active(isActive) {
      if (isActive) {
        this.eventHub.$emit("operatorDetailShow", {
          operators: this.operators,
          geofencing: this.geofencing,
          selectedVehicles: this.selectedVehicles,
          selectedGeofencing: this.selectedGeofencing,
          toggle: this,
          icon: this.icon,
          initiator: this.initiator,
          title: this.title,
          filters: this.filters,
          filterModel: this.filterModel,
          ptStations: this.ptStations,
          stations: this.stations,
          selectedStations: this.selectedStations,
          ptStationsEnabled: this.ptStationsEnabled
        });
      }
      setTimeout(() => {
        this.active = false;
      }, 100);
    }
  },
  methods: {
    onUpdate(data) {
      this.selectedVehicles = data.selectedVehicles;
      this.selectedGeofencing = data.selectedGeofencing;
      this.filterModel = data.filterModel;
      this.selectedStations = data.selectedStations;
      this.ptStationsEnabled = data.ptStationsEnabled;

      /**
      *   @see 
      *   Order matters !
      *   1) toggleStations()
      *   2) operatorUpdated()
      */
      switch (this.mode) {
        case 'pt':
          if (this.$parent.$parent.$refs.stations.status.pt != this.ptStationsEnabled) {
            this.eventHub.$emit("toggleStations", { mode: 'pt', enable: this.ptStationsEnabled });
          } // end if
          break;
      
        default: {
          let stationsSelected = this.selectedStations.length > 0;
          if (stationsSelected != this.$parent.$parent.$refs.stations.status[this.mode]) {
            this.eventHub.$emit("toggleStations", { mode: this.mode, enable: stationsSelected });
          } // end if
          break;
        }
      }
      

      this.eventHub.$emit("operatorUpdated");

    },
    getSelection() {
      return this.selectedVehicles;
    },
    getZonesSelection() {
      return this.selectedGeofencing;
    },
    getStationsSelection() {
      return this.selectedStations;
    },
    getFilterSettings() {
      return this.filterModel
    },
  },
};
</script>

<style scoped lang="scss">
.operators-toggler {
  position: absolute;
  width: 30px;
  height: auto;
  border-radius: 10px;

  margin-top: -36px;
  // z-index: 999;
  padding: 0 0 5px 0;

  &.list {
    background-color: white;
    padding: 30px 20px;
    left: -300px;
    margin-top: -58px;
  }

  &.toggle {
    border: 0;
    // z-index: 5;
    left: -22px;
    color: darkgray;
    cursor: pointer;

    &:hover {
      color: #27793e;
    }

    a.active {
      color: #27793e;
    }

    a {
      background-color: white;
      width: 20px;
      height: 20px;
      display: inline-block;
      text-align: center;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      padding: 6px 6px 6px 6px;

      svg {
        padding-top: 3px;
      }
    }
  }

  hr {
    border-top: 0;
    border-bottom: 1px solid lightgray;
  }

  .inner {
    padding: 10px 10px;

    .section {
      padding-bottom: 5px;
      border-bottom: 1px solid gray;
    }
  }


}</style>
