<script>

// @vuese
// Manipulates the payload just before it't sent to `Connector`
// @group Engine/Mixins
export default {
  name: "Preprocessor",
  methods: {
    preprocessRequest(request, context) {
      // Get app from context, which is a component calling this mixin
      const app = context.$root;
      return app.$hooks.preprocessRequest(request, context);

    },
  }
};
</script>