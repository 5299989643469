<template>
  <div class="map-control">
    <div class="btn" v-on:click="zoomIn">
      <font-awesome-icon icon="plus"></font-awesome-icon>
    </div>
    <div class="btn" v-on:click="zoomOut">
      <font-awesome-icon icon="minus"></font-awesome-icon>
    </div>
    <div class="btn" v-on:click="locateMe(false)" v-tooltip="$t('components.MapControl.tooltip.locator')">
      <font-awesome-icon icon="map-marker"></font-awesome-icon>
    </div>
  </div>
</template>

<script>

// Custom basic map control (zoom, locator) for easier customization
// @group Map
export default {
  name: "MapControl",
  methods: {
    zoomIn() {
      this.eventHub.$emit("zoomIn");
    },
    zoomOut() {
      this.eventHub.$emit("zoomOut");
    },
    // @vuese
    // Tries to locate user using built-in browser geolocation services
    // @arg `Bool` {retry}
    locateMe(retry = false) {
      const $self = this; // Yeah, I know...

      if (navigator.geolocation) {
        const opts = {
          timeout: retry ? 30000 : 5000, 
          enableHighAccuracy: ! retry, 
          maximumAge: retry ? Infinity : 0
        };

        navigator.geolocation.getCurrentPosition(
          location => {
            $self.eventHub.$emit("flyTo", {
              lat: location.coords.latitude,
              lng: location.coords.longitude
            });
          },
          function(err) {
            if (true === retry) {
                throw err;
            } else {
                $self.locateMe(true);
            } 
          },
          opts
        );
      } else {
        console.log("error.geolocationUnsupported.title");
      } 
    }
  }
};
</script>

<style scoped lang="scss">
.map-control {
  position: absolute;
  width: 50px;
  height: 155px;
  border: 1px solid #f1f5f1;
  border-radius: 24px;
  background-color: #f3fff6;
  top: 20px;
  right: 20px;

  .btn {
    width: 40px;
    height: 40px;
    box-shadow: rgb(69 91 99 / 10%) 0px 6px 12px;
    border-radius: 50% !important;
    background-color: #f8f8f8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #efefef;
    margin: 10px 0 0 4px;

    &:first-of-type {
      margin-top: 5px;
    }

    [data-icon] {
      color: #9DB2B0;
      font-size: 20px;
    }

    &:hover {
      [data-icon] {
        color: gray;
      }
    }
  }
}
</style>
