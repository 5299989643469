<template>
  <modal
    name="plannerExceptionsDialog"
    ref="plannerExceptionsDialog"
    class="vue-dialog pe-dialog"
    :width="`${window.innerWidth - 50}px`"
    :height="`${window.innerHeight - 50}px`"
    :shiftX="0.5"
    :shiftY="0.25"
    id="plannerExceptionsDialog"
  >
    <div class="vue-dialog-content">
      <div class="vue-dialog-content-title">
        {{ $t("components.PlannerExceptionsDialog.dialog.title") }}
      </div>
      <div class="line-15px"></div>
      <div class="content">
        <json-viewer :value="errs" :expand-depth="3" copyable></json-viewer>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button
        v-on:click="hide()"
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.PlannerExceptionsDialog.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";

// Displays a modal dialog with detailed trip errs
// @group Dialogs
export default {
  name: "PlannerExceptionsDialog",
  data() {
    return {
      window: window,
      errs: null,
    };
  },
  mixins: [Ui],
  mounted() {
    this.eventHub.$on("plannerExceptionsDialogUpdate", (errs) => {
      this.errs = errs;
    });
    this.eventHub.$on("plannerExceptionsDialogShow", (errs) => {
      this.errs = errs;
      this.show();
    });
    this.eventHub.$on("plannerExceptionsDialogHide", this.hide);
  },
  methods: {
    // @vuese
    // Shows modal window
    // @arg `void`
    show() {
      this.$modal.show("plannerExceptionsDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("plannerExceptionsDialog");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";

#plannerExceptionsDialog.vue-dialog {
  .vue-dialog-content {
    li {
      padding-left: 30px;

      [data-icon="check"] {
        top: 0;
        left: -10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media (min-width: 800px) {
  #plannerExceptionsDialog::v-deep .vm--modal {
    [data-icon="caret-left"] {
      top: 77px;
    }
  }
}
</style>
<style lang="scss">
.pe-dialog {
  .jv-code {
    height: calc(100vh - 100px - 77px);
    overflow: scroll !important;
  }
}
</style>
