<template>
  <span class="viapoint-add-row">
    <a
      class="viapoint-add"
      v-on:click="addVia()"
      v-tooltip="'Add waypoint'"
      ><font-awesome-icon
        class="via"
        :icon="['fas', 'plus-square']"
      ></font-awesome-icon
    ></a>
  </span>
</template>

<script>
export default {
  name: "ViaAdd",
  props: ["index"],
  data() {
    return {};
  },
  mounted() {
    this.eventHub.$on(`addWaypoint_${this.index}`, data => {
      this.addVia(data.point);
    });
  },
  methods: {
    addVia(ll = null) {
      let via = this.$store.getters.via;

      let wp = this.getDummyWaypoint();

      // Event coming from Map ContextMenu
      if (ll) {
        wp.point = ll;
        wp.mode = "point";
      } // end if

      if (via[this.index]) {
        via.splice(this.index, 0, wp);
      } else {
        via.splice(this.index, 1, wp);
      } // end if-else

      this.$store.commit("via", via);

      // @vuese
      // Signal to show destination marker configuration modal
      // @arg `void`
      if (null == ll) {
        this.eventHub.$emit("waypointPickerShow", { index: this.index });  
      } // end if
    },
    getDummyWaypoint() {
      return {
        mode: "point",
        point: { lat: null, lng: null },
        name: `Waypoint #${this.index + 1}`,
        stopIds: undefined,
      };

    },
  },
};
</script>

<style scoped lang="scss">
.viapoint-add-row {
  display: block;
  position: relative;
  height: 0;

  .viapoint-add {
    position: absolute;
    left: -18px;
    top: -18px;
    font-size: 18px;
    border-radius: 50%;
    color: #7b7b7b;
    font-weight: normal;
    text-decoration: none;
    padding: 4px 10px;
    z-index: 3;
    cursor: pointer;

    &:hover {
      background-color: #349a511a;
      color: black;
    }
  }
}
</style>