<template>
  <div
    :class="{
      visible: show,
      hidden: !show,
      disabled: disabled,
      updated: updated,
    }"
    class="update"
  >
    <div
      class="inner"
      v-on:click="onclick"
      v-tooltip="$t('components.UpdateBtn.tooltip.update')"
    >
      {{ $t("components.UpdateBtn.label.search") }}
      <font-awesome-icon icon="search" flip="horizontal"></font-awesome-icon>
    </div>
  </div>
</template>

<script>
// Buttons performing the fetch of new plans, calling for a UI refresh & repaint; listens for `Panel` changes
// @group Map
export default {
  name: "UpdateBtn",
  computed: {
    origin() {
      return this.$store.getters.origin;
    },
    destination() {
      return this.$store.getters.destination;
    },
    via() {
      return this.$store.getters.via;
    },
    viaHash() {
      return this.$store.getters.viaHash;
    },
  },
  watch: {
    origin(newVal, oldVal) {
      this.onOriginDestinationUpdate(newVal, oldVal);
    },
    destination(newVal, oldVal) {
      this.onOriginDestinationUpdate(newVal, oldVal);
    },
    via(newVal) {
      const via = JSON.parse(JSON.stringify(newVal));
      this.calculateAndCommitViaHash(via);
    },
    viaHash(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onclickIfAutoplanEnabled();
      } // end if
    },
  },
  data() {
    return {
      show: true,
      updated: false,
      disabled: false,
    };
  },
  mounted() {
    window._updateBtn = this;
    this.eventHub.$on("suggestUpdate", () => {
      this.show = true;
      this.updated = true;
    });
    this.eventHub.$on("loading", () => {
      this.disabled = true;
      this.updated = false;
    });
    this.eventHub.$on("loadingDone", () => {
      this.disabled = false;
      this.updated = false;
    });
  },
  methods: {
    calculateAndCommitViaHash(via) {
      let hash = '';

      for (let wp of via) {
        hash += `${wp.name}|${wp.mode}`;
        if (wp.point) {
          hash += `@${wp.point.lat},${wp.point.lng}`;
        } // end if
      } // end for

      this.$store.commit('viaHash', hash);
    },
    onOriginDestinationUpdate(newVal, oldVal) {
      if (newVal.point !== oldVal.point) {
        this.onclickIfAutoplanEnabled();
      }
    },
    onclickIfAutoplanEnabled() {
      if (this.$store.getters.autoPlan) {
        this.onclick();
      } // end if
    },
    onclick() {
      if (!this.disabled) {
        this.eventHub.$emit("getPlans");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.update {
  text-align: center;
  margin: 5px 0 25px 0;

  .inner {
    margin: auto;
    border-radius: 4px;
    color: white;
    padding: 10px 40px 10px 20px;
    position: relative;
    background-color: #349a51;
    display: inline-block;
    width: auto;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
  }
  &.disabled {
    filter: grayscale(1) brightness(1.4);
    pointer-events: none;
  }

  [data-icon="search"] {
    font-size: 20px;
    width: 18px;
    color: white;
    height: 18px;
    margin: 10px;
    position: absolute;
    top: 3px;
    right: 10px;
    padding: 0;
    margin: 5px;
  }

  .inner:hover {
    background-color: #39a959;
    cursor: pointer;
  }

  &.updated .inner {
    background: linear-gradient(60deg, #349a51, #84b400, #349a51);
    animation: gradientAnimation 6s ease infinite alternate;
    background-size: 300% 300%;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}
</style>

