<template>
  <modal
    name="responseDialog"
    ref="responseDialog"
    class="vue-dialog response-dialog"
    :width="`${window.innerWidth - 50}px`"
    :height="`${window.innerHeight - 50}px`"
    :shiftX="0.5"
    :shiftY="0.25"
    id="responseDialog"
  >
    <div class="vue-dialog-content">
      <div class="vue-dialog-content-title">
        {{ 'request' == mode ? $t("components.ResponseDialog.dialog.requestTitle") : $t("components.ResponseDialog.dialog.title") }}
      </div>
      <div class="line-15px"></div>
      <div class="content">
        <json-viewer :value="response" :expand-depth="3"></json-viewer>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button
        v-on:click="copyToClipboard(response)"
        type="button"
        class="vue-dialog-button"
      >
        {{ $t("components.ResponseDialog.buttons.copy") }}
      </button>
      <button
        v-on:click="hide()"
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.ResponseDialog.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";

// Displays a modal dialog with detailed trip response
// @group Dialogs
export default {
  name: "ResponseDialog",
  data() {
    return {
      window: window,
      response: null,
      mode: undefined,
    };
  },
  mixins: [Ui],
  mounted() {
    this.eventHub.$on("responseDialogUpdate", (data) => {
      this.response = data.details;
      this.mode = data.mode;
    });
    this.eventHub.$on("responseDialogShow", (data) => {
      this.response = data.details;
      this.mode = data.mode;
      this.show();
    });
    this.eventHub.$on("responseDialogHide", this.hide);
  },
  methods: {
    // @vuese
    // Shows modal window
    // @arg `void`
    show() {
      this.$modal.show("responseDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("responseDialog");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";

#responseDialog.vue-dialog {
  .vue-dialog-content {
    li {
      padding-left: 30px;

      [data-icon="check"] {
        top: 0;
        left: -10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media (min-width: 800px) {
  #responseDialog::v-deep .vm--modal {
    [data-icon="caret-left"] {
      top: 77px;
    }
  }
}
</style>
<style lang="scss">
.response-dialog {
  .jv-code {
    height: calc(100vh - 100px - 77px);
    overflow: scroll !important;
  }
}
</style>
