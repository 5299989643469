<script>
import Preprocessor from "@/components/engine/mixins/Preprocessor.vue";
import PostRequest from "@/components/engine/mixins/requests/post.ts";
import Postprocessor from "@/components/engine/mixins/Postprocessor.vue";

// @vuese
// Executes all `http` requests the web application needs
// @group Engine/Mixins
export default {
  name: "Connector",
  mixins: [Preprocessor, Postprocessor],
  data() {
    return {
      pendingRequest: null,
    };
  },
  methods: {
    // @vuese
    // Fetches new plans from API based on new config in `payload`
    // @arg `Object` {payload}
    downloadPlans(payload) {
      const url = this.appConfig.api.routing;

      const cancelToken = this.$http.CancelToken;
      this.pendingRequest = cancelToken.source();

      let request = { ...PostRequest };
      request.uid = "plans-download.post";
      request.url = url;
      request.payload = payload;
      request.timeout = 120000;
      request = this.preprocessRequest(request, this);

      return this.$http.post(request.url, request.payload, {
        timeout: request.timeout,
        headers: request.headers,
        cancelToken: this.pendingRequest.token,
      });
    },
    // @vuese
    // Fetches prev/next PT segment from plan detail
    // @arg `LatLng` {origin}
    // @arg `LatLng` {destination}
    // @arg `Enum` {direction}
    // @arg `Object` {params}
    downloadSegment(origin, destination, direction, params) {
      let url = this.appConfig.api.segment.replace("{direction}", direction);
      
      const payload = {
        startCoordinate: {
          lat: origin.lat,
          lon: origin.lon
        },
        fromStopId: params.startStopId,
        endCoordinate: {
          lat: destination.lat,
          lon: destination.lon
        },
        dateTime: params.dateTime,
        maxTripsCount: params.maxTripsCount,
        ptSettings: params.ptSettings,
        walkSettings: params.walkSettings
      };

      let request = { ...PostRequest };
      request.uid = "segment.post";
      request.url = url;
      request.payload = payload;
      request = this.preprocessRequest(request, this);

      return this.postprocessResponse(request.uid, this.$http.post(request.url, request.payload, {
        timeout: request.timeout,
        headers: request.headers,
      }));
    },
    updatePlan(plan) {
      let url = this.appConfig.api.update;

      let request = { ...PostRequest };
      request.uid = "plan-update.post";
      request.url = url;
      request.payload = { plannedRoute: plan.hardCopy };
      request = this.preprocessRequest(request, this);

      return this.postprocessResponse(request.uid, this.$http.post(request.url, request.payload, {
        timeout: request.timeout,
        headers: request.headers,
      }));
    },
    executePlan(plan) {
      let url = this.appConfig.api.execute;

      let request = { ...PostRequest };
      request.uid = "plan-execute.post";
      request.url = url;
      request.payload = { plannedRoute: plan.hardCopy };
      request = this.preprocessRequest(request, this);

      return this.postprocessResponse(request.uid, this.$http.post(request.url, request.payload, {
        timeout: request.timeout,
        headers: request.headers,
      }));
    }
  },
};
</script>