<template>
  <modal
    name="segmentDialog"
    ref="segmentDialog"
    class="vue-dialog segment-dialog"
    width="640px"
    height="500px"
    :shiftX="0.5"
    :shiftY="0.25"
    id="segmentDialog"
  >
    <div class="vue-dialog-content">
      <div class="vue-dialog-content-title">{{ $t("components.SegmentDialog.dialog.title") }}</div>
      <div class="line-15px"></div>
      <div class="content">
        <json-viewer :value="segment" :expand-depth="2" copyable></json-viewer>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button
        v-on:click="hide()"
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.SegmentDialog.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";

// Displays a modal dialog with detailed trip segment
// @group Dialogs
export default {
  name: "SegmentDialog",
  data() {
    return {
      segment: null,
    };
  },
  mixins: [Ui],
  mounted() {
    this.eventHub.$on("segmentDialogUpdate", (segment) => {
      this.segment = segment;
    });
    this.eventHub.$on("segmentDialogShow", this.show);
    this.eventHub.$on("segmentDialogHide", this.hide);
  },
  methods: {
    // @vuese
    // Shows modal window
    // @arg `void`
    show() {
      this.$modal.show("segmentDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("segmentDialog");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";

#segmentDialog.vue-dialog {
  .vue-dialog-content {
    li {
      padding-left: 30px;

      [data-icon="check"] {
        top: 0;
        left: -10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
@media (min-width: 800px) {
  #segmentDialog::v-deep .vm--modal {
    [data-icon="caret-left"] {
      top: 77px;
    }
  }
}
</style>
<style lang="scss">
.segment-dialog {
  .jv-code {
    height: 373px;
    overflow: scroll !important;
  }
}
</style>
