<template>
  <modal
    name="mopedsharingPickerDialog"
    ref="mopedsharingPickerDialog"
    class="vue-dialog"
    width="400px"
    height="auto"
    :shiftX="0.5"
    :shiftY="0.25"
    id="mopedsharingPickerDialog"
    opened="opened"
  >
    <div class="vue-dialog-content">

      <div class="vue-dialog-content-title">
        {{ $t("components.MopedsharingPicker.dialog.title") }}
      </div>
      <div class="line-15px"></div>
      <div class="opts">
        <div class="values">
          <span class="left">How far do you want to ride?</span>
          <span class="right bold pull-right">{{
            kms > 0 ? prettyKms(kms) : "No riding"
          }}</span>
        </div>
        <vue-slider ref="slider" v-model="kms" v-bind="sliderOpts" />
      </div>
      <div class="opts">
        <p class="subheading is-first">
          Which moped sharing providers you want to use?
        </p>
        <ul>
          <li
            v-for="option in options"
            :key="option"
            v-on:click="toggle(option)"
            :class="{ active: selected.indexOf(option) >= 0 }"
            class="pointer"
          >
            <font-awesome-icon icon="check"></font-awesome-icon>
            <div class="bold">{{ operators.shared_moped[option].name }}</div>
          </li>
        </ul>
      </div>
      <div class="opts">
        <p class="subheading">Allow electric vehicles</p>
        <ul>
          <li
            v-on:click="allowElectric = ! allowElectric"
            :class="{ active: allowElectric }"
            class="pointer"
          >
            <font-awesome-icon icon="check"></font-awesome-icon>
            <div class="bold">
              {{ allowElectric ? "Yes" : "No" }}
            </div>
          </li>
        </ul>
      </div>
      <div class="opts">
        <p class="subheading">Allow gasoline powered vehicles</p>
        <ul>
          <li
            v-on:click="allowCombustion = ! allowCombustion"
            :class="{ active: allowCombustion }"
            class="pointer"
          >
            <font-awesome-icon icon="check"></font-awesome-icon>
            <div class="bold">
              {{ allowCombustion ? "Yes" : "No" }}
            </div>
          </li>
        </ul>
      </div>
      <div class="opts">
        <p class="subheading">Best route only</p>
        <ul>
          <li
            v-on:click="returnBestOnlyRoute = ! returnBestOnlyRoute"
            :class="{ active: returnBestOnlyRoute }"
            class="pointer"
          >
            <font-awesome-icon icon="check"></font-awesome-icon>
            <div class="bold">
              {{ returnBestOnlyRoute ? "Yes" : "No" }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button v-on:click="hide()" type="button" class="vue-dialog-button">
        {{ $t("components.MopedsharingPicker.buttons.cancel") }}
      </button>
      <button
        v-on:click="
          save();
          hide();
        "
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.MopedsharingPicker.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";
import System from "../mixins/System.vue";

// Modal dialog component for picking MopedSharing provider(s)
// @group Dialogs
export default {
  name: "MopedsharingPicker",
  mixins: [Ui, System],
  data() {
    return {
      options: [],
      selected: this.$store.getters.mopedsharing,
      allowElectric: true,
      allowCombustion: true,
      returnBestOnlyRoute: true,
      kms: this.$store.getters.mopedSharingKms,
      sliderOpts: {
        dotSize: 14,
        width: "auto",
        height: 4,
        contained: false,
        direction: "ltr",
        data: null,
        min: 0,
        max: 25,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: "active",
        tooltipPlacement: "top",
        tooltipFormatter: void 0,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
    };
  },
  mounted() {
    this.eventHub.$on("mopedsharingPickerShow", this.show);
    this.eventHub.$on("mopedsharingPickerHide", this.hide);
  },
  computed: {
    operators() {
      return this.$store.getters.operators;
    },
  },
  methods: {
    // @vuese
    // Shows modal dialog
    // @arg `void`
    show() {
      this.options = Object.keys(this.operators.shared_moped);

      this.selected = this.$store.getters.mopedsharing;
      this.$modal.show("mopedsharingPickerDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("mopedsharingPickerDialog");
    },
    // @vuese
    // Saves selected values to local store and suggest UI update
    // @arg `void`
    save() {
      this.$store.commit("mopedsharing", this.selected);
      this.$store.commit("mopedSharingKms", this.kms);

      // 1.5
      this.$store.commit("allowElectricMS", this.allowElectric);
      this.$store.commit("allowCombustionMS", this.allowCombustion);
      this.$store.commit("returnBestOnlyRouteMS", this.returnBestOnlyRoute);

      // @vuese
      // Suggest fetching new plans because user has changed one or more options
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
    },
    // @vuese
    // Checks/unchecks clicked values and saves them to the instance
    // @arg `String` {option} selected value
    toggle(option) {
      const index = this.selected.indexOf(option);
      if (index >= 0) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(option);
      }
    },
    // @vuese
    // Callback called after each modal opening
    // @arg `void`
    opened() {
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";

#mopedsharingPickerDialog.vue-dialog {
  .vue-dialog-content {
    li {
      padding-left: 30px;

      [data-icon="check"] {
        top: 0;
        left: -10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.vue-slider {
  margin: 30px 0 20px 0;
}

@media (min-width: 800px) {
  // #mopedsharingPickerDialog::v-deep .vm--modal {
  //   overflow: visible !important;
  //   left: 253px !important;
  //   top: 370px !important;

  //   [data-icon="mopedet-left"] {
  //     top: 205px;
  //   }
  // }
}
</style>
