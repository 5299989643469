<template>
  <modal
    name="publicTransportPickerDialog"
    ref="publicTransportPickerDialog"
    class="vue-dialog"
    width="800px"
    height="auto"
    :shiftX="0.5"
    :shiftY="0.25"
    id="publicTransportPickerDialog"
    opened="opened"
  >
    <div class="vue-dialog-content">

      <div class="vue-dialog-content-title">
        {{ $t("components.PublicTransportPicker.dialog.title") }}
      </div>
      <div class="line-15px"></div>
      <div class="columns">
        <div class="column left">
          <div class="opts">
            <div class="values">
              <span class="left">{{
                $t("components.PublicTransportPicker.slider.left")
              }}</span>
              <span class="right bold pull-right">{{
                transfers > 0
                  ? prettyTransfer(transfers)
                  : $t("components.PublicTransportPicker.slider.direct")
              }}</span>
            </div>
            <vue-slider
              ref="slider"
              v-model="transfers"
              v-bind="options.transfers"
            />
          </div>

          <div class="opts">
            <p class="subheading is-first">I will travel by</p>
            <ul>
              <li
                v-for="option in options.pt.options"
                :key="option"
                v-on:click="togglePt(option)"
                :class="{ active: options.pt.selected.indexOf(option) >= 0 }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{
                    $t(
                      `components.PublicTransportPicker.option.pt.${option}.title`
                    )
                  }}
                </div>
              </li>
            </ul>
          </div>

          <div class="opts">
            <p class="subheading">User fares</p>
            <ul>
              <li
                v-for="option in options.userFares.options"
                :key="option"
                v-on:click="toggleUserFare(option)"
                :class="{
                  active: options.userFares.selected.indexOf(option) >= 0,
                }"
                class="pointer inline"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">{{ option }}</div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">Only vehicles with lowered floor</p>
            <ul>
              <li
                v-on:click="options.lowered = ! options.lowered"
                :class="{ active: options.lowered }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ options.lowered ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">Only barrier-free stops</p>
            <ul>
              <li
                v-on:click="options.barrierFreeStopsOnly = ! options.barrierFreeStopsOnly"
                :class="{ active: options.barrierFreeStopsOnly }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ options.barrierFreeStopsOnly ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="column right">
          
          <div class="opts">
            <p class="subheading">Transfer speed</p>
            <ul>
              <li
                v-for="option in options.transferSpeed.options"
                :key="option"
                v-on:click="options.transferSpeed.selected = option"
                :class="{ active: options.transferSpeed.selected == option }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ $t(`components.PtPicker.option.${option}.title`) }}
                </div>
                <div class="line-10px"></div>
                <div class="light gray desc">
                  {{ $t(`components.PtPicker.option.${option}.description`) }}
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">Avoid stairs during transfer</p>
            <ul>
              <li
                v-on:click="options.stairsFreeWalkSegments = ! options.stairsFreeWalkSegments"
                :class="{ active: options.stairsFreeWalkSegments }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ options.stairsFreeWalkSegments ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
          <hr />
          <div class="opts">
            <p class="subheading">Customer profile</p>
            <input
              type="number"
              min="0"
              max="2147483647"
              v-model="options.cp"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button v-on:click="hide()" type="button" class="vue-dialog-button">
        {{ $t("components.PublicTransportPicker.buttons.cancel") }}
      </button>
      <button
        v-on:click="
          save();
          hide();
        "
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.PublicTransportPicker.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";
import Converter from "../mixins/Converter.vue";
import System from "../mixins/System.vue";

// Modal dialog component for public transport config
// @group Dialogs
export default {
  name: "PublicTransportPicker",
  mixins: [Ui, Converter, System],
  data() {
    return {
      transfers: this.$store.getters.transfers,
      options: {
        pt: {
          options: [
            "BUS",
            "TRAM",
            "METRO",
            "TRAIN",
            "FERRY",
            "FUNICULAR",
            "TROLLEYBUS",
          ],
          selected: this.$store.getters.pt,
        },
        transfers: {
          dotSize: 14,
          width: "auto",
          height: 4,
          contained: false,
          direction: "ltr",
          data: null,
          min: 0,
          max: 5,
          interval: 1,
          disabled: false,
          clickable: true,
          duration: 0.5,
          adsorb: false,
          lazy: false,
          tooltip: "active",
          tooltipPlacement: "top",
          tooltipFormatter: void 0,
          useKeyboard: false,
          keydownHook: null,
          dragOnClick: false,
          enableCross: true,
          fixed: false,
          minRange: void 0,
          maxRange: void 0,
          order: true,
          marks: false,
          dotOptions: void 0,
          process: true,
          dotStyle: void 0,
          railStyle: void 0,
          processStyle: void 0,
          tooltipStyle: void 0,
          stepStyle: void 0,
          stepActiveStyle: void 0,
          labelStyle: void 0,
          labelActiveStyle: void 0,
        },
        userFares: {
          options: ["P", "0", "B", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
          selected: this.$store.getters.userFares,
        },
        lowered: this.$store.getters.lowered,
        barrierFreeStopsOnly: this.$store.getters.barrierFreeStopsOnly,
        cp: this.$store.getters.cp,
        stairsFreeWalkSegments: this.$store.getters.stairsFreeWalkSegments,
        transferSpeed: {
          options: ["transferSlow", "transferAverage", "transferFast"],
          selected: this.$store.getters.transferSpeed,
        },
      },
    };
  },
  mounted() {
    this.eventHub.$on("publicTransportPickerShow", this.show);
    this.eventHub.$on("publicTransportPickerHide", this.hide);
  },
  methods: {
    // @vuese
    // Shows modal dialog
    // @arg `void`
    show() {
      this.transfers = this.$store.getters.transfers;
      this.$modal.show("publicTransportPickerDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("publicTransportPickerDialog");
    },
    // @vuese
    // Saves selected values to local store and suggest UI update
    // @arg `void`
    save() {
      this.$store.commit("transfers", this.transfers);
      this.$store.commit("userFares", this.options.userFares.selected);
      this.$store.commit("pt", this.options.pt.selected);
      this.$store.commit("loweredFloorOnly", this.options.lowered);
      this.$store.commit("barrierFreeStopsOnly", this.options.barrierFreeStopsOnly);
      
      // 1.5
      this.$store.commit('stairsFreeWalkSegments', this.options.stairsFreeWalkSegments);
      this.$store.commit('transferSpeed', this.options.transferSpeed.selected);
      this.$store.commit('cp', this.options.cp);

      // @vuese
      // Suggest fetching new plans because user has commited changes
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
    },
    // @vuese
    // Callback called after each modal opening
    // @arg `void`
    opened() {
      return null;
    },
    // @vuese
    // Prettify the number of transfers
    // @arg `Integer` {howMany}
    prettyTransfer(howMany) {
      return howMany.toString() + " transfer" + (howMany > 1 ? "s" : "");
    },
    toggleUserFare(option) {
      const index = this.options.userFares.selected.indexOf(option);
      if (index >= 0) {
        this.options.userFares.selected.splice(index, 1);
      } else {
        this.options.userFares.selected.push(option);
      }
    },
    togglePt(option) {
      const index = this.options.pt.selected.indexOf(option);
      if (index >= 0) {
        this.options.pt.selected.splice(index, 1);
      } else {
        this.options.pt.selected.push(option);
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";
</style>

<style scoped lang="scss">
.vue-slider {
  margin: 30px 0 20px 0;
}

@media (min-width: 800px) {
  // #publicTransportPickerDialog::v-deep .vm--modal {
  //   overflow: visible !important;
  //   left: 243px !important;
  //   top: 334px !important;
  // }
}
</style>
