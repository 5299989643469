<template>
  <div class="opts">
    <div class="btn" v-on:click="displayOptions()" v-tooltip="'Fetch options'">
      <font-awesome-icon icon="cog" class="active" />
      <font-awesome-icon icon="cog" class="inactive" />
    </div>
  </div>
</template>

<script>
import Preprocessor from "@/components/engine/mixins/Preprocessor.vue";
import GetRequest from "@/components/engine/mixins/requests/get.ts";
import Postprocessor from "@/components/engine/mixins/Postprocessor.vue";

export default {
  name: "Opts",
  mixins: [Preprocessor, Postprocessor],
  data() {
    return {

    };
  },
  methods: {
    displayOptions() {
      let request = { ...GetRequest };
      request.uid = "opts.get";
      request.url = this.appConfig.api.settings;
      request = this.preprocessRequest(request, this);

      this.$http
        .get(request.url)
        .then(res => {
          res = this.postprocessResponse(res, this);
          this.eventHub.$emit("optsDialogShow", res.data);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.opts {
  position: absolute;
  width: 50px;
  height: 52px;
  // border: 1px solid #f1f5f1;
  border-radius: 24px;
  // background-color: #f3fff6;
  bottom: 70px;
  right: 20px;

  .btn {
    width: 40px;
    height: 40px;
    box-shadow: rgb(69 91 99 / 10%) 0px 6px 12px;
    border-radius: 50% !important;
    background-color: #f8f8f8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #efefef;
    margin: 10px 0 0 4px;

    .hover {
      display: none;
    }
    &.active {
      background-color: white;

      .inactive {
        display: none;
      }

      [data-icon] {
        color: #277a3e;
      }
    }
    &:not(.active) {
      .active {
        display: none;
      }
    }

    &:first-of-type {
      margin-top: 5px;
    }

    [data-icon] {
      color: #b3c5c2;
      font-size: 20px;
    }

    &:hover {
      [data-icon],
      img {
        filter: brightness(90%);
      }
    }
  }
}
</style>
