import { render, staticRenderFns } from "./AliveDialog.vue?vue&type=template&id=1f7266de&scoped=true&"
import script from "./AliveDialog.vue?vue&type=script&lang=js&"
export * from "./AliveDialog.vue?vue&type=script&lang=js&"
import style0 from "./AliveDialog.vue?vue&type=style&index=0&id=1f7266de&scoped=true&lang=scss&"
import style1 from "./AliveDialog.vue?vue&type=style&index=1&id=1f7266de&scoped=true&lang=scss&"
import style2 from "./AliveDialog.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f7266de",
  null
  
)

export default component.exports