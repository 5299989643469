<template>
  <a
    v-on:click="pickOrigin"
    ref="pickOrigin"
    v-tooltip="$t('components.Panel.tooltip.pickOrigin')"
    v-html="$parent.descriptor(origin)"
  ></a>
</template>

<script>
export default {
  name: "OriginBtn",
  props: ['origin'],
  data() {
    return {};
  },
  methods: {
    pickOrigin() {
      // @vuese
      // Signal to show origin marker configuration modal
      // @arg `void`
      this.eventHub.$emit("originPickerShow");
    },
  },
};
</script>