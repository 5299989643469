const GetRequest = {
    uid: "",
    url: "",
    method: "get",
    timeout: 5000,
    headers: {
        "content-type": "application/json; charset=utf-8",
    },
};

export default GetRequest;
