<template>
    <modal name="walkPickerDialog" ref="walkPickerDialog" class="vue-dialog" width="400px" height="auto" :shiftX="0.5"
        :shiftY="0.25" id="walkPickerDialog">
        <div class="vue-dialog-content">

            <div class="vue-dialog-content-title">{{ $t("components.WalkPicker.dialog.title") }}</div>
            <div class="line-15px"></div>
            <div class="opts">
                <div class="values">
                    <span class="left">How far do you want to walk?</span>
                    <span class="right bold pull-right">{{
        kms > 0 ? prettyKms(kms) : "No walking"
    }}</span>
                </div>
                <vue-slider ref="slider" v-model="kms" v-bind="options.kms" />
            </div>
            <div class="opts">
                <p class="subheading is-first">How fast do you walk?</p>
                <ul>
                    <li v-for="option in options.walking" :key="option" v-on:click="pick(option)"
                        :class="{ active: selected.walking == option }" class="pointer">
                        <font-awesome-icon icon="check"></font-awesome-icon>
                        <div class="bold">{{ $t(`components.WalkPicker.option.${option}.title`) }}</div>
                        <div class="line-10px"></div>
                        <div class="light gray desc">{{ $t(`components.WalkPicker.option.${option}.description`) }}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="opts">
                <p class="subheading">Avoid stairs</p>
                <ul>
                    <li v-on:click="options.avoidStairsWalk = !options.avoidStairsWalk"
                        :class="{ active: options.avoidStairsWalk }" class="pointer">
                        <font-awesome-icon icon="check"></font-awesome-icon>
                        <div class="bold">
                            {{ options.avoidStairsWalk ? "Yes" : "No" }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="vue-dialog-buttons">
            <button v-on:click="hide()" type="button" class="vue-dialog-button">
                {{ $t("components.WalkPicker.buttons.cancel") }}
            </button>
            <button v-on:click="
        save();
    hide();
    " type="button" class="vue-dialog-button vue-dialog-button-success">
                {{ $t("components.WalkPicker.buttons.okay") }}
            </button>
        </div>
    </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";
import System from "../mixins/System.vue";

// Modal dialog component for selecting the accepted range of walking for planned trip
// @group Dialogs
export default {
    name: "WalkPicker",
    data() {
        return {
            options: {
                walking: ["walkSlow", "walkAverage", "walkFast"],
                kms: {
                    dotSize: 14,
                    width: "auto",
                    height: 4,
                    contained: false,
                    direction: "ltr",
                    data: null,
                    min: 0,
                    max: 25,
                    interval: 0.1,
                    disabled: false,
                    clickable: true,
                    duration: 0.5,
                    adsorb: false,
                    lazy: false,
                    tooltip: "active",
                    tooltipPlacement: "top",
                    tooltipFormatter: void 0,
                    useKeyboard: false,
                    keydownHook: null,
                    dragOnClick: false,
                    enableCross: true,
                    fixed: false,
                    minRange: void 0,
                    maxRange: void 0,
                    order: true,
                    marks: false,
                    dotOptions: void 0,
                    process: true,
                    dotStyle: void 0,
                    railStyle: void 0,
                    processStyle: void 0,
                    tooltipStyle: void 0,
                    stepStyle: void 0,
                    stepActiveStyle: void 0,
                    labelStyle: void 0,
                    labelActiveStyle: void 0,
                },
                avoidStairsWalk: false,
            },
            selected: {
                walking: this.$store.getters.walk,
            },
            kms: this.$store.getters.walkKms,
            
        };
    },
    mixins: [Ui, System],
    mounted() {
        this.eventHub.$on("walkPickerShow", this.show);
        this.eventHub.$on("walkPickerHide", this.hide);
    },
    methods: {
        // @vuese
        // Shows modal dialog
        // @arg `void`
        show() {
            this.selected.walking = this.$store.getters.walk;
            this.$modal.show("walkPickerDialog");
        },
        // @vuese
        // Hides modal dialog
        // @arg `void`
        hide() {
            this.$modal.hide("walkPickerDialog");
        },
        // @vuese
        // Saves selected values to local store and suggest UI update
        // @arg `void`
        save() {
            this.$store.commit("walk", this.selected.walking);
            this.$store.commit("walkKms", this.kms);
            this.$store.commit("avoidStairsWalk", this.options.avoidStairsWalk);
            // @vuese
            // Suggest fetching new plans because user has commited changes
            // @arg `void`
            this.eventHub.$emit("suggestUpdate");
        },
        // @vuese
        // saves selected value to instance
        // @arg `String` {option} picked value
        pick(option) {
            this.selected.walking = option;
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";
</style>

<style scoped lang="scss">
.vue-slider {
    margin: 30px 0 20px 0;
}

@media (min-width: 800px) {
    // #walkPickerDialog::v-deep .vm--modal {
    //   overflow: visible !important;
    //   left: 243px !important;
    //   top: 153px !important;
    // }
}
</style>
