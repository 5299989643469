<script>

// @vuese
// Modifies the `json` responses from `Connector` for the web application needs
// @group Engine/Mixins
export default {
  name: "Postprocessor",
  methods: {
    postprocessResponse(response, context) {
      // Get app from context, which is a component calling this mixin
      const app = context.$root;
      return app.$hooks.postprocessResponse(response, context);
      
    }
  }
};
</script>