<template>
  <div class="panel" :data-minimized="minimized ? 'true' : 'false'">
    <p class="coordinates">
      <OriginBtn :origin="origin" />
      <ViaAdd :index="0" />
      <span v-for="(waypoint, i) in via" v-bind:key="i">
        <WaypointBtn :waypoint="waypoint" :index="i" />
        <ViaAdd :index="1 + i" />
        <ViaDel :index="i" />
      </span>
      <DestinationBtn :destination="destination" />
    </p>

    <p class="dt">
      <a v-on:click="pickTime" ref="pickTime" v-tooltip="$t('components.Panel.tooltip.pickTime')">
        <label>{{ timeSummary(time) }}</label>
      </a>
    </p>

    <div>
      <label class="large">Transport modes</label>

      <p v-show="isModeAllowed('publicTransport')" class="transport boolean"
        :data-checked="shouldBeChecked('publicTransport') ? 'true' : 'false'">
        <font-awesome-icon v-on:click="toggle('publicTransport')" :icon="['far', 'square']"></font-awesome-icon>
        <font-awesome-icon v-on:click="toggle('publicTransport')" :icon="['far', 'check-square']"></font-awesome-icon>
        <label>Public transport</label>
        <a v-on:click="pickPublicTransport" ref="pickPublicTransport"
          v-tooltip="$t('components.Panel.tooltip.pickPublicTransport')"><font-awesome-icon
            :icon="['fas', 'cog']"></font-awesome-icon></a>
        <small class="summary">
          · {{ listPt(pt) }}<br />
          ·
          {{
    transfers > 0
      ? transfers +
      " " +
      $t(
        transfers > 1
          ? $t("components.Panel.vals.transfers")
          : $t("components.Panel.vals.transfer")
      )
      : $t("components.Panel.vals.noTransfers")
  }}<br />
          · Fares: {{ list(userFares) }}
        </small>
      </p>

      <p v-show="isModeAllowed('cycling')" class="cycling boolean"
        :data-checked="shouldBeChecked('cycling') ? 'true' : 'false'">
        <font-awesome-icon v-on:click="toggle('cycling')" :icon="['far', 'square']"></font-awesome-icon>
        <font-awesome-icon v-on:click="toggle('cycling')" :icon="['far', 'check-square']"></font-awesome-icon>
        <label>Bike</label>

        <a v-on:click="pickCycling" ref="pickCycling"
          v-tooltip="$t('components.Panel.tooltip.pickCycling')"><font-awesome-icon
            :icon="['fas', 'cog']"></font-awesome-icon></a>
        <small class="summary">
          <template v-show="cyclingKms > 0.0">
            · Ride up to {{ cyclingKms }} km<br />
          </template>
          · Speed is set to
          {{
    $t(
      `components.CyclingPicker.option.${cycling}.title`
    ).toLowerCase()
  }}<br />
        </small>
      </p>

      <p v-show="isModeAllowed('walking')" class="walking boolean"
        :data-checked="shouldBeChecked('walking') ? 'true' : 'false'">
        <font-awesome-icon v-on:click="toggle('walking')" :icon="['far', 'square']"></font-awesome-icon>
        <font-awesome-icon v-on:click="toggle('walking')" :icon="['far', 'check-square']"></font-awesome-icon>
        <label>Walk</label>
        <a v-on:click="pickWalk" ref="pickWalk" v-tooltip="$t('components.Panel.tooltip.pickWalk')"><font-awesome-icon
            :icon="['fas', 'cog']"></font-awesome-icon></a>
        <small class="summary">
          <template v-show="walkKms > 0.0">
            · Walk up to {{ walkKms }} km<br />
          </template>
          · Speed is set to
          {{ $t(`components.WalkPicker.option.${walk}.title`).toLowerCase()
          }}<br />
        </small>
      </p>

      <p v-show="isModeAllowed('car')" class="car boolean"
        :data-checked="shouldBeChecked('car') ? 'true' : 'false'">
        <font-awesome-icon v-on:click="toggle('car')" :icon="['far', 'square']"></font-awesome-icon>
        <font-awesome-icon v-on:click="toggle('car')" :icon="['far', 'check-square']"></font-awesome-icon>
        <label>Car</label>

        <a v-on:click="pickCar" ref="pickCar" v-tooltip="$t('components.Panel.tooltip.pickCar')"><font-awesome-icon
            :icon="['fas', 'cog']"></font-awesome-icon></a>
        <small class="summary">
          <template v-show="drivingKms > 0.0">
            · Drive up to {{ drivingKms }} km<br />
          </template>
        </small>
      </p>

      <p v-show="isModeAllowed('sharedBikes')" class="bikesharing boolean"
        :data-checked="shouldBeChecked('sharedBikes') ? 'true' : 'false'">
        <font-awesome-icon v-on:click="toggle('sharedBikes')" :icon="['far', 'square']"></font-awesome-icon>
        <font-awesome-icon v-on:click="toggle('sharedBikes')" :icon="['far', 'check-square']"></font-awesome-icon>
        <label>Shared bike</label>
        <a v-on:click="pickBikesharing" ref="pickBikesharing"
          v-tooltip="$t('components.Panel.tooltip.pickBikesharing')"><font-awesome-icon
            :icon="['fas', 'cog']"></font-awesome-icon></a>
        <small class="summary">
          · {{ listBikesharing(bikesharing) }}<br />
        </small>
      </p>

      <p v-show="isModeAllowed('sharedScooters')" class="scootersharing boolean"
        :data-checked="shouldBeChecked('sharedScooters') ? 'true' : 'false'">
        <font-awesome-icon v-on:click="toggle('sharedScooters')" :icon="['far', 'square']"></font-awesome-icon>
        <font-awesome-icon v-on:click="toggle('sharedScooters')" :icon="['far', 'check-square']"></font-awesome-icon>
        <label>Shared scooter</label>
        <a v-on:click="pickScootersharing" ref="pickScootersharing"
          v-tooltip="$t('components.Panel.tooltip.pickScootersharing')"><font-awesome-icon
            :icon="['fas', 'cog']"></font-awesome-icon></a>
        <small class="summary">
          · {{ listScootersharing(scootersharing) }}<br />
        </small>
      </p>

      <p v-show="isModeAllowed('sharedMopeds')" class="mopedsharing boolean"
        :data-checked="shouldBeChecked('sharedMopeds') ? 'true' : 'false'">
        <font-awesome-icon v-on:click="toggle('sharedMopeds')" :icon="['far', 'square']"></font-awesome-icon>
        <font-awesome-icon v-on:click="toggle('sharedMopeds')" :icon="['far', 'check-square']"></font-awesome-icon>
        <label>Shared moped</label>
        <a v-on:click="pickMopedsharing" ref="pickMopedsharing"
          v-tooltip="$t('components.Panel.tooltip.pickMopedsharing')"><font-awesome-icon
            :icon="['fas', 'cog']"></font-awesome-icon></a>
        <small class="summary">
          · {{ listMopedsharing(mopedsharing) }}<br />
        </small>
      </p>

      <p v-show="isModeAllowed('sharedCars')" class="carsharing boolean"
        :data-checked="shouldBeChecked('sharedCars') ? 'true' : 'false'">
        <font-awesome-icon v-on:click="toggle('sharedCars')" :icon="['far', 'square']"></font-awesome-icon>
        <font-awesome-icon v-on:click="toggle('sharedCars')" :icon="['far', 'check-square']"></font-awesome-icon>
        <label>Shared car</label>
        <a v-on:click="pickCarsharing" ref="pickCarsharing"
          v-tooltip="$t('components.Panel.tooltip.pickCarsharing')"><font-awesome-icon
            :icon="['fas', 'cog']"></font-awesome-icon></a>
        <small class="summary">
          · {{ listCarsharing(carsharing) }}<br />
        </small>
      </p>

      <p v-show="isModeAllowed('taxi')" class="taxi boolean"
        :data-checked="shouldBeChecked('taxi') ? 'true' : 'false'">
        <font-awesome-icon v-on:click="toggle('taxi')" :icon="['far', 'square']"></font-awesome-icon>
        <font-awesome-icon v-on:click="toggle('taxi')" :icon="['far', 'check-square']"></font-awesome-icon>
        <label>Taxi</label>
        <a v-on:click="pickTaxisharing" ref="pickTaxisharing"
          v-tooltip="$t('components.Panel.tooltip.pickTaxisharing')"><font-awesome-icon
            :icon="['fas', 'cog']"></font-awesome-icon></a>
        <small class="summary">
          · {{ listTaxisharing(taxisharing) }}<br />
        </small>
      </p>
    </div>

    <SettingsMinimizer ref="settingsMinimizer" />
    <UpdateBtn />
    <Spinner />

    <PlanNotFound />
    <PlanFoundWithErrors />

    <PlanPicker />
  </div>
</template>

<script>
import Ui from "./mixins/Ui.vue";
import Converter from "./mixins/Converter.vue";
import PlanPicker from "@/components/map/PlanPicker.vue";
import PlanNotFound from "@/components/map/PlanNotFound.vue";
import PlanFoundWithErrors from "@/components/map/PlanFoundWithErrors.vue";
import UpdateBtn from "@/components/map/UpdateBtn.vue";
import OriginBtn from "@/components/buttons/panel/OriginBtn.vue";
import DestinationBtn from "@/components/buttons/panel/DestinationBtn.vue";
import ViaAdd from "@/components/buttons/panel/ViaAdd.vue";
import ViaDel from "@/components/buttons/panel/ViaDel.vue";
import WaypointBtn from "@/components/buttons/panel/WaypointBtn.vue";

import Spinner from "@/components/map/Spinner.vue";
import SettingsMinimizer from "@/components/map/SettingsMinimizer.vue";

// Paints the left configuration panel; handles changes and saves them to local store
// @group App
export default {
  name: "Panel",
  mixins: [Ui, Converter],
  components: {
    PlanPicker,
    UpdateBtn,
    OriginBtn,
    DestinationBtn,
    ViaAdd,
    ViaDel,
    WaypointBtn,
    Spinner,
    SettingsMinimizer,
    PlanNotFound,
    PlanFoundWithErrors,
  },
  data() {
    return {
      minimized: true,
    };
  },
  computed: {
    walk() {
      return this.$store.getters.walk;
    },
    cycling() {
      return this.$store.getters.cycling;
    },
    cyclingKms() {
      return this.$store.getters.cyclingKms;
    },
    drivingKms() {
      return this.$store.getters.drivingKms;
    },
    walkKms() {
      return this.$store.getters.walkKms;
    },
    driving() {
      return this.$store.getters.driving;
    },
    transport() {
      return this.$store.getters.transport;
    },
    litacka() {
      return this.$store.getters.litacka;
    },
    bikesharing() {
      return this.$store.getters.bikesharing;
    },
    scootersharing() {
      return this.$store.getters.scootersharing;
    },
    mopedsharing() {
      return this.$store.getters.mopedsharing;
    },
    carsharing() {
      return this.$store.getters.carsharing;
    },
    taxisharing() {
      return this.$store.getters.taxisharing;
    },
    transfers() {
      return this.$store.getters.transfers;
    },
    pt() {
      return this.$store.getters.pt;
    },
    time() {
      return this.$store.getters.time;
    },
    origin() {
      return this.$store.getters.origin;
    },
    destination() {
      return this.$store.getters.destination;
    },
    via() {
      return this.$store.getters.via;
    },
    checkboxes() {
      return this.$store.getters.checkboxes;
    },
    userFares() {
      return this.$store.getters.userFares;
    },
    allowedTransportModesSuper() {
      return this.$store.getters.appSettings.allowedTransportModes || [];
    },
  },
  mounted() {
    this.eventHub.$on("updatePanelMinimizedStatus", (status) => {
      this.minimized = status;
    });
    this.eventHub.$on("configurePanel", (response) => {
      this.configurePanel(response);
    });
    this.eventHub.$on("redrawPanel", () => {
      this.$forceUpdate();
    });
  },
  methods: {
    isModeAllowed(mode) {
      return this.allowedTransportModesSuper.includes(mode);
    },
    shouldBeChecked(what) {
      return this.checkboxes[what] && this.isModeAllowed(what);
    },
    toggle(what) {
      let state = this.checkboxes;
      state[what] = !state[what];
      this.$store.commit("checkboxes", state);
    },
    pickWalk() {
      // @vuese
      // Signal to show walk configuration modal
      // @arg `void`
      this.eventHub.$emit("walkPickerShow");
    },
    pickCycling() {
      // @vuese
      // Signal to show cycling configuration modal
      // @arg `void`
      this.eventHub.$emit("cyclingPickerShow");
    },
    pickTransport() {
      // @vuese
      // Signal to show transport types configuration modal
      // @arg `void`
      this.eventHub.$emit("transportPickerShow");
    },
    pickPublicTransport() {
      // @vuese
      // Signal to show public transport configuration modal
      // @arg `void`
      this.eventHub.$emit("publicTransportPickerShow");
    },
    pickLitacka() {
      // @vuese
      // Signal to show modal with litacka card checkbox
      // @arg `void`
      // @deprecated
      this.eventHub.$emit("litackaPickerShow");
    },
    pickTransfers() {
      // @vuese
      // Signal to show modal to pick max number of transfers
      // @arg `void`
      // @deprecated
      this.eventHub.$emit("transfersPickerShow");
    },
    pickPT() {
      // @vuese
      // Signal to show public transport configuration modal
      // @arg `void`
      // @deprecated
      this.eventHub.$emit("ptPickerShow");
    },
    pickBikesharing() {
      // @vuese
      // Signal to show bike sharing configuration modal
      // @arg `void`
      this.eventHub.$emit("bikesharingPickerShow");
    },
    pickScootersharing() {
      // @vuese
      // Signal to show scooter sharing configuration modal
      // @arg `void`
      this.eventHub.$emit("scootersharingPickerShow");
    },
    pickMopedsharing() {
      // @vuese
      // Signal to show scooter sharing configuration modal
      // @arg `void`
      this.eventHub.$emit("mopedsharingPickerShow");
    },
    pickCarsharing() {
      // @vuese
      // Signal to show car sharing configuration modal
      // @arg `void`
      this.eventHub.$emit("carsharingPickerShow");
    },
    pickTaxisharing() {
      // @vuese
      // Signal to show taxi providers configuration modal
      // @arg `void`
      this.eventHub.$emit("taxisharingPickerShow");
    },
    pickTime() {
      // @vuese
      // Signal to show date & time configuration modal
      // @arg `void`
      this.eventHub.$emit("timePickerShow");
    },
    toggleSettings() {
      // @vuese
      // Signal to show detailed settings modal
      // @arg `void`
      this.eventHub.$emit("detailedSettingsShow");
    },
    pickOrigin() {
      // @vuese
      // Signal to show origin marker configuration modal
      // @arg `void`
      this.eventHub.$emit("originPickerShow");
    },
    pickDestination() {
      // @vuese
      // Signal to show destination marker configuration modal
      // @arg `void`
      this.eventHub.$emit("destinationPickerShow");
    },
    pickCar() {
      // @vuese
      //
      // @arg `void`
      this.eventHub.$emit("carPickerShow");
    },
    // @vuese
    // Converts Point (marker) to address with coords
    // @arg `Object` {data}
    descriptor(data) {
      if ("point" == data.mode && data.name && data.name.length) {
        if (data.point && data.point.lat && data.point.lng) {
          return `${data.name} <span class="note">${data.point.lat.toFixed(
            6
          )}, ${data.point.lng.toFixed(6)}</span>`;
        } else {
          return "-";
        } // end if-else
      } else if ("stopIds" == data.mode) {
        return `${data.name ? data.name : 'Stop IDs'} <span class="note">${data.stopIds}</span>`;
      } else {
        return this.$t(`components.Panel.vals.${data.mode}`);
      }
    },
    // @vuese
    // Stringifies list values into displayable value
    // @arg `Array` {items}
    list(items) {
      let o = [];
      items.forEach((item) => {
        o.push(
          item.charAt(0).toUpperCase() +
          item.toLowerCase().slice(1).replace("_", " ")
        );
      });
      return o.length ? o.join(", ") : "None selected";
    },
    listSummary(items) {
      return items.length ? `${items.length} selected` : "None selected";
    },
    listPt(items) {
      return items.length
        ? `${items.length} vehicles selected`
        : "No vehicle selected";
    },
    listBikesharing(items) {
      return items.length
        ? `${items.length} bike sharing operators selected`
        : "No bike sharing";
    },
    listScootersharing(items) {
      return items.length
        ? `${items.length} scooter sharing operators selected`
        : "No scooter sharing";
    },
    listMopedsharing(items) {
      return items.length
        ? `${items.length} moped sharing operators selected`
        : "No moped sharing";
    },
    listCarsharing(items) {
      return items.length
        ? `${items.length} operators selected`
        : "No operators selected";
    },
    listTaxisharing(items) {
      return items.length
        ? `${items.length} operators selected`
        : "No operators selected";
    },
    timeSummary(dt) {
      let o = "";
      o += dt.getDate();
      o += ".";
      o += dt.toLocaleString("default", { month: "short" });
      o += ", ";
      o += ("0" + dt.getHours().toString()).slice(-2);
      o += ":";
      o += ("0" + dt.getMinutes().toString()).slice(-2);

      return o;
    },
    configurePanel(response) {
      const settings = response.routingRequest.modesSettings;

      this.$store.commit("autoPlan", false);

      // BikeSharing:
      this.$store.commit("checkbox", [
        "sharedBikes",
        settings.allowedTransportModes.includes("SHARED_BIKE"),
      ]);

      if (settings.sharedBikeSettings) {
        try {
          this.$store.commit(
            "bikesharing",
            settings.sharedBikeSettings.providerSettings.providerIds
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "avoidSteepClimbsBS",
            settings.sharedBikeSettings.avoidSteepClimbs
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "returnBestOnlyRouteBS",
            settings.sharedBikeSettings.providerSettings.returnBestOnlyRoute
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "onlyElectricBS",
            settings.sharedBikeSettings.onlyElectric
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "blockedParkingIdsBS",
            settings.sharedBikeSettings.blockedParkingIds
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "cyclingBS",
            settings.sharedBikeSettings.sharedBikeSpeed
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "cyclingKmsBS",
            settings.sharedBikeSettings.maxDistanceMeters / 1000
          );
        } catch (th) { }
      } // end if

      // CarPicker:
      this.$store.commit("checkbox", [
        "car",
        settings.allowedTransportModes.includes("CAR"),
      ]);

      if (settings.carSettings) {
        try {
          this.$store.commit(
            "drivingKms",
            settings.carSettings.maxDistanceMeters / 1000
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "maxParkingPriceCzkPerHour",
            settings.carSettings.maxParkingPriceCzkPerHour
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "expectedParkingTimeMinutes",
            settings.carSettings.expectedParkingTimeMinutes
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "blockedParkingIdsC",
            settings.carSettings.blockedParkingIds
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "pricePerKmInCzk",
            settings.carSettings.pricePerKmInCzk
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "useTollFreeRoads",
            settings.carSettings.useTollFreeRoads
          );
        } catch (th) { }

        try {
          this.$store.commit("parkCar", settings.carSettings.parkCar);
        } catch (th) { }

        try {
          this.$store.commit(
            "parkingZoneTypes",
            settings.carSettings.parkingZoneTypes
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "carParkingTypes",
            settings.carSettings.carParkingTypes
          );
        } catch (th) { }
      } // end if

      // CarsharingPicker:
      this.$store.commit("checkbox", [
        "sharedCars",
        settings.allowedTransportModes.includes("SHARED_CAR"),
      ]);

      if (settings.sharedCarSettings) {
        try {
          this.$store.commit(
            "carsharing",
            settings.sharedCarSettings.providerSettings.providerIds
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "allowElectricCS",
            settings.sharedCarSettings.allowElectric
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "allowCombustionCS",
            settings.sharedCarSettings.allowCombustion
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "returnBestOnlyRouteCS",
            settings.sharedCarSettings.providerSettings.returnBestOnlyRoute
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "blockedParkingIdsCS",
            settings.sharedCarSettings.blockedParkingIds
          );
        } catch (th) { }
      } // end if

      // CyclingPicker:
      this.$store.commit("checkbox", [
        "cycling",
        settings.allowedTransportModes.includes("BICYCLE"),
      ]);

      if (settings.bicycleSettings) {
        try {
          let speed = null;

          switch (settings.bicycleSettings.bicycleSpeed) {
            case "SLOW":
              speed = "cycleSlow";
              break;
            case "QUICK":
              speed = "cycleFast";
              break;
            case "MEDIUM":
            default:
              speed = "cycleAverage";
              break;
          } // end switch

          this.$store.commit("cycling", speed);
        } catch (th) { }

        try {
          this.$store.commit(
            "cyclingKms",
            settings.bicycleSettings.maxDistanceMeters / 1000
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "avoidSteepClimbsB",
            settings.bicycleSettings.avoidSteepClimbs
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "parkBicycle",
            settings.bicycleSettings.parkBicycle
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "bicycleInPt",
            settings.bicycleSettings.bicycleInPt
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "blockedParkingIdsB",
            settings.bicycleSettings.blockedParkingIds
          );
        } catch (th) { }
      } // end if

      // OriginPicker
      const originOpts = {};
      if (response.routingRequest.origin.geoLocation) {
        originOpts.ll = {
          lat: response.routingRequest.origin.geoLocation.lat,
          lng: response.routingRequest.origin.geoLocation.lon,
        };
      } // end if
      originOpts.stopIds = response.routingRequest.origin.stopIds ? response.routingRequest.origin.stopIds.join(",") : null;
      originOpts.mode = originOpts.ll ? "point" : "stopIds";
      originOpts.name = originOpts.stopIds ? originOpts.stopIds : null;
      this.eventHub.$emit("setOrigin", originOpts);

      // DestinationPicker
      const destinationOpts = {};
      if (response.routingRequest.destination.geoLocation) {
        destinationOpts.ll = {
          lat: response.routingRequest.destination.geoLocation.lat,
          lng: response.routingRequest.destination.geoLocation.lon,
        };
      } // end if
      destinationOpts.stopIds = response.routingRequest.destination.stopIds ? response.routingRequest.destination.stopIds.join(",") : null;
      destinationOpts.mode = destinationOpts.ll ? "point" : "stopIds";
      destinationOpts.name = destinationOpts.stopIds ? destinationOpts.stopIds : null;
      this.eventHub.$emit("setDestination", destinationOpts);

      if (response.routingRequest.waypoints?.length) {
        let via = [];

        response.routingRequest.waypoints.forEach((waypoint) => {
          const waypointOpts = {};
          if (waypoint.geoLocation) {
            waypointOpts.ll = {
              lat: waypoint.geoLocation.lat,
              lng: waypoint.geoLocation.lon,
            };
          } else {
            if (waypoint.geoLocation) {
              waypointOpts.ll = {
                lat: waypoint.geoLocation.lat,
                lng: waypoint.geoLocation.lon,
              };
            } // end if
          } // end if-else
          waypointOpts.stopIds = waypoint.stopIds ? waypoint.stopIds.join(",") : null;
          waypointOpts.mode = waypointOpts.ll ? "point" : "stopIds";
          waypointOpts.name = waypointOpts.stopIds ? waypointOpts.stopIds : null;
          waypointOpts.silent = true;

          via.push(waypointOpts);
        });

        this.$store.commit("via", via);
      } // end if

      // MopedSharingPicker:
      this.$store.commit("checkbox", [
        "sharedMopeds",
        settings.allowedTransportModes.includes("SHARED_MOPED"),
      ]);

      if (settings.sharedMopedSettings) {
        try {
          this.$store.commit(
            "mopedsharing",
            settings.sharedMopedSettings.providerSettings.providerIds
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "allowElectricMS",
            settings.sharedMopedSettings.allowElectric
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "allowCombustionMS",
            settings.sharedMopedSettings.allowCombustion
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "returnBestOnlyRouteMS",
            settings.sharedMopedSettings.providerSettings.returnBestOnlyRoute
          );
        } catch (th) { }
      } // end if

      // PublicTransportPicker:
      this.$store.commit("checkbox", [
        "publicTransport",
        settings.allowedTransportModes.includes("PT"),
      ]);

      if (settings.ptSettings) {
        try {
          this.$store.commit(
            "transfers",
            settings.ptSettings.maxPtInterchanges
          );
        } catch (th) { }

        try {
          this.$store.commit("pt", settings.ptSettings.allowedPtVehicles);
        } catch (th) { }

        try {
          this.$store.commit(
            "loweredFloorOnly",
            settings.ptSettings.lowFloorTripsOnly
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "stairsFreeWalkSegments",
            settings.ptSettings.stairsFreeWalkSegments
          );
        } catch (th) { }

        try {
          let transferSpeed = null;

          switch (settings.ptSettings.transferSpeed) {
            case "SLOW":
              transferSpeed = "transferSlow";
              break;
            case "QUICK":
              transferSpeed = "transferFast";
              break;
            case "MEDIUM":
            default:
              transferSpeed = "transferAverage";
              break;
          } // end switch

          this.$store.commit("transferSpeed", transferSpeed);
        } catch (th) { }

        try {
          if (
            settings.ptSettings.userFareInfo &&
            settings.ptSettings.userFareInfo.customerProfiles &&
            settings.ptSettings.userFareInfo.customerProfiles.length
          ) {
            this.$store.commit(
              "cp",
              settings.ptSettings.userFareInfo.customerProfiles[0].id
            );
          } // end if
        } catch (th) { }

        try {
          let zones = [];
          settings.ptSettings.userFareInfo.coupons.forEach((coupon) => {
            coupon.validInZones.forEach((zoneId) => {
              zones.push(zoneId);
            });
          });
          zones = [...new Set(zones)];
          this.$store.commit("userFares", zones);
        } catch (th) { }
      } // end if

      // ScooterSharingPicker:
      this.$store.commit("checkbox", [
        "sharedScooters",
        settings.allowedTransportModes.includes("SHARED_SCOOTER"),
      ]);

      if (settings.sharedScooterSettings) {
        try {
          this.$store.commit(
            "scootersharing",
            settings.sharedScooterSettings.providerSettings.providerIds
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "returnBestOnlyRouteSS",
            settings.sharedScooterSettings.providerSettings.returnBestOnlyRoute
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "onlyElectricSS",
            settings.sharedScooterSettings.onlyElectric
          );
        } catch (th) { }
      } // end if

      // TaxisharingPicker:
      this.$store.commit("checkbox", [
        "taxi",
        settings.allowedTransportModes.includes("TAXI"),
      ]);

      if (settings.onDemandSettings) {
        try {
          this.$store.commit(
            "taxisharing",
            settings.onDemandSettings.providerSettings.providerIds
          );
        } catch (th) { }

        try {
          this.$store.commit(
            "returnBestOnlyRouteOD",
            settings.onDemandSettings.providerSettings.returnBestOnlyRoute
          );
        } catch (th) { }
      } // end if

      // TimePicker:
      if (response.routingRequest.departure) {
        this.$store.commit("time", new Date(response.routingRequest.departure));
      } else {
        this.$store.commit("time", new Date());
      } // end if-else

      // WalkPicker:
      this.$store.commit("checkbox", [
        "walking",
        settings.allowedTransportModes.includes("WALK"),
      ]);

      if (settings.walkSettings) {
        try {
          let walkSpeed = null;
          switch (settings.walkSettings.walkSpeed) {
            case "SLOW":
            case 2.5: // Keeping this here for backwards compatibility
              walkSpeed = "walkSlow";
              break;
            case "QUICK":
            case 7.0: // Keeping this here for backwards compatibility
              walkSpeed = "walkFast";
              break;
            case "MEDIUM":
            case 5.0: // Keeping this here for backwards compatibility
            default:
              walkSpeed = "walkAverage";
              break;
          } // end switch
          this.$store.commit("walk", walkSpeed);
        } catch (th) { }

        try {
          this.$store.commit(
            "walkKms",
            settings.walkSettings.maxDistanceMeters / 1000
          );
        } catch (th) { }
      } // end if

      this.$parent.$refs.publicTransportPicker.reset();
      this.$parent.$refs.cyclingPicker.reset();
      this.$parent.$refs.walkPicker.reset();
      this.$parent.$refs.carPicker.reset();
      this.$parent.$refs.bikesharingPicker.reset();
      this.$parent.$refs.scootersharingPicker.reset();
      this.$parent.$refs.mopedsharingPicker.reset();
      this.$parent.$refs.carsharingPicker.reset();
      this.$parent.$refs.taxisharingPicker.reset();
      this.$parent.$refs.timePicker.reset();

      setTimeout(() => {
        this.$store.commit("autoPlan", true);
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";

.panel {
  // text-align: center;
  color: #2c3e50;
  z-index: 20;
  padding: 30px 20px;
  position: fixed;
  top: 20px;
  left: 20px;
  border-radius: 6px;
  max-height: calc(100vh - 100px);
  background-color: white;
  width: 330px;
  overflow: auto;

  p {
    padding: 20px 0 0 20px;
    text-align: left;
    line-height: 1.6;
    font-size: 1rem;
    font-weight: 300;

    svg[data-icon]:not(.via) {
      color: #7b7b7b;
      position: relative;
      left: -5px;
      top: 2px;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        filter: brightness(120%);
      }
    }

    label {
      // display: block;
      font-size: 15px;
      // text-transform: uppercase;
      margin: 0 2px 0 5px;
      padding: 1px 5px;
    }
  }

  label.large {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #349a51;
    padding: 4px 5px;
    margin: 25px 0 0 0px;
    color: white;
  }

  .logo {
    display: block;
    margin: auto;
    width: 75px;
    height: 75px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .coordinates {
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 0;

    a {
      color: #7b7b7b;
      display: block;
      font-weight: normal;
      text-decoration: none;
      padding: 4px 10px;
      word-break: break-word;

      &:hover {
        background-color: #349a511a;
        color: black;
      }

      &:nth-child(2) {
        border-top: 1px solid #eee;
      }
    }
  }

  .walking {
    b {
      font-weight: bold;
    }
  }

  &[data-minimized="true"] {

    .walking,
    .cycling,
    .transport,
    .pt,
    .car,
    .taxi,
    // .update,
    .litacka,
    .bikesharing,
    .scootersharing,
    .mopedsharing,
    .carsharing,
    .taxisharing,
    .transfers,
    label.large {
      display: none;
    }
  }

  .settings {
    padding: 10px 0 0 0;
    margin-bottom: -15px;
    float: left;

    a {
      font-weight: 500;
      text-decoration: none;

      label {
        cursor: pointer;
      }
    }
  }

  .dt {
    padding: 10px 0 0 0;
    margin-bottom: -15px;
    text-align: right;

    a {
      font-weight: 500;
      text-decoration: none;

      label {
        cursor: pointer;
      }
    }
  }

  p.boolean {
    a {
      float: right;
    }

    &[data-checked="false"] {
      [data-icon="check-square"] {
        display: none;
      }

      small {
        display: none;
      }

      a {
        pointer-events: none;
        opacity: 0.1;
      }
    }

    &[data-checked="true"] {
      [data-icon="square"] {
        display: none;
      }
    }
  }

  small {
    font-size: smaller;
  }

  .summary {
    display: block;
    margin: 5px 0 0 35px;
    line-height: 1.2;
    font-size: 11px;
  }
}
</style>
<style lang="scss">
.note {
  display: block;
  font-size: 10px;
  color: #444444;

}
</style>
