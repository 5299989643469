<template>
  <div class="plan-mode" :disabled="plans.length == 0">
    <div
      class="btn"
      :class="{active: 'trajectory' == selected}"
      v-on:click="pick('trajectory')"
      v-tooltip="$t('components.PlanMode.tooltip.trajectory')"
    >
      <img class="active" src="../../../public/img/buttons/map--green.svg" alt="Toggle" />
      <img class="inactive" src="../../../public/img/buttons/map--gray.svg" alt="Toggle" />
    </div>
    <div
      class="btn"
      :class="{active: 'transparent' == selected}"
      v-on:click="pick('transparent')"
      v-tooltip="$t('components.PlanMode.tooltip.transparent')"
    >
      <font-awesome-icon icon="eye"></font-awesome-icon>
    </div>
  </div>
</template>

<script>

// Component for switching between different selected plan display modes (solid, transparent, traffic, surface, steepness)
// @group Map
export default {
  name: "PlanMode",
  computed: {
    plans() {
      return this.$store.getters.plans;
    }
  },
  data() {
    return {
      selected: "trajectory"
    };
  },
  mounted() {
    this.selected = this.$store.getters.planMode;
  },
  methods: {
    pick(mode) {
      this.selected = mode;
      this.$store.commit("planMode", mode);
      this.eventHub.$emit("togglePlan", this.$store.getters.activePlan);
    }
  }
};
</script>

<style scoped lang="scss">
.plan-mode {
  position: absolute;
  width: 50px;
  height: 103px;
  border: 1px solid #f1f5f1;
  border-radius: 24px;
  background-color: #f3fff6;
  top: 190px;
  right: 20px;

  .btn {
    width: 40px;
    height: 40px;
    box-shadow: rgb(69 91 99 / 10%) 0px 6px 12px;
    border-radius: 50% !important;
    background-color: #f8f8f8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #efefef;
    margin: 10px 0 0 4px;

    .hover {
      display: none;
    }
    &.active {
      background-color: white;
      
      .inactive { display: none; }

      [data-icon] {
        color: #277a3e;
      }
    }
    &:not(.active) {
      .active { display: none; }
    }

    &:first-of-type {
      margin-top: 5px;
    }

    [data-icon] {
      color: #b3c5c2;
      font-size: 20px;
    }

    &:hover {
      [data-icon], img {
        filter: brightness(90%);
      }
    }
  }

  &[disabled] {
    pointer-events: none;
    filter: grayscale(100%);
  }
}
</style>
