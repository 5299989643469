<template>
  <div v-if="false">
  </div>
</template>

<script>
import L from "leaflet";
import Ui from "../mixins/Ui.vue";
import Preprocessor from "@/components/engine/mixins/Preprocessor.vue";
import GetRequest from "@/components/engine/mixins/requests/get.ts";
import Postprocessor from "@/components/engine/mixins/Postprocessor.vue";

// Toggles layer showing (PT, Carsharing, Bikesharing) stations; handles automatic refresh of positions
// @group Map
export default {
  name: "Stations",
  mixins: [Ui, Preprocessor, Postprocessor],
  computed: {
    plans() {
      return this.$store.getters.plans;
    },
    operators() {
      return this.$store.getters.operators;
    },
  },
  data() {
    return {
      status: {
        pt: false,
        bikesharing: false,
      },
      collections: {
        pt: null,
        bikesharing: null,
      },
      endpoints: {
        pt: this.appConfig.api.stations.pt,
        bikesharing: this.appConfig.api.stations.bikesharing,
      },
      colors: {
        pt: "#d9a373",
        bikesharing: "#d973cb",
        providers: this.appConfig.map.color.providers,
      },
      fetching: {

      }
    };
  },
  mounted() {
    this.eventHub.$on("toggleStations", (data) => {
      if (data.enable != this.status[data.mode]) {
        this.toggle(data.mode);
        this.eventHub.$emit("toggledStations", { mode: data.mode, enabled: this.status[data.mode] });

      } // end if
    });
    this.eventHub.$on("moveend", () => {
      Object.keys(this.status).forEach((mode) => {
        if (this.status[mode]) this.update(mode);
      });
    });
    this.eventHub.$on("operatorUpdated", () => {
      Object.keys(this.status).forEach((mode) => {
        if (this.status[mode]) this.update(mode);
      });
    });

  },
  methods: {
    toggle(mode) {
      this.status[mode] = !this.status[mode];

      if (!this.status[mode]) {
        this.hide(mode);
      }

      if (this.status[mode]) {
        this.update(mode);
      } // end if

    },
    update(mode) {
      this.eventHub.$emit("logMessage", {
        type: "info",
        message: "Fetching stations",
      });

      this.hide(mode);

      if (this.$parent.isFarAway()) {
        this.eventHub.$emit("logMessage", {
          type: "warning",
          message: "The map is zoomed out too much - zoom in to fetch stations",
        });
        return;
      } // end if

      const self = this;

      const providerIds = this.$parent.$refs.vehicles.$refs.bikesharingDetail.getStationsSelection();
      const bbox = this.$store.getters.mapBbox;

      let providerIdsTxt = providerIds.join(",");
      let bboxTxt = [
        bbox._northEast.lat,
        bbox._southWest.lng,
        bbox._southWest.lat,
        bbox._northEast.lng,
      ].join(",");

      if (undefined === this.endpoints[mode]) {
        return;
      } // end if

      const url = this.endpoints[mode]
        .replace("{providerIds}", providerIdsTxt)
        .replace("{boundingBox}", bboxTxt);

      if (self.fetching[mode]) {
        return;
      } // end if

      // Add the ability to call a custom hook before the request is sent,
      // to allow for custom modifications of the base_v1.0 requests.
      let request = { ...GetRequest };
      request.uid = "stations.get";
      request.url = url;
      request.timeout = 60000;
      request = this.preprocessRequest(request, this);

      this.$http
        .get(request.url, {
          timeout: request.timeout,
          headers: request.headers,
        })
        .then((res) => {
          res = this.postprocessResponse(res, this);
          
          self.fetching[mode] = false;

          const stations = [];
          const layerId = `stations_${mode}`;

          if ("pt" == mode) {

            res.data.ptStopsWithRoutes.forEach((item) => {

              let show = false;
              let firstVehicle = null;

              for (let i = 0; i < item.routes.length; i++) {
                const route = item.routes[i];

                  show = true;
                  firstVehicle = route.ptVehicle;
                  break;
              } // end for

              var marker = null;

              marker = self.createMarker(
                L.latLng(item.ptStop.location.lat, item.ptStop.location.lon),
                mode,
                firstVehicle
              );

              let o = "";
              o += `${item.ptStop.gtfsStopName}<br />`;
              o += `(${item.ptStop.gtfsStopId})<br />`;
              o += `Zone ${item.ptStop.gtfsZoneId}<br />`;
              o += `Wheelchair boarding: ${item.ptStop.gtfsWheelchairBoarding ? "Yes" : "No"
                }<br />`;
              o += `<hr />`;

              const routes = [];

              item.routes.forEach((route) => {
                routes.push(route.routeId);
              });

              let unique = [...new Set(routes)];
              o += unique.join(", ");

              marker.o = o;

              stations.push(marker);

              if (null !== marker) {
                marker.on("click", (ev) => {
                  ev.originalEvent.preventDefault();

                  const popup = L.popup()
                    .setLatLng(ev.latlng)
                    .setContent(ev.target.o);
                  this.eventHub.$emit("showPopup", popup);
                });
              } // end if
            });
          } else {
            res.data.data.forEach((provider) => {
              provider.stations.forEach((station) => {
                var marker = null;

                marker = self.createMarker(
                  L.latLng(
                    station.stationInformation.lat,
                    station.stationInformation.lon
                  ),
                  mode,
                  provider.providerId
                );

                let o = "";
                o += `${station.stationInformation.name}<br />`;
                o += `(${station.stationInformation.stationId})<br />`;

                if (station.stationStatus) {
                  o += `Is installed: ${station.stationStatus.isInstalled ? "Yes" : "No"
                    }<br />`;
                  o += `Is renting: ${station.stationStatus.isRenting ? "Yes" : "No"
                    }<br />`;
                  o += `Is returning: ${station.stationStatus.isReturning ? "Yes" : "No"
                    }<br />`;
                } else {
                  o += `Status: UNKNOWN<br />`;
                } // end if-else

                marker.o = o;

                stations.push(marker);

                if (null !== marker) {
                  marker.on("click", (ev) => {
                    ev.originalEvent.preventDefault();

                    const popup = L.popup()
                      .setLatLng(ev.latlng)
                      .setContent(ev.target.o);
                    this.eventHub.$emit("showPopup", popup);
                  });
                }
              });
            });
          }

          // In case multiple requests are queuing
          this.hide(mode);

          const collection = L.featureGroup(stations);
          collection.id = layerId;
          this.collections[mode] = collection;
          this.eventHub.$emit("addLayer", this.collections[mode]);
          this.eventHub.$emit("showLayer", this.collections[mode].id);
        }).catch(ex => {
          self.fetching[mode] = false;
        });
    },
    createMarker(ll, mode, providerId = null) {
      let color = this.smartColor(mode, providerId);

      let icon = this.smartIcon(mode, providerId);
      if (icon) {
        icon = L.divIcon({
          html: `<div class="map-vehicle" style="background-color: ${this.smartColor(mode, providerId)};">${icon}</div>`,
          className: "station-marker",
        });
      } // end if

      return L.marker(ll, {
        icon: icon,
        radius: 5,
        color: color,
        className: "station-marker",
        interactive: true,
      });
    },
    hide(mode) {
      if (this.collections[mode]) {
        this.eventHub.$emit("hideLayer", this.collections[mode].id);
        this.eventHub.$emit("removeLayer", this.collections[mode].id);
      }
    },
  },
};
</script>

