<template>
  <div v-if="false" />
</template>

<script>
import L from "leaflet";

// Reusable component for draggable pin shown on `Map`
// @group Map
export default {
  name: "Pin",
  props: ["type", "ll", "mode", "index"],
  data() {
    return {
      markerId: null,
      coords: null,
    };
  },
  watch: {
    ll(coords) {
      if ("object" == typeof coords) {
        if (null === this.markerId) {
          this.createMarker();
        } else {
          this.marker.setLatLng(coords);
        }
      }
    },
  },
  mounted() {
    if (this.ll) {
      this.createMarker();
    }
    this.listen();
  },
  beforeDestroy() {
    if (this.markerId) {
      this.eventHub.$emit("removeLayer", this.markerId);
    }
  },
  methods: {
    createMarker() {
      const $self = this;

      const id = `${this.type}_marker`;
      const ll = this.ll;
      const marker = L.marker(L.latLng(ll.lat, ll.lng), {
        draggable: true,
        icon: L.icon({
          iconUrl: `./img/markers/${this.type}.svg`,
          iconSize: [25, 32],
          iconAnchor: [12.5, 32],
          className: "pointer-events-enable",
        }),
        opacity: 1,
      });
      marker.on("click", $self.onclick);
      marker.on("dragend", $self.dragend);
      marker.id = id;

      this.markerId = id;
      this.marker = marker;
      this.coords = ll;

      this.eventHub.$emit("addLayer", marker);
    },
    listen() {
      this.eventHub.$on(`${this.type}PinShow`, (data) => {
        let proceed = true;

        if ("waypoint" == this.type && data.index != this.index) {
          proceed = false;
        } // end if

        if (proceed) this.eventHub.$emit("showLayer", this.markerId);
      });
      this.eventHub.$on(`${this.type}PinHide`, (data) => {
        let proceed = true;

        if ("waypoint" == this.type && data.index != this.index) {
          proceed = false;
        } // end if

        if (proceed) this.eventHub.$emit("hideLayer", this.markerId);
      });
      this.eventHub.$on(`${this.type}PinDestroy`, (data) => {
        let proceed = true;

        if ("waypoint" == this.type && data.index != this.index) {
          proceed = false;
        } // end if

        if (proceed) this.eventHub.$emit("removeLayer", this.markerId);
      });
    },
    onclick() {
      let data = { index: this.index };
      const event = `${this.type}PickerShow`;
      this.eventHub.$emit(event, data);
    },
    dragend(event) {
      const coords = {
        lat: event.target._latlng.lat,
        lng: event.target._latlng.lng,
      };
      this.coords = coords;

      let data = coords;

      if ("waypoint" == this.type) {
        data = {
          ll: coords,
          index: this.index,
        };
      } // end if

      this.eventHub.$emit(`${this.type}PinDragend`, data);
      this.eventHub.$emit("suggestUpdate");
    },
  },
};
</script>

<style lang="scss">
.leaflet-marker-pane {
  &:hover {
    img.pointer-events-enable {
      filter: brightness(115%);
    }
  }
}
</style>