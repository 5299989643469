<script>

// @vuese
// Units and currency printing helper
// @group Mixins
export default {
  name: "Converter",
  methods: {
    printDurationFromSeconds(seconds) {
      const mins = seconds / 60;
      if (mins < 60) {
        return `${mins.toFixed(0)} min`;
      } else {
        return `${Math.floor(mins/60).toFixed(0)} hr ${(mins%60).toFixed(0)} min`;
      } 
    },
    printLengthFromMeters(meters, lvl = 2) {
      return this.printLengthFromKilometers(meters / 1000, lvl);
    },
    printLengthFromKilometers(kilometers, lvl = 2) {
      const units = this.units();
      const meters = kilometers * 1000;
      const target = units[`lvl${lvl}`];
      let result = undefined;
      
      if (target.default) {
        result = meters;
      } else if (target.formula) {
        if (target.formula.multiply) {
          result = meters * target.formula.multiply;
        } else if (target.formula.divide) {
          result = meters / target.formula.divide;
        } else {
          throw new Error('Formula set but undefined');  
        } 
      } else {
        throw new Error('Unknown target unit system');
      } 

      return `${result.toFixed(target.precision)} ${target.unit}`;
    },
    units() {
      const units = {
        metric: {
          lvl1: {
            unit: "m",
            precision: 0,
            default: true // <--- base
          },
          lvl2: {
            unit: "km",
            precision: 1,
            formula: {
              divide: 1000
            }
          }
        },
        imperial: {
          lvl1: {
            unit: "ft",
            precision: 0,
            formula: {
              divide: 1.609
            }
          },
          lvl2: {
            unit: "mi",
            precision: 2,
            formula: {
              divide: 1609
            }
          }
        }
      };

      return units[this.$store.getters.userSettings.units];
    },
    smartUnitLvlFromMeters(meters) {
      return meters > 1000 ? 2 : 1;
    },
    printPrice(pc) {
      return `${pc.toFixed(0)} Kč`;
    }
  }
};
</script>