<template>
  <div class="search">
    <div data-type="classic">
      <autocomplete ref="search" :search="search" @submit="pick" :autoSelect="true"
        :placeholder="$t('components.Search.autocomplete.placeholder')"
        :aria-label="$t('components.Search.autocomplete.placeholder')" :get-result-value="prettify" :debounceTime="500">
      </autocomplete>
    </div>
    <div data-type="municipalities" v-if="municipalitiesEnabled && municipalitiesAvailable">
      <autocomplete ref="searchMunicipalities" :search="searchMunicipalities" @submit="pickMunicipalities"
        :autoSelect="false" :placeholder="$t('components.Search.municipalitiesAutocomplete.placeholder')
          " :aria-label="$t('components.Search.municipalitiesAutocomplete.placeholder')
    " :get-result-value="prettifyMunicipalities" :debounceTime="500" :default-value="prefillMunicipality()">
        <template #result="{ result, props }">
          <li v-bind="props">
            <div>
              <img class="autocomplete-item-icon" :src="'img/icons/' + detectMunicipalityType(result)" alt="" />
              <span>{{ prettifyMunicipalities(result) }}</span>
            </div>
          </li>
        </template>
      </autocomplete>
    </div>
  </div>
</template>

<script>
import Ui from "../mixins/Ui.vue";
import Geocoder from "../mixins/Geocoder.vue";
import Autocomplete from "@trevoreyre/autocomplete-vue";

// Generic pluggable geocoding autocomplete combobox; used for A+B
// @group Map
export default {
  name: "Search",
  data() {
    return {
      selected: {
        query: "",
        item: null,
      },
      municipalitiesEnabled: true,
    };
  },
  props: ["signals", "callback", "fly", "type"],
  computed: {
    municipalitiesAvailable() {
      return this.$store.getters.municipalitiesAvailable;
    },
  },
  components: {
    Autocomplete,
  },
  mixins: [Ui, Geocoder],
  mounted() { },
  methods: {
    search(query) {
      if (query.length < 3) {
        return [];
      } else {
        return this.autocomplete(query, this.$store.getters.mapCenter);
      }
    },
    prettify(item) {
      return this.prettyGeocodedLocation(item);
    },
    pick(item) {
      let ll = {
        lng: item.geometry.coordinates[0],
        lat: item.geometry.coordinates[1],
      };

      this.selected.item = item;

      if (this.fly) {
        this.eventHub.$emit("flyTo", ll);
      } // end if

      if (this.signals && this.signals.length) {
        for (let signal of this.signals) {
          this.eventHub.$emit(signal, {
            ll: ll,
            mode: "point",
          });
        } // end for
      } // end if
      if (this.callback) {
        this.callback(ll);
      } // end if
    },
    searchMunicipalities(query) {
      const queryLower = query.toLowerCase();

      if (query.length < 3) {
        return [];
      } else {
        let results = [];

        results = results.concat(
          this.$store.getters.municipalities.municipalities.filter(
            (municipality) => {
              var found = 0;
              ['municipality', 'name'].forEach(key => {
                if (municipality.properties[key].toLowerCase().startsWith(queryLower)) {
                  found++;
                } // end if
              });
              return found > 0;
            }
          )
        );

        results = results.concat(
          this.$store.getters.municipalities.stops.filter((stop) => {
            return stop.name.toLowerCase().startsWith(queryLower);
          })
        );

        return results;
      }
    },
    prettifyMunicipalities(item) {
      return this.prettyGeocodedMunicipality(item);
    },
    detectMunicipalityType(item) {
      if (item.idosCategory) {
        return "stop.svg";
      } else if (item.properties.icon == "municipality") {
        return "city.svg";
      } else if (item.properties.icon == "center") {
        return "center.svg";
      } else {
        return "unknown.svg";
      } // end if-elseif-else
    },
    pickMunicipalities(item) {

      let mode = "point";
      let ll;
      const stopIds = [];

      if (item.stops) { // stops.json entry
        mode = "stopIds";
        item.stops.forEach(stop => {
          stop.gtfsIds.forEach(gtfsId => {
            stopIds.push(gtfsId);
          });
        });
        ll = {
          lng: item.avgLon,
          lat: item.avgLat,
        };
      } else if (item.properties && item.properties.gtfsIds) { // municipalities.json entry with gtfsIds
        mode = "stopIds";
        item.properties.gtfsIds.forEach(gtfsId => {
          stopIds.push(gtfsId);
        });
        ll = {
          lng: item.geometry.coordinates[0],
          lat: item.geometry.coordinates[1],
        };
      } else { // municipalities.json entry without gtfsIds
        ll = {
          lng: item.geometry.coordinates[0],
          lat: item.geometry.coordinates[1],
        };
      } // end if

      this.selected.item = item;

      if (this.fly) {
        this.eventHub.$emit("flyTo", ll);
      } // end if

      const payload = {
        ll: ll,
        mode: mode,
        stopIds: stopIds,
        name: this.prettifyMunicipalities(item)
      };

      if (this.signals && this.signals.length) {
        for (let signal of this.signals) {
          this.eventHub.$emit(signal, payload);
        } // end for
      } // end if
      if (this.callback) {
        this.callback(payload);
      } // end if

      this.$parent.autocompleteStopIdsOverride = true;
    },
    prefillMunicipality(par) {
      const self = this;

      if (this.$store.getters[this.type]?.mode == "stopIds") {
        if (this.$store.getters[this.type].stopIds && this.$store.getters[this.type].stopIds.length) {
          const name = this.$store.getters[this.type].name;

          if ( ! name.match(/^\d+/)) {
            self.$parent.autocompleteStopIdsOverride = true;

            return name;
          } // end if
        } // end if
      } // end if
    },
    hasMunicipalityValue() {
      if (this.municipalitiesEnabled) {
        return this.$refs.searchMunicipalities && this.$refs.searchMunicipalities.value.length > 0;
      } // end if
      return null;
    },
    clear() {
      if (this.$refs.searchMunicipalities) {
        this.$refs.searchMunicipalities.value = "";  
      } // end if
      if (this.$refs.search) {
        this.$refs.search.value = "";  
      } // end if
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
</style>
<style>
@import "~@trevoreyre/autocomplete-vue/dist/style.css";
</style>

<style lang="scss">
.autocomplete {
  margin-bottom: 25px;

  .autocomplete-input {
    background-color: white;
    background-position: 4px;
    padding: 4px 12px 4px 10px;
    background-image: none;

    &:focus {
      box-shadow: none;
    }
  }

  .autocomplete-input,
  .autocomplete-result-list {
    border-radius: 3px;
  }

  .autocomplete-result-list {
    overflow-y: scroll;
    max-height: 250px;
  }

  .autocomplete-result {
    background-image: none;
    padding: 12px 12px;
    background-color: white;
    cursor: pointer;
    line-height: 1.3;
    font-size: 1rem;
    font-weight: 300;

    &:hover {
      background-color: #349a511a;
    }
  }

  .autocomplete-item-icon {
    opacity: .5;
    width: 20px;
    height: auto;
    object-fit: cover;
    position: relative;
    top: 4px;
    margin-right: 5px;
  }
}

</style>

<style scoped lang="scss">
.search {
  position: relative;
}
</style>

