<template>
  <div class="deletestate">
    <div class="btn" v-on:click="deleteState()" v-tooltip="'Restore default state & reload'">
      <font-awesome-icon icon="trash" class="active" />
      <font-awesome-icon icon="trash" class="inactive" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteState",
  data() {
    return {

    };
  },
  methods: {
    deleteState() {
      let key = `ar-state-${this.appConfig.version}`;
      try {
        window.localStorage.removeItem(key);
        window.location.reload();
      } catch (error) {
        alert("An error has occured.");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.deletestate {
  position: absolute;
  width: 50px;
  height: 52px;
  border-radius: 24px;
  bottom: 20px;
  right: 55px;

  .btn {
    width: 40px;
    height: 40px;
    box-shadow: rgb(69 91 99 / 10%) 0px 6px 12px;
    border-radius: 50% !important;
    background-color: #f8f8f8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #efefef;
    margin: 10px 0 0 4px;

    .hover {
      display: none;
    }
    &.active {
      background-color: white;

      .inactive {
        display: none;
      }

      [data-icon] {
        color: #277a3e;
      }
    }
    &:not(.active) {
      .active {
        display: none;
      }
    }

    &:first-of-type {
      margin-top: 5px;
    }

    [data-icon] {
      color: #b3c5c2;
      font-size: 20px;
    }

    &:hover {
      [data-icon],
      img {
        filter: brightness(90%);
      }
    }
  }
}
</style>
