<template>
  <div class="plan-picker" v-if="plans.length">
    <p
      v-html="
        plans.length
          ? $t('components.PlanPicker.message.success', { cnt: plans.length })
          : $t('components.PlanPicker.message.nothing')
      "
    ></p>

    <div class="plans-wrapper">
      <div
        v-for="plan in plans"
        :key="plan.id"
        class="plan pointer"
        :class="{
          active: selected == plan.id,
          'bike-friendly': plan._.type == 'bikeFriendly',
          fast: plan._.type == 'fast',
        }"
        v-on:click="pick(plan)"
      >
        <span class="name">
          <span class="info">
            <span
              class="time"
              v-html="formatDt(plan.start.dateTime, plan.end.dateTime)"
            >
            </span>
            <span class="pull-right">
              <span class="distance">{{
                printLengthFromMeters(plan.stats.distanceMeters)
              }}</span
              >,
              <span class="duration">{{
                printDurationFromSeconds(plan.stats.durationSeconds)
              }}</span>
              <template
                v-if="
                  undefined !== plan.stats.priceAndPayment.priceInCzk &&
                  plan.stats.priceAndPayment.priceInCzk > 0.0
                "
                >,
                <span class="price">{{
                  printPrice(plan.stats.priceAndPayment.priceInCzk)
                }}</span>
              </template>
            </span>
          </span>
          <span>
            <span
              class="icon-wrapper"
              v-for="segment in plan.segments"
              :key="segment.id"
              :style="
                'background-color: ' +
                getIconStyle(segment.transportMode, segment.segmentType, segment.transferType)
              "
              :data-icon-type="getIconType(segment)"
            >
              <font-awesome-icon
                v-if="'DROPOFF' == segment.segmentType"
                :title="'Drop-off'"
                :icon="'parking'"
              ></font-awesome-icon>
              <font-awesome-icon
                v-else-if="'PICKUP' == segment.segmentType && segment.transferType"
                :title="'Transfer'"
                :icon="'angle-right'"
              ></font-awesome-icon>
              <font-awesome-icon
                v-else-if="'PICKUP' == segment.segmentType"
                :title="'Pick-up'"
                :icon="'angle-up'"
              ></font-awesome-icon>
              <img
                v-else-if="'vector' == getIconType(segment)"
                :title="segment.transportMode"
                :alt="segment.transportMode"
                :src="getIcon(segment).src"
                width="15"
                height="15"
              />
              <font-awesome-icon
                v-else
                :title="segment.transportMode"
                :icon="getIcon(segment)"
              ></font-awesome-icon>
            </span>
          </span>
          <div class="plan-id">
            {{ plan.id }}
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Converter from "../mixins/Converter.vue";
import PT from "../mixins/PT.vue";

// Handles switching between different fetched plans
// @group Map
export default {
  name: "PlanPicker",
  mixins: [Converter, PT],
  mounted() {
    this.eventHub.$on("pickPlan", (plan) => {
      this.pick(plan);
    });
    this.eventHub.$on("erasePlans", () => {
      this.$store.commit("plans", []);
    });
  },
  data() {
    return {};
  },
  computed: {
    plans() {
      return this.$store.getters.plans;
    },
    selected() {
      return this.$store.getters.activePlan;
    },
    icons() {
      return this.appConfig.map.plan.icons;
    },
    ptIcons() {
      return this.appConfig.map.plan.ptIcons;
    },
  },
  methods: {
    pick(plan) {
      this.$store.commit("activePlan", plan.id);
      this.eventHub.$emit("togglePlan", plan.id);
    },
    getIconStyle(mode, type, transferType) {
      if ("DROPOFF" == type) {
        mode = "parking";
      } // end if

      if ("PICKUP" == type && transferType) {
        mode = "transfer";
      } else if ("PICKUP" == type) {
        mode = "pickup";
      } // end if

      const style = this.appConfig.map.plan;
      if (style.color[mode.toLowerCase()] !== undefined) {
        return style.color[mode.toLowerCase()];
      } else {
        return style.color.generic;
      }
    },
    formatDt(start, end) {
      const s = new Date(start);
      const e = new Date(end);
      let o = "";
      o += ("0" + s.getHours()).slice(-2);
      o += ":";
      o += ("0" + s.getMinutes()).slice(-2);
      o += " - ";
      o += ("0" + e.getHours()).slice(-2);
      o += ":";
      o += ("0" + e.getMinutes()).slice(-2);
      return o;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";

.plan-picker {
  p {
    padding: 0 20px 20px 20px;
    text-align: left;
    line-height: 1.6;
    font-size: 1rem;
    font-weight: 300;
  }

  hr {
    border: 0;
    border-bottom: 1px solid #d2d2d2;
    width: 150px;
    margin: 25px auto;
  }

  .plan {
    padding: 10px 10px 10px 15px;
    font-weight: 300;
    border-left: 3px solid transparent;

    &:hover {
      background-color: #f3fff6;
    }
    &.active {
      // border-left-color: #73bd85;
      background-color: #f3fff6;
      color: #2c3e50;
      font-weight: 500;
      border-left-color: #349a51;
    }

    .icon-wrapper {
      background-color: #349a51;
      padding: 4px;
      border-radius: 2px;
      margin: 0 9px 1px 0;
      display: inline-block;
      &[data-icon-type="vector"] {
        padding: 4px 4px 2px 4px;
        position: relative;
        top: 2px;
        margin-top: -2px;
      }

      [data-icon] {
        color: white;
        width: 15px;
        height: 15px;
      }
      // &.bike-friendly [data-icon] {
      //   color: #74c476;
      // }
      // &.fast [data-icon] {
      //   color: #ff3333;
      // }
    }

    .info {
      // opacity: .5;
      color: gray;
      font-weight: 300;
      display: block;
      margin-bottom: 5px;

      .duration {
        width: 50px;
      }
      .price {
        font-weight: bold;
      }
    }
    .plan-id {
      font-family: monospace;
      font-size: 12px;
      color: gray;
    }
  }
}
</style>
