<template>
  <div v-if="false" />
</template>

<script>
export default {
  name: "Analytics",
  data() {
    return {
      ga: this.appConfig.analytics.ga,
    };
  },
  mounted() {
    const head = document.head;

    const analytics = document.createElement("script");
    analytics.src = `https://www.googletagmanager.com/gtag/js?id=${this.ga}`;
    analytics.type = "text/plain";
    analytics.dataset.cookiecategory = "analytics";
    head.appendChild(analytics);

  },
  methods: {},
};
</script>

