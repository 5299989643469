<template>
  <div v-if="plan" class="plan-detail no-scrollbar">
    <div class="plan-header">
      <span class="name">
        <span class="info">
          <span
            class="time"
            v-html="formatDt(plan.start.dateTime, plan.end.dateTime)"
          >
          </span>
          <span class="pull-right">
            <span class="distance">{{
              printLengthFromMeters(plan.stats.distanceMeters)
            }}</span
            >,
            <span class="duration">{{
              printDurationFromSeconds(plan.stats.durationSeconds)
            }}</span>
            <template
              v-if="
                undefined !== plan.stats.priceAndPayment.priceInCzk &&
                plan.stats.priceAndPayment.priceInCzk > 0.0
              "
              >,
              <span class="price">{{
                printPrice(plan.stats.priceAndPayment.priceInCzk)
              }}</span>
            </template>
          </span>
        </span>
        <span>
          <span
            class="icon-wrapper"
            v-for="segment in plan.segments"
            :key="segment.id"
            :style="
              'background-color: ' +
              getIconStyle(segment.transportMode, segment.segmentType, segment.transferType)
            "
            :data-icon-type="getIconType(segment)"
          >
            <font-awesome-icon
              v-if="'DROPOFF' == segment.segmentType"
              :title="'Drop-off'"
              :icon="'parking'"
            ></font-awesome-icon>
            <font-awesome-icon
              v-else-if="'PICKUP' == segment.segmentType && segment.transferType"
              :title="'Transfer'"
              :icon="'caret-right'"
            ></font-awesome-icon>
            <font-awesome-icon
              v-else-if="'PICKUP' == segment.segmentType"
              :title="'Pick-up'"
              :icon="'caret-up'"
            ></font-awesome-icon>
            <img
              v-else-if="'vector' == getIconType(segment)"
              :title="segment.transportMode"
              :alt="segment.transportMode"
              :src="getIcon(segment).src"
              width="15"
              height="15"
            />
            <font-awesome-icon
              v-else
              :title="segment.transportMode"
              :icon="getIcon(segment)"
            ></font-awesome-icon>
          </span>
        </span>
        <pre>{{ plan.id }}</pre>
        <a
          class="btn-update"
          :class="{ updating: updating }"
          v-on:click="clickUpdate(plan)"
        >
          {{ $t("components.PlanDetail.update") }}
          <font-awesome-icon :icon="'circle-notch'" spin></font-awesome-icon>
        </a>
        <br />
        <a
          class="btn-execute"
          :class="{ executing: executing }"
          v-on:click="clickExecute(plan)"
        >
          {{ $t("components.PlanDetail.execute") }}
          <font-awesome-icon :icon="'circle-notch'" spin></font-awesome-icon>
        </a>
      </span>
    </div>
    <div class="plan-body">
      <ul>
        <li
          v-for="(segment, i) in plan.segments"
          v-bind:key="i"
          class="segment"
          :class="{ switched: segment._switched }"
        >
          <span
            class="icon-wrapper"
            :data-type="segment.segmentType"
            :style="
              'background-color: ' +
              getIconStyle(segment.transportMode, segment.segmentType, segment.transferType)
            "
          >
            <font-awesome-icon
              v-if="'DROPOFF' == segment.segmentType"
              :title="'Drop-off'"
              :icon="'parking'"
            ></font-awesome-icon>
            <font-awesome-icon
              v-else-if="'PICKUP' == segment.segmentType && segment.transferType"
              :title="'Transfer'"
              :icon="'caret-right'"
            ></font-awesome-icon>
            <font-awesome-icon
              v-else-if="'PICKUP' == segment.segmentType"
              :title="'Pick-up'"
              :icon="'caret-up'"
            ></font-awesome-icon>
            <img
              v-else-if="'vector' == getIconType(segment)"
              :title="segment.transportMode"
              :alt="segment.transportMode"
              :src="getIcon(segment).src"
              width="15"
              height="15"
            />
            <font-awesome-icon
              v-else
              :title="segment.transportMode"
              :icon="getIcon(segment)"
            ></font-awesome-icon>
          </span>
          <div v-if="'PT' == segment.transportMode" class="journey-name">
            {{ segment.rideDetails.ptDetails.route.ptVehicle }}
            {{ segment.rideDetails.ptDetails.route.routeShortName }} ({{
              segment.rideDetails.ptDetails.route.routeId
            }})
          </div>
          <div
            v-else-if="'DROPOFF' == segment.segmentType"
            class="journey-name"
          >
            Drop-off
          </div>
          <div v-else-if="'PICKUP' == segment.segmentType && segment.transferType" class="journey-name">
            Transfer
          </div>
          <div v-else-if="'PICKUP' == segment.segmentType" class="journey-name">
            Pick-up
          </div>
          <div v-else class="journey-name">
            {{
              segment.transportMode[0] +
              segment.transportMode.toLowerCase().substring(1)
            }}
          </div>
          <span class="btn-info pointer" v-on:click="showSegment(segment)">
            <font-awesome-icon title="Info" icon="info-circle" />
          </span>
          <div v-if="'DROPOFF' == segment.segmentType" class="small">
            <template
              v-if="
                segment.dropOffDetails.carParkingDetails &&
                segment.dropOffDetails.carParkingDetails.carParking !== null
              "
            >
              {{ segment.dropOffDetails.carParkingDetails.carParking.name }}

              <template
                v-if="
                  segment.dropOffDetails.carParkingDetails.carParkingMeasurement
                "
              >
                ({{
                  segment.dropOffDetails.carParkingDetails.carParkingMeasurement
                    .availableSpotCount
                }}/{{
                  segment.dropOffDetails.carParkingDetails.carParkingMeasurement
                    .totalSpotCount
                }}
                available)
              </template>

              <template
                v-if="segment.dropOffDetails.carParkingDetails.carParking.name"
              >
                -
              </template>

              {{
                segment.dropOffDetails.carParkingDetails.carParking
                  .addressFormatted
              }}
            </template>
            <template
              v-else-if="
                segment.bicycleParkingDetails &&
                segment.bicycleParkingDetails !== null
              "
            >
              {{
                segment.dropOffDetails.bicycleParkingDetails.bicycleParking.name
              }}

              <template
                v-if="
                  segment.dropOffDetails.bicycleParkingDetails.bicycleParking
                    .name
                "
              >
                -
              </template>

              {{
                segment.dropOffDetails.bicycleParkingDetails.bicycleParking
                  .addressFormatted
              }}
            </template>
          </div>
          <div v-else-if="'PICKUP' == segment.segmentType" class="small">
            <template
              v-if="
                segment.pickupDetails &&
                segment.pickupDetails.sharedVehicleDetails &&
                'string' ==
                  typeof segment.pickupDetails.sharedVehicleDetails.vehicle
                    .vehicleId
              "
            >
              Vehicle
              <code>{{
                segment.pickupDetails.sharedVehicleDetails.vehicle.vehicleId
              }}</code>
            </template>
          </div>
          <div v-else class="small">
            {{
              printLengthFromMeters(
                segment.stats.distanceMeters,
                smartUnitLvlFromMeters(segment.stats.distanceMeters)
              )
            }},
            {{ printDurationFromSeconds(segment.stats.durationSeconds) }}
          </div>
          <div v-if="'PT' == segment.transportMode && (appConfig.app.features.include('prevSegment') || appConfig.app.features.include('nextSegment'))">
            <a
              v-if="appConfig.app.features.include('prevSegment')"
              v-on:click="
                previous({
                  current: segment,
                  previous: plan.segments[i - 1],
                  next: plan.segments[i + 1],
                })
              "
              class="prev btn-prevnext"
              ><font-awesome-icon
                icon="angle-left"
                title="Previous connection"
              ></font-awesome-icon>
              Prev</a
            >
            <a
              v-if="appConfig.app.features.include('nextSegment')"
              v-on:click="
                next({
                  current: segment,
                  previous: plan.segments[i - 1],
                  next: plan.segments[i + 1],
                })
              "
              class="next btn-prevnext pull-right"
              >Next
              <font-awesome-icon
                icon="angle-right"
                title="Next connection"
              ></font-awesome-icon
            ></a>
            <PtStops
              :stops="
                segment.rideDetails.ptDetails.stopTimes.slice(
                  segment.rideDetails.ptDetails.getOnStopIndex,
                  segment.rideDetails.ptDetails.getOffStopIndex + 1
                )
              "
              :startIndex="segment.rideDetails.ptDetails.getOnStopIndex + 1"
            />
          </div>
        </li>
        <li class="">
          <span class="icon-wrapper" :style="'background-color: #349a51'">
            <font-awesome-icon :icon="'flag-checkered'"></font-awesome-icon>
          </span>
        </li>
      </ul>
    </div>
    <GenericDialog ref="dialog" :title="'Plan ' + plan.id" :body="dialogBody" />
  </div>
</template>

<script>
import Converter from "./mixins/Converter.vue";
import PT from "./mixins/PT.vue";
import PtStops from "./PtStops.vue";
import GenericDialog from "./dialogs/GenericDialog";

// Paints and handles selected plan (trip) detail
// @group App
export default {
  name: "PlanDetail",
  mixins: [Converter, PT],
  components: { PtStops, GenericDialog },
  computed: {
    icons() {
      return this.appConfig.map.plan.icons;
    },
    ptIcons() {
      return this.appConfig.map.plan.ptIcons;
    },
  },
  data() {
    return {
      plan: null,
      updating: false,
      executing: false,
      dialogBody: "",
    };
  },
  mounted() {
    this.eventHub.$on("planDetail", (planId) => {
      this.$store.getters.plans.forEach((plan) => {
        if (planId == plan.id) {
          this.plan = plan;
          return false;
        }
      });
    });

    this.eventHub.$on("switchSegments", (data) => {
      this.switchSegment(data);
    });
    this.eventHub.$on("erasePlans", () => {
      this.plan = null;
    });
    this.eventHub.$on("planUpdated", (data) => {
      this.updatePlan(data);
    });
    this.eventHub.$on("planExecuted", (_response) => {
      this.executing = false;
      this.dialogBody = "200: OK";
      this.$refs.dialog.show();
    });
  },
  methods: {
    // @vuese
    // Stringifies two Date objects into readable interval string
    // @arg `Date` {start} start dt
    // @arg `Date` {end} end dt
    formatDt(start, end) {
      const s = new Date(start);
      const e = new Date(end);
      let o = "";
      o += ("0" + s.getHours()).slice(-2);
      o += ":";
      o += ("0" + s.getMinutes()).slice(-2);
      o += " - ";
      o += ("0" + e.getHours()).slice(-2);
      o += ":";
      o += ("0" + e.getMinutes()).slice(-2);
      return o;
    },
    showSegment(segment) {
      // @vuese
      // Assigns clicked segment to the segment dialog component
      // @arg {Object} segment
      this.eventHub.$emit("segmentDialogUpdate", segment);
      // @vuese
      // Shows the segment detail dialog window (currently json-viewer)
      // @arg `void`
      this.eventHub.$emit("segmentDialogShow");
    },
    previous(data) {
      // @vuese
      // Initialize previous segment download & update
      // @arg `Object` data
      this.eventHub.$emit("fetchPrevious", data);
    },
    next(data) {
      // @vuese
      // Initialize next segment download & update
      // @arg `Object` data
      this.eventHub.$emit("fetchNext", data);
    },
    switchSegment(data) {

      if (0 == data.result.data.routeSegments.length) {
        alert("No connection found.");
        return;
      }

      const newSegments = [];
      const replacements = [];

      for (let i = 0; i < this.plan.segments.length; i++) {
        const planSegment = this.plan.segments[i];

        if (planSegment === data.segment) {
          var index = 0;

          data.result.data.routeSegments[0].forEach((candidateSegment) => {
            if ("PT" == candidateSegment.transportMode) {
              replacements[i] = candidateSegment;
            } // end if

            ++index;
          });

          newSegments.push(planSegment);
        } else {
          newSegments.push(planSegment);
        } // end if-else
      } // end for
      const finalizedSegments = [];

      for (let k = 0; k < newSegments.length; k++) {
        const segment = newSegments[k];

        if (replacements[k] !== undefined) {
          finalizedSegments[k] = replacements[k];
          finalizedSegments[k]._switched = true;
        } else {
          finalizedSegments[k] = segment;
          finalizedSegments[k]._switched = false;
        }
      }

      this.plan.segments = finalizedSegments;

      let currentPlans = this.$store.getters.plans;
      for (let ind = 0; ind < currentPlans.length; ind++) {
        const onePlan = currentPlans[ind];
        if (onePlan.id === this.plan.id) {
          currentPlans[ind] = this.plan;
        }
      }
      this.$store.commit("plans", currentPlans);
      this.$store.commit("activePlan", this.plan.id);
      // @vuese
      // Repaint the whole UI with new data
      // @arg `void`
      this.eventHub.$emit("repaint");
    },
    clickUpdate(plan) {
      this.updating = true;
      // @vuese
      // Emits event that triggers fetching and updating selected plan
      // @arg `String` Plan ID
      this.eventHub.$emit("updatePlan", plan);
    },
    clickExecute(plan) {
      this.executing = true;
      // @vuese
      // Emits event that triggers calling the execute endpoint on BE
      // @arg `String` Plan ID
      this.eventHub.$emit("executePlan", plan);
    },
    updatePlan(data) {
      const plans = data.plans;
      const planId = data.planId;
      this.updating = false;
      this.dialogBody = "";

      if (plans.length) {
        const newPlan = plans[0];

        this.dialogBody = "Is feasible: " + (newPlan.isFeasible ? "YES" : "NO");

        let currentPlans = this.$store.getters.plans;

        for (let index = 0; index < currentPlans.length; index++) {
          const onePlan = currentPlans[index];
          if (onePlan.id === planId) {
            currentPlans[index] = newPlan;
          }
        }

        this.$store.commit("plans", currentPlans);
        this.$store.commit("activePlan", planId);
        this.eventHub.$emit("repaint");

        this.$refs.dialog.show();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";

.plan-detail {
  color: #2c3e50;
  z-index: 20;
  padding: 30px 20px 0px 20px;
  position: fixed;
  top: 20px;
  left: 400px;
  border-radius: 6px;
  max-height: calc(100vh - 100px);
  background-color: white;
  width: 330px;
  overflow: scroll;

  p {
    padding: 0 0px 10px 0px;
    text-align: left;
    line-height: 1.6;
    font-size: 1rem;
    font-weight: 300;
  }

  ul,
  ol {
    margin: 20px 10px;
    li {
      padding: 5px 0 5px 0;
      margin: 0 0 0 10px;
      list-style: disc;
      line-height: 1.6;
      font-size: 0.9rem;
      font-weight: 300;
      position: relative;

      &.segment::before {
        content: " ";
        position: absolute;
        width: 2px;
        background-color: black;
        top: 15px;
        bottom: -15px;
        left: -20px;
      }
      &.segment.switched::before {
        background-color: red;
      }
    }
  }

  ol.stops {
    margin: 10px 0;
    li {
      list-style: auto;
      font-size: 11px;
      padding: 0;
    }
  }

  .icon-wrapper {
    background-color: #349a51;
    padding: 4px;
    border-radius: 2px;
    margin: 0 9px 1px 0;
    display: inline-block;
    &[data-icon-type="vector"] {
      padding: 4px 4px 2px 4px;
      position: relative;
      top: 2px;
      margin-top: -2px;
    }

    [data-icon] {
      color: white;
      width: 15px;
      height: 15px;
    }
    &[data-type="DROPOFF"] {
      border-radius: 3px !important;
    }
  }

  .plan-header {
    margin: -30px -20px 10px -20px;
    padding: 30px 20px 25px 20px;
    background-color: #f2fff6;
    border-bottom: 1px solid #d2d2d2;

    .btn-update,
    .btn-execute {
      font-size: 10px;
      float: right;
      text-decoration: none;
      [data-icon] {
        visibility: hidden;
      }

      &.updating,
      &.executing {
        pointer-events: none;

        [data-icon] {
          visibility: visible;
        }
      }
    }
  }

  .plan-body {
    margin-left: 11px;

    .icon-wrapper {
      position: absolute;
      display: inline-block;
      height: 15px;
      width: 15px;
      /* padding: 0; */
      padding: 4px;
      line-height: 0;
      left: -30px;
      border-radius: 50%;
    }
  }

  .info {
    // opacity: .5;
    color: gray;
    font-weight: 300;
    display: block;
    margin-bottom: 5px;

    .duration {
      width: 50px;
    }
    .price {
      font-weight: bold;
    }
  }

  pre {
    font-family: monospace;
    font-size: 12px;
    color: gray;
  }

  .journey-name {
    font-weight: 400;
    padding-right: 20px;
  }
  .btn-info {
    position: absolute;
    right: 0;
    top: 5px;

    [data-icon] {
      background-color: white;
      color: #0049b0;
      border-radius: 50%;
      width: 20px;
      height: 20px;
    }

    &:hover {
      filter: brightness(120%);
    }
  }
  .btn-prevnext {
    text-decoration: none;
    font-size: 12px;
  }
}
</style>

