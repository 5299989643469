<script>
// @vuese
// System mixin
// @group Mixins
export default {
  name: "System",
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    }
  },
};
</script>