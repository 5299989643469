import { render, staticRenderFns } from "./Vehicles.vue?vue&type=template&id=5f54f039&scoped=true&"
import script from "./Vehicles.vue?vue&type=script&lang=js&"
export * from "./Vehicles.vue?vue&type=script&lang=js&"
import style0 from "./Vehicles.vue?vue&type=style&index=0&id=5f54f039&scoped=true&lang=scss&"
import style1 from "./Vehicles.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f54f039",
  null
  
)

export default component.exports