const PostRequest = {
    url: "",
    method: "post",
    timeout: 5000,
    headers: {
        "content-type": "application/json; charset=utf-8",
    },
    data: {},
};

export default PostRequest;
