<template>
  <modal
    name="originPickerDialog"
    ref="originPickerDialog"
    class="vue-dialog"
    width="400px"
    height="auto"
    :shiftX="0.5"
    :shiftY="0.25"
    id="originPickerDialog"
    @before-close="close"
    @opened="onAfterShow()"
  >
    <div class="vue-dialog-content">

      <div class="vue-dialog-content-title">
        {{ $t("components.OriginPicker.dialog.title") }}
      </div>
      <div class="line-15px"></div>
      <div class="opts">
        <Search ref="search" v-if="$store.getters.dynamicApi.geocoding.classic" :signals="['setOrigin', 'originPickerHide']" :fly="true" :type="'origin'" />
        <ul>
          <li
            v-for="option in options"
            :key="option"
            v-on:click="pick(option)"
            :class="{ active: isActive(mode, option) }"
            class="pointer"
          >
            <font-awesome-icon icon="check"></font-awesome-icon>
            <div class="bold">
              {{ $t(`components.OriginPicker.option.${option}.title`) }}
            </div>
            <div class="line-10px"></div>
            <div v-if="'latLon' == option">
              <input
                type="text"
                v-on:change="validateLatLng()"
                v-on:keyup="validateLatLng()"
                ref="latLngInput"
                :value="dynamicLatLngInputValue()"
              />
            </div>
            <div v-else-if="'stopIds' == option">
              <input
                type="text"
                ref="stopIds"
                v-model="stopIds"
              />
            </div>
            <div v-else class="light gray desc">
              {{ $t(`components.OriginPicker.option.${option}.description`) }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button v-on:click="hide()" type="button" class="vue-dialog-button">
        {{ $t("components.OriginPicker.buttons.cancel") }}
      </button>
      <button
        ref="saveBtn"
        v-on:click="
          save();
          hide();
        "
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.OriginPicker.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import L from "leaflet";
import Ui from "../mixins/Ui.vue";
import Geocoder from "../mixins/Geocoder.vue";
import Locator from "../mixins/Locator.vue";
import Search from "@/components/map/Search.vue";

// Modal dialog component for picking the mode of selecting the origin (A) location
// @group Dialogs
export default {
  name: "OriginPicker",
  data() {
    const origin = this.$store.getters.origin;
    return {
      options: ["point", "myLocation", "latLon", "stopIds"],
      mode: origin.mode,
      name: origin.name,
      point: origin.point,
      geocodingCache: null,
      watchId: null,
      stopIds: origin.stopIds,
      autocompleteStopIdsOverride: false,
    };
  },
  components: {Search},
  mixins: [Ui, Geocoder, Locator],
  mounted() {
    this.listen();
    this.commit();
  },
  methods: {
    listen() {
      this.eventHub.$on("originPickerShow", this.show);
      this.eventHub.$on("originPickerHide", this.hide);
      
      this.eventHub.$on("originPinDragend", (ll) => {
        this.dragend(ll);
      });
      this.eventHub.$on("setOrigin", (data) => {
        
        this.point = data.ll;
        this.mode = data.mode ? data.mode : this.mode;
        this.stopIds = data.stopIds ? ( 'string' == typeof data.stopIds ? data.stopIds : data.stopIds.join(",") ) : undefined;
        this.name = data.name ? data.name : (this.name ? this.name : this.$t(`components.Panel.vals.${this.mode}`));
        
        this.commit().finally(() => {
          // @vuese
          // Show the Pin
          // @arg `void`
          this.eventHub.$emit("originPinShow");
          // @vuese
          // Suggest fetching new plans because user has changed Pin position
          // @arg `void`
          this.eventHub.$emit("suggestUpdate");
        });
      });
    },
    // @vuese
    // Shows modal dialog
    // @arg `void`
    show() {
      this.mode = this.$store.getters.origin.mode;
      this.$modal.show("originPickerDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("originPickerDialog");
    },
    close() {
      this.hideInactive(this.mode);
    },
    // @vuese
    // Saves selected values to local store and suggest UI update
    // @arg `void`
    save() {
      if ("latLon" == this.mode) {
        const coords = this.parseManualCoordinates();
        // @vuese
        // Signal origin Pin location change
        // @arg `Object` LatLng
        this.eventHub.$emit("setOrigin", {ll: coords, mode: 'point'});
        return;
      } // end if

      this.commit();

      if ("visibleArea" == this.mode) {
        // @vuese
        // Set destination mode to "same"
        // @arg `String` "same"
        this.eventHub.$emit("destinationMode", "same");
        // @vuese
        // Hides destination Pin because user picked "visibleArea" as mode
        // @arg `void`
        this.eventHub.$emit("destinationPinHide");
      }
      // @vuese
      // Suggest fetching new plans because user has commited changes
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
    },
    // @vuese
    // Commits changes to local store and propagates appropriate events
    // @arg `Bool` {deep}
    commit(deep = true) {
      
      const opts = {
        mode: this.mode,
        point: this.point,
        name: this.name,
        stopIds: this.stopIds
      };

      if (deep) {
        this.eventHub.$emit("logMessage", {
          type: "info",
          message: "Geocoding origin",
        });

        return this.reverseGeocode(this.point)
          .then((res) => {
            this.name = this.prettyGeocodedLocation(res.data.features[0]);
          })
          .catch((_res) => {
            this.eventHub.$emit("logMessage", {
              type: "warning",
              message: "Origin geocoding failed or disabled",
            });
            if ("stopIds" == opts.mode) {
              this.name = opts.name ? opts.name : opts.stopIds;
            } else {
              const coords = this.point;
              this.name = `${coords.lat.toFixed(6)},${coords.lng.toFixed(6)}`;  
            } // end if-else
            
          })
          .finally((_res) => {
            opts.name = this.name;
            this.$store.commit("origin", opts);
            this.geocodingCache = opts;
          });
      } else {
        return new Promise((resolve, _reject) => {
          this.$store.commit("origin", opts);
          resolve();
        });
      }
    },
    // @vuese
    // Commits selected mode to local store
    // @arg `String` {option} picked value
    pick(option) {
      this.hideInactive(option);
      this.clearAutocomplete();

      switch (option) {
        case "myLocation":
          this.getUserLocation();
          break;

        case "point":
          this.mode = option;
          this.point = this.$store.getters.mapCenter;
          this.commit();
          // @vuese
          // Move and show Pin
          // @arg `void`
          this.eventHub.$emit("originPinShow");
          break;

        case "visibleArea":
          this.mode = option;
          this.commit();
          break;

        case "latLon":
          this.mode = option;

          break;
        
        case "stopIds":
          this.autocompleteStopIdsOverride = false;
          this.mode = option;

          break;
      }
    },
    // @vuese
    // Hides elements of inactive (not checked) modes
    // @arg `String` {option} active value
    hideInactive(option) {
      if ("point" != option && "latLon" != option) {
        // @vuese
        // Hide Pin
        // @arg `void`
        this.eventHub.$emit("originPinHide");
      }
      if ("myLocation" != option) {
        // @vuese
        // Hide Locator
        // @arg `void`
        this.eventHub.$emit("hideLocator");
      }
    },
    // @vuese
    // Save and commit new marker location on drag and
    // @arg `Object` {ll} position
    dragend(ll) {
      this.point = ll;
      this.mode = "point";
      this.commit();
    },
    // @vuese
    // Validates user-provided geolocation
    validateLatLng() {
      if (0 === this.$refs.latLngInput.length) {
        return null;
      }

      const el = this.$refs.latLngInput[0];
      const coords = this.parseManualCoordinates();

      if (coords) {
        el.classList.add("is-success");
        return true;
      } else {
        el.classList.add("is-error");
        return false;
      }
    },
    parseManualCoordinates() {
      if (0 === this.$refs.latLngInput.length) {
        return null;
      }

      const coords = this.$refs.latLngInput[0].value;

      if (!this.validateCoordinates(coords)) {
        return false;
      }

      const [lat, lng] = coords.replace(" ", "").split(",");

      return L.latLng(lat, lng);
    },
    dynamicLatLngInputValue() {
      if (this.point) {
        return this.point.lat.toFixed(6) + ", " + this.point.lng.toFixed(6);  
      } else if (this.stopIds) {
        return this.stopIds;
      } // end if-else
    },
    isActive(mode, option) {
      let active = mode == option;

      if ("stopIds" == mode && true === this.autocompleteStopIdsOverride) {
        active = false;
      } // end if

      return active;
    },
    onAfterShow() {
      this.autocompleteStopIdsOverride = this.$refs.search.hasMunicipalityValue();
    },
    clearAutocomplete() {
      this.$refs.search.clear();
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";
</style>

<style scoped lang="scss">
input {
  &[type="text"] {
    border: 1px solid #eee;
    border-radius: 4px;
    color: #7b7b7b;
    display: block;
    font-weight: normal;
    text-decoration: none;
    padding: 8px 12px;
    font-size: 15px;
    font-weight: bold;
    width: calc(100% - 30px);
  }
  &.is-success,
  &.is-error {
    color: white;
  }
}
</style>
