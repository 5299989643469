<template>
  <div ref="mapWrapper" class="map" data-far-away="yes">
    <div id="map"></div>
    <MapControl />
    <Pin
      v-if="initialized && origin.point"
      :type="'origin'"
      :ll="origin.point"
      :mode="origin.mode"
    />
    <Pin
      v-if="initialized && destination.point"
      :type="'destination'"
      :ll="destination.point"
      :mode="destination.mode"
    />
    <template v-for="(waypoint, i) in via">
      <Pin v-if="initialized && waypoint.point && waypoint.point.lat" v-bind:key="i" :type="'waypoint'" :mode="waypoint.mode" :ll="waypoint.point" :index="i" />
    </template>
    
    <Locator v-if="initialized && origin.point" :ll="origin.point" />
    <ContextMenu />
    <PlanMode />
    <Vehicles ref="vehicles" />
    <Stations ref="stations" />
    <Cmd />
    <Opts />
    <Alive />
    <DeleteState />
    <Osm />
  </div>
</template>

<script>
import L from "leaflet";
import "mapbox.js";
import MapControl from "@/components/map/MapControl.vue";
import PlanMode from "@/components/map/PlanMode.vue";
import Vehicles from "@/components/map/Vehicles.vue";
import Stations from "@/components/map/Stations.vue";
import Cmd from "@/components/map/Cmd.vue";
import Opts from "@/components/map/Opts.vue";
import Alive from "@/components/map/Alive.vue";
import DeleteState from "@/components/map/DeleteState.vue";
import Osm from "@/components/map/Osm.vue";
import Pin from "@/components/map/Pin.vue";
import Locator from "@/components/map/Locator.vue";
import ContextMenu from "@/components/map/ContextMenu.vue";
import Painter from "./map/mixins/Painter.vue";
import LayerManager from "./map/mixins/LayerManager.vue";

// Map layer. Encapsulates the native Mapbox.js map object; Responsible for layer management
// @group App
export default {
  name: "Map",
  components: {
    MapControl,
    PlanMode,
    Vehicles,
    Stations,
    Cmd,
    Opts,
    Alive,
    DeleteState,
    Osm,
    Pin,
    Locator,
    ContextMenu,
  },
  mixins: [Painter, LayerManager],
  computed: {
    center() {
      return this.$store.getters.mapCenter;
    },
    zoom() {
      return this.$store.getters.mapZoom;
    },
    origin() {
      return this.$store.getters.origin;
    },
    destination() {
      return this.$store.getters.destination;
    },
    via() {
      return this.$store.getters.via;
    },
  },
  data() {
    return {
      initialized: false,
      mapObject: null,
      layers: {},
      win: window,
      threshold: 14
    };
  },
  mounted() {
    this.initMap();
    this.on();
    this.listen();
  },
  methods: {
    // @vuese
    // Initializes map; called once
    // @arg `void`
    initMap() {
      if (this.appConfig.mapbox.token) {
        this.win.L.mapbox.accessToken = this.appConfig.mapbox.token;
      } // end if

      let tileJson = null;
      let styleLayer = null;

      if (this.appConfig.map.styleLayer) {
        styleLayer = this.win.L.mapbox.styleLayer(this.appConfig.map.styleLayer);
      } else {
        tileJson = this.appConfig.map.tileJson;
      } // end if-else

      this.mapObject = this.win.L.mapbox
        .map("map", tileJson, {
          zoomControl: false,
        })
        .on("load", () => {
          this.initialized = true;
        })
        .setView([this.center.lat, this.center.lng], this.zoom);
      
      this.mapObject.attributionControl.setPosition('bottomleft');

      if (this.appConfig.map.styleLayer) {
        this.mapObject.addLayer(styleLayer);
      } // end if

      this.$store.commit("mapCenter", this.mapObject.getCenter());
      this.$store.commit("mapBbox", this.mapObject.getBounds());
    },
    // @vuese
    // Decides on which coordinates should the planner application start
    // @arg `void`
    determineStartingCenter() {
      return this.center;
    },
    // @vuese
    // Registers listeners for all map event
    // @arg `void`
    on() {
      this.mapObject.on("moveend", () => {
        const oldCenter = this.$store.getters.mapCenter;
        this.$store.commit("mapCenter", this.mapObject.getCenter());
        this.$store.commit("mapBbox", this.mapObject.getBounds());

        // @vuese
        // Globally signals map moveend event for non-mapbox components
        // @arg `Object` distance moved in meters
        this.eventHub.$emit("moveend", {
          distance: L.latLng(oldCenter).distanceTo(
            L.latLng(this.mapObject.getCenter())
          ),
        });
      });
      this.mapObject.on("contextmenu", (data) => {
        // @vuese
        // Signals to open custom context menu
        // @arg `Object` event
        this.eventHub.$emit("contextMenu", data);
      });
      this.mapObject.on("click", (data) => {
        // @vuese
        // Signals map click to VUE components
        // @arg `Object` event
        this.eventHub.$emit("mapClick", data);
      });
      this.mapObject.on("zoom", (data) => {
        // @vuese
        // Signals map zoom event to VUE components
        // @arg `Object` event
        this.eventHub.$emit("mapZoom", data);
      });
    },
    // @vuese
    // Registers listeners for all application map-related events
    // @arg `void`
    listen() {
      this.eventHub.$on("flyTo", (coords) => {
        this.flyTo(coords);
      });
      this.eventHub.$on("zoomIn", () => {
        this.mapObject.zoomIn();
      });
      this.eventHub.$on("zoomOut", () => {
        this.mapObject.zoomOut();
      });

      this.eventHub.$on("addLayer", (layer) => {
        this.layers[layer.id] = layer;
        this.layers[layer.id].addTo(this.mapObject);
      });
      this.eventHub.$on("removeLayer", (layerId) => {
        if (this.layers[layerId]) {
          this.layers[layerId].remove();
          delete this.layers[layerId];
        } 
      });
      this.eventHub.$on("mapZoom", (ev) => {
        this.$refs.mapWrapper.dataset['farAway'] = this.isFarAway() ? 'yes' : 'no';
      });
      this.eventHub.$on("hideLayer", (layerId) => {
        if (this.layers[layerId]) {
          if ("function" == typeof this.layers[layerId].setOpacity) {
            this.layers[layerId].setOpacity(0);
          } else {
            this.layers[layerId].setStyle({
              opacity: 0,
            });
          } 
        } 
      });
      this.eventHub.$on("showLayer", (layerId) => {
        if (this.layers[layerId]) {
          if ("function" == typeof this.layers[layerId].setOpacity) {
            this.layers[layerId].setOpacity(1);
          } else {
            this.layers[layerId].setStyle({
              opacity: 1,
            });
          } 
        } 
      });
      this.eventHub.$on("showPopup", (popup) => {
        popup.openOn(this.mapObject);
      });
    },
    flyTo(coords) {
      this.mapObject.flyTo(L.latLng(coords.lat, coords.lng));
    },
    isFarAway() {
      return this.mapObject.getZoom() <= this.threshold;
    }
  },
  // @vuese
  // -
  // @arg `void`
  beforeDestroy() {
    if (this.mapObject) {
      this.mapObject.off();
      this.mapObject.remove();
    } 
    this.mapObject = null;
  },
};
</script>

<style scoped lang="scss">
.map {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  #map {
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss">
.leaflet-popup-content {
  width: auto;
  padding: 20px 30px 15px;
}
.leaflet-popup-content pre {
  font-family: monospace;
}
.mapbox-logo {
  position: absolute;
  bottom: 15px;
  left: -4px;
}
.map[data-far-away="yes"] .station-marker,
.map[data-far-away="yes"] .vehicle-marker {
  display: none !important;
}
</style>