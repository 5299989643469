<template>
  <modal
    name="transportPickerDialog"
    ref="transportPickerDialog"
    class="vue-dialog"
    width="400px"
    height="auto"
    :shiftX=".5"
    :shiftY=".25"
    id="transportPickerDialog"
    opened="opened"
  >
    <div class="vue-dialog-content">

      <div class="vue-dialog-content-title">{{ $t('components.TransportPicker.dialog.title') }}</div>
      <div class="line-15px"></div>
      <div class="opts">
        <ul>
          <li
            v-for="option in options"
            :key="option"
            v-on:click="toggle(option)"
            :class="{active: selected.indexOf(option) >= 0}"
            class="pointer"
          >
            <font-awesome-icon icon="check"></font-awesome-icon>
            <div class="bold">{{ $t(`components.TransportPicker.option.${option}.title`) }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button
        v-on:click="hide();"
        type="button"
        class="vue-dialog-button"
      >{{ $t('components.TransportPicker.buttons.cancel') }}</button>
      <button
        v-on:click="save(); hide()"
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >{{ $t('components.TransportPicker.buttons.okay') }}</button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";

// Modal dialog component for picking the allowed modes of transport for planned trip
// @group Dialogs
export default {
  name: "TransportPicker",
  mixins: [Ui],
  data() {
    return {
      options: ["WALK", "BICYCLE", "CAR", "PT", "SHARED_BIKE", "SHARED_SCOOTER", "SHARED_CAR", "TAXI"],
      selected: this.$store.getters.transport
    };
  },
  props: {},
  mounted() {
    this.eventHub.$on("transportPickerShow", this.show);
    this.eventHub.$on("transportPickerHide", this.hide);
  },
  methods: {
    // @vuese
    // Shows modal dialog
    // @arg `void`
    show() {
      this.selected = this.$store.getters.transport;
      this.$modal.show("transportPickerDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("transportPickerDialog");
    },
    // @vuese
    // Saves selected values to local store and suggest UI update
    // @arg `void`
    save() {
      this.$store.commit("transport", this.selected);
      // @vuese
      // Suggest fetching new plans because user has commited changes
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
    },
    // @vuese
    // Checks/unchecks clicked values and saves them to the instance
    // @arg `String` {option} selected value
    toggle(option) {
      const index = this.selected.indexOf(option);
      if (index >= 0) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(option);
      } 
        },
    // @vuese
    // Callback called after each modal opening
    // @arg `void`
    opened() {
      return null;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";

#transportPickerDialog.vue-dialog {
  .vue-dialog-content {
    li {
      padding-left: 30px;

      [data-icon="check"] {
        top: 0;
        left: -10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.vue-slider {
  margin: 30px 0 20px 0;
}

@media (min-width: 800px) {
  // #transportPickerDialog::v-deep .vm--modal {
  //   overflow: visible !important;
  //   left: 243px !important;
  //   top: 274px !important;
  // }
}
</style>
