<template>
  <div class="vehicles" :style="componentStyle">

    <template v-if="allowedVehicleOverlays.includes('publicTransport')">
      <div class="btn" :class="{ active: status.pt }" v-on:click="toggle('pt')"
        v-tooltip="$t('components.Vehicles.tooltip.pt')">
        <font-awesome-icon icon="train" class="active" />
        <font-awesome-icon icon="train" class="inactive" />
      </div>
      <OperatorsToggle ref="ptDetail" :geofencing="false" :operators="prepareFakePtOperators()" :initiator="this"
        :icon="'bus'" :title="$t('components.OperatorDetail.dialog.titlePt')" :filters="true" :ptStations="true"
        :mode="'pt'" />
    </template>

    <template v-if="allowedVehicleOverlays.includes('sharedBikes')">
      <div class="btn" :class="{ active: status.bikesharing }" v-on:click="toggle('bikesharing')"
        v-tooltip="$t('components.Vehicles.tooltip.bikesharing')">
        <font-awesome-icon icon="biking" class="active" />
        <font-awesome-icon icon="biking" class="inactive" />
      </div>
      <OperatorsToggle ref="bikesharingDetail" :geofencing="true" v-if="this.$store.getters.operators &&
    Object.keys(this.$store.getters.operators.shared_bike).length
    " :operators="this.$store.getters.operators.shared_bike" :initiator="this" :icon="'biking'"
        :stations="this.$store.getters.operators.shared_bike" :mode="'bikesharing'" />
    </template>

    <template v-if="allowedVehicleOverlays.includes('sharedMopeds')">
      <div class="btn" :class="{ active: status.mopedsharing }" v-on:click="toggle('mopedsharing')"
        v-tooltip="$t('components.Vehicles.tooltip.mopedsharing')">
        <font-awesome-icon icon="motorcycle" class="active" />
        <font-awesome-icon icon="motorcycle" class="inactive" />
      </div>
      <OperatorsToggle ref="mopedsharingDetail" :geofencing="true" v-if="this.$store.getters.operators &&
    Object.keys(this.$store.getters.operators.shared_moped).length
    " :operators="this.$store.getters.operators.shared_moped" :initiator="this" :icon="'motorcycle'"
        :mode="'mopedsharing'" />
    </template>

    <template v-if="allowedVehicleOverlays.includes('sharedScooters')">
      <div class="btn" :class="{ active: status.scootersharing }" v-on:click="toggle('scootersharing')"
        v-tooltip="$t('components.Vehicles.tooltip.scootersharing')">
        <font-awesome-icon icon="ring" class="active" />
        <font-awesome-icon icon="ring" class="inactive" />
      </div>
      <OperatorsToggle ref="scootersharingDetail" :geofencing="true" v-if="this.$store.getters.operators &&
    Object.keys(this.$store.getters.operators.shared_scooter).length
    " :operators="this.$store.getters.operators.shared_scooter" :initiator="this" :icon="'ring'"
        :mode="'scootersharing'" />
    </template>

    <template v-if="allowedVehicleOverlays.includes('sharedCars')">
      <div class="btn" :class="{ active: status.carsharing }" v-on:click="toggle('carsharing')"
        v-tooltip="$t('components.Vehicles.tooltip.carsharing')">
        <font-awesome-icon icon="car" class="active" />
        <font-awesome-icon icon="car" class="inactive" />
      </div>
      <OperatorsToggle ref="carsharingDetail" :geofencing="true" v-if="this.$store.getters.operators &&
    Object.keys(this.$store.getters.operators.shared_car).length
    " :operators="this.$store.getters.operators.shared_car" :initiator="this" :icon="'car'" :mode="'carsharing'" />
    </template>

  </div>
</template>

<script>
import L from "leaflet";
import OperatorsToggle from "./OperatorsToggle.vue";
import Ui from "../mixins/Ui.vue";
import Preprocessor from "@/components/engine/mixins/Preprocessor.vue";
import GetRequest from "@/components/engine/mixins/requests/get.ts";
import Postprocessor from "@/components/engine/mixins/Postprocessor.vue";

// Toggles layer showing (PT, Carsharing, Bikesharing) vehicles; handles automatic refresh of positions
// @group Map
export default {
  name: "Vehicles",
  components: {
    OperatorsToggle
  },
  mixins: [Ui, Preprocessor, Postprocessor],
  computed: {
    plans() {
      return this.$store.getters.plans;
    },
    operators() {
      return this.$store.getters.operators;
    },
    allowedVehicleOverlays() {
      return this.$store.getters.appSettings.allowedVehicleOverlays || [];
    },
    componentStyle() {
      return {
        height: (261 / 5 * this.allowedVehicleOverlays.length).toFixed(2) + "px",
      };
    }
  },
  data() {
    return {
      status: {
        pt: false,
        bikesharing: false,
        carsharing: false,
        mopedsharing: false,
        scootersharing: false,
      },
      collections: {
        pt: null,
        bikesharing: null,
        carsharing: null,
        mopedsharing: null,
        scootersharing: null,
      },
      zones: {
        pt: null,
        bikesharing: null,
        carsharing: null,
        mopedsharing: null,
        scootersharing: null,
      },
      endpoints: {
        pt: this.appConfig.api.vehicles.pt,
        bikesharing: this.appConfig.api.vehicles.bikesharing,
        carsharing: this.appConfig.api.vehicles.carsharing,
        mopedsharing: this.appConfig.api.vehicles.mopedsharing,
        scootersharing: this.appConfig.api.vehicles.scootersharing,
      },
      intervals: {
        pt: null,
        bikesharing: null,
        carsharing: null,
        mopedsharing: null,
        scootersharing: null,
      },
    };
  },

  mounted() {
    this.eventHub.$on("moveend", () => {
      Object.keys(this.status).forEach((mode) => {
        if (this.status[mode]) this.update(mode);
      });
    });
    this.eventHub.$on("operatorUpdated", () => {
      Object.keys(this.status).forEach((mode) => {
        if (this.status[mode]) this.update(mode);
      });
    });
  },
  methods: {
    toggle(mode) {
      this.status[mode] = !this.status[mode];

      if (!this.status[mode]) {
        clearInterval(this.intervals[mode]);
        this.hide(mode);
        // With vehicles, also hide stations
        this.eventHub.$emit("toggleStations", { enable: false, mode: mode });
        this.$parent.$refs.stations.hide(mode);
      }

      if (this.status[mode]) {
        this.intervals[mode] = setInterval(this.update(mode), 60000);

        let toggleStations = false;
        if ("pt" == mode) {

          if (this.$refs[`${mode}Detail`].ptStationsEnabled) {
            toggleStations = true;
          } // end if
        } else {
          if (this.$refs[`${mode}Detail`].getStationsSelection().length) {
            toggleStations = true;
          } // end if
        } // end if-else

        if (toggleStations) {
          this.eventHub.$emit("toggleStations", { mode: mode });
        } // end if

      } // end if

      if (this.$refs[`${mode}Detail`]) {
        this.$refs[`${mode}Detail`].btnActive = this.status[mode];
      } // end if
    },
    update(mode) {
      this.eventHub.$emit("logMessage", {
        type: "info",
        message: "Fetching vehicles",
      });

      this.hide(mode);

      if (this.$parent.isFarAway()) {
        this.eventHub.$emit("logMessage", {
          type: "warning",
          message: "The map is zoomed out too much - zoom in to fetch vehicles",
        });
        return;
      } // end if

      // Prepare the request for the default version of the API, which is base_v1.0
      const self = this;
      var providerIds = [];
      var zoneIds = [];
      const bbox = this.$store.getters.mapBbox;

      if ("bikesharing" == mode) {
        if (!this.$refs.bikesharingDetail) return;
        providerIds = this.$refs.bikesharingDetail.getSelection();
        zoneIds = this.$refs.bikesharingDetail.getZonesSelection();
      } else if ("carsharing" == mode) {
        if (!this.$refs.carsharingDetail) return;
        providerIds = this.$refs.carsharingDetail.getSelection();
        zoneIds = this.$refs.carsharingDetail.getZonesSelection();
      } else if ("mopedsharing" == mode) {
        if (!this.$refs.mopedsharingDetail) return;
        providerIds = this.$refs.mopedsharingDetail.getSelection();
        zoneIds = this.$refs.mopedsharingDetail.getZonesSelection();
      } else if ("scootersharing" == mode) {
        if (!this.$refs.scootersharingDetail) return;
        providerIds = this.$refs.scootersharingDetail.getSelection();
        zoneIds = this.$refs.scootersharingDetail.getZonesSelection();
      } // end if

      // First, geofencing zones

      const zones = [];

      zoneIds.forEach((providerId) => {
        if (this.operators.all[providerId].geofencingZones) {
          if (zoneIds.indexOf(providerId) >= 0) {
            zones.push({
              providerId: providerId,
              zones: this.operators.all[providerId].geofencingZones,
            });
          } // end if
        }
      });

      const zoneLayers = [];
      zones.forEach((zone) => {
        const zoneLayer = L.geoJSON(zone.zones);
        zoneLayer.setStyle({
          color: this.smartColor(mode, zone.providerId),
          fillColor: this.smartColor(mode, zone.providerId),
          lineWidth: 1,
        });
        zoneLayers.push(zoneLayer);
      });

      if (zoneLayers.length) {
        const zoneLayerId = `zones_${mode}`;
        const zonesCollection = L.featureGroup(zoneLayers);
        zonesCollection.id = zoneLayerId;

        this.zones[mode] = zonesCollection;
        this.eventHub.$emit("addLayer", this.zones[mode]);
        this.eventHub.$emit("showLayer", this.zones[mode].id);
      } // end if

      // Next, vehicles

      let providerIdsTxt = providerIds.join(",");
      let bboxTxt = [
        bbox._northEast.lat,
        bbox._southWest.lng,
        bbox._southWest.lat,
        bbox._northEast.lng,
      ].join(",");

      let url = this.endpoints[mode]
        .replace("{providerIds}", providerIdsTxt)
        .replace("{boundingBox}", bboxTxt);

      const filter = this.$refs.ptDetail.getFilterSettings();

      if (filter.tripId) url += `&tripIds=${filter.tripId.trim()}`;
      if (filter.routeId) url += `&routeIds=${filter.routeId.trim()}`;

      if ("pt" == mode) {
        const selectedPtVehicles = this.$refs.ptDetail.getSelection();
        if (selectedPtVehicles.length) {
          url += `&ptVehicles=${selectedPtVehicles.join(',')}`;
        } else {
          // No PT vehicle types selected, will show none
          return;
        }
      } // end if

      // Add the ability to call a custom hook before the request is sent,
      // to allow for custom modifications of the base_v1.0 requests.
      let request = { ...GetRequest };
      request.uid = "vehicles.get";
      request.url = url;
      request = this.preprocessRequest(request, this);
      
      this.$http
        .get(request.url, {
          timeout: request.timeout,
          headers: request.headers,
        })
        .then((res) => {
          res = this.postprocessResponse(res, this);
          
          const vehicles = [];
          const layerId = `vehicles_${mode}`;

          if ("pt" == mode) {

            res.data.ptVehiclePositions.forEach((item) => {
              let marker = null;

              marker = self.createMarker(
                L.latLng(item.position.latitude, item.position.longitude),
                mode,
                item.vehicleType ? item.vehicleType : null
              );

              let o = "";
              o += `Trip: ${item.trip.tripId}<br />`;
              o += `Route: ${item.trip.routeId}<br />`;

              marker.o = o;

              vehicles.push(marker);

              marker.on("click", (ev) => {
                ev.originalEvent.preventDefault();

                const popup = L.popup()
                  .setLatLng(ev.latlng)
                  .setContent(ev.target.o);
                this.eventHub.$emit("showPopup", popup);
              });
            });
          } else if (
            [
              "carsharing",
              "bikesharing",
              "mopedsharing",
              "scootersharing",
            ].indexOf(mode) >= 0
          ) {
            let marker = null;

            res.data.data.forEach((provider) => {
              if (provider.vehicles && provider.vehicles.length) {
                provider.vehicles.forEach((vehicle) => {
                  marker = self.createMarker(
                    L.latLng(vehicle.lat, vehicle.lon),
                    mode,
                    provider.providerId
                  );

                  let o = "";
                  o += `Provider: ${provider.providerId}<br />`;
                  o += `Type ID: ${vehicle.vehicleTypeId}`;
                  marker.o = o;

                  vehicles.push(marker);

                  marker.on("click", (ev) => {
                    ev.originalEvent.preventDefault();

                    const popup = L.popup()
                      .setLatLng(ev.latlng)
                      .setContent(ev.target.o);
                    this.eventHub.$emit("showPopup", popup);
                  });
                });
              } // end if
            });
          } // end if-else

          const collection = L.featureGroup(vehicles);
          collection.id = layerId;
          this.collections[mode] = collection;

          this.eventHub.$emit("addLayer", this.collections[mode]);
          this.eventHub.$emit("showLayer", this.collections[mode].id);
        });
    },
    createMarker(ll, mode, providerId = null) {
      let color = this.smartColor(mode, providerId);

      let icon = this.smartIcon(mode, providerId, true);
      if (icon) {
        icon = L.divIcon({
          html: `<div class="map-vehicle is-vehicle" style="background-color: ${this.smartColor(mode, providerId)};">${icon}</div>`,
          className: "vehicle-marker",
        });
      } // end if

      return L.marker(ll, {
        icon: icon,
        radius: 5,
        color: color,
        className: "vehicle-marker",
        interactive: true,
      });
    },
    hide(mode) {
      if (this.collections[mode]) {
        this.eventHub.$emit("hideLayer", this.collections[mode].id);
        this.eventHub.$emit("removeLayer", this.collections[mode].id);
      }
      if (this.zones[mode]) {
        this.eventHub.$emit("hideLayer", this.zones[mode].id);
        this.eventHub.$emit("removeLayer", this.zones[mode].id);
      }

    },
  },
};
</script>

<style scoped lang="scss">
.vehicles {
  position: absolute;
  width: 50px;
  height: 261px;
  border: 1px solid #f1f5f1;
  border-radius: 24px;
  background-color: #f3fff6;
  top: 310px;
  right: 20px;

  .btn {
    width: 40px;
    height: 40px;
    box-shadow: rgb(69 91 99 / 10%) 0px 6px 12px;
    border-radius: 50% !important;
    background-color: #f8f8f8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #efefef;
    margin: 10px 0 0 4px;
    z-index: 10;
    position: relative;

    .hover {
      display: none;
    }

    &.active {
      background-color: white;

      .inactive {
        display: none;
      }

      [data-icon] {
        color: #277a3e;
      }
    }

    &:not(.active) {
      .active {
        display: none;
      }
    }

    &:first-of-type {
      margin-top: 5px;
    }

    [data-icon] {
      color: #b3c5c2;
      font-size: 20px;
    }

    &:hover {

      [data-icon],
      img {
        filter: brightness(90%);
      }
    }
  }
}
</style>

<style lang="scss">
.map[data-far-away="yes"] .vehicles .btn.active [data-icon],
.map[data-far-away="yes"] .vehicles .operators-toggler {
  color: red;
}
</style>
