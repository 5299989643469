<template>
  <a
    v-on:click="pickWaypoint"
    ref="pickWaypoint"
    v-tooltip="$t('components.Panel.tooltip.pickWaypoint') + ' #' + (index + 1).toFixed(0)"
    v-html="$parent.descriptor(waypoint)"
  ></a>
</template>

<script>
export default {
  name: "WaypointBtn",
  props: ['waypoint', 'index'],
  data() {
    return {};
  },
  methods: {
    pickWaypoint() {
      // @vuese
      // Signal to show waypoint marker configuration modal
      // @arg `void`
      this.eventHub.$emit("waypointPickerShow", {index: this.index});
    },
  },
};
</script>