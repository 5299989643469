<template>
  <div v-if="false" />
</template>

<script>
export default {
  name: "CookieConsent",
  mounted() {
    const head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "./cookieconsent/cookieconsent.css";

    head.appendChild(link);

    const cookieConsentLib = document.createElement("script");
    cookieConsentLib.src = "./cookieconsent/cookieconsent.js";
    cookieConsentLib.type = "application/javascript";

    const cookieConsentInit = document.createElement("script");
    cookieConsentInit.src = "./cookieconsent/cookieconsent-init.js";
    cookieConsentInit.type = "application/javascript";

    cookieConsentLib.onload = () => {
      head.appendChild(cookieConsentInit);
    };

    head.appendChild(cookieConsentLib);
  },
  methods: {
    
  },
};
</script>

