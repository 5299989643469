<script>
import Preprocessor from "@/components/engine/mixins/Preprocessor.vue";
import GetRequest from "@/components/engine/mixins/requests/get.ts";
import Postprocessor from "@/components/engine/mixins/Postprocessor.vue";

// @vuese
// Standalone geocoder, preparing it's own request using parameters & returning response from performed `http` geocoding requests
// @group Mixins
export default {
  name: "Geocoder",
  mixins: [Preprocessor, Postprocessor],
  methods: {
    autocomplete(query, ll, _headers = null) {
      let url = this.$store.getters.dynamicApi.geocoding.classic
        .replace("{query}", query)
        .replace("{lat}", ll.lat)
        .replace("{lng}", ll.lng);

      let request = { ...GetRequest };
      request.uid = "geocoder.get";
      request.url = url;
      request = this.preprocessRequest(request, this);

      return this.$http
        .get(request.url)
        .then(function (res) {
          const data = [];
          if (res.data.features.length) {
            res.data.features.forEach(function (item) {
              data.push(item);
            });
          } // end if

          return data;
        })
        .catch(function (ex) {
          throw ex;
        });
    },
    reverseGeocode(coords) {
      let url = this.$store.getters.dynamicApi.geocoding.reverse;

      if (/^https?:\/\/.+/.test(url) && coords) {
        url = url.replace("{lat}", coords.lat).replace("{lng}", coords.lng);

        let request = { ...GetRequest };
        request.uid = "reversegeocoder.get";
        request.url = url;
        request = this.preprocessRequest(request, this);

        const response = this.$http.get(request.url);
        return this.postprocessResponse(response, this);
      } else {
        return new Promise((resolve, _reject) => {
          resolve();
        });
      } // end if-else
    },
    prettyGeocodedLocation(item) {
      let o = [];

      item.properties.street_custom = undefined;

      if (item.properties.street) {
        item.properties.street_custom = item.properties.street;
        if (item.properties.housenumber) {
          item.properties.street_custom += ` ${item.properties.housenumber}`;
        } // end if
      } // end if

      for (let interest of ["name", "municipality", "street_custom", "district", "city"]) {
        item.properties[interest] && o.push(item.properties[interest]);
      } // end for

      return o.join(", ");
    },
    prettyGeocodedMunicipality(item) {
      let o = [];

      if (item.idosCategory) {
        o.push(`${item.name}`);
      } else {
        item.properties.street_custom = undefined;

        if (item.properties.street) {
          item.properties.street_custom = item.properties.street;
          if (item.properties.housenumber) {
            item.properties.street_custom += ` ${item.properties.housenumber}`;
          } // end if
        } // end if

        for (let interest of ["name", "street_custom", "district", "city"]) {
          if (item.properties[interest]) {
            
            let chunk = item.properties[interest];
            
            if (item.properties.icon == "center" && "name" == interest) {
              chunk = `${chunk} - Centrum`;
            } // end if

            o.push(chunk);

          }
        } // end for
      } // end if-else

      return o.join(", ");
    },
  },
};
</script>