<template>
  <modal
    name="pricePickerDialog"
    ref="pricePickerDialog"
    class="vue-dialog"
    width="400px"
    height="auto"
    :shiftX=".5"
    :shiftY=".25"
    id="pricePickerDialog"
  >
    <div class="vue-dialog-content">

      <div class="vue-dialog-content-title">{{ $t('components.PricePicker.dialog.title') }}</div>
      <div class="line-15px"></div>
      <div class="opts">
        <ul>
          <li
            v-for="option in options"
            :key="option"
            v-on:click="pick(option)"
            :class="{active: selected == option}"
            class="pointer"
          >
            <font-awesome-icon icon="check"></font-awesome-icon>
            <div class="bold">{{ $t(`components.PricePicker.option.${option}.title`) }}</div>
            <div class="line-10px"></div>
            <div class="light gray desc">{{ $t(`components.PricePicker.option.${option}.description`) }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button v-on:click="hide();" type="button" class="vue-dialog-button">{{ $t('components.PricePicker.buttons.cancel') }}</button>
      <button
        v-on:click="save(); hide()"
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >{{ $t('components.PricePicker.buttons.okay') }}</button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";

// Modal dialog component for selecting the range of money costs for planned trip
// @group Dialogs
export default {
  name: "PricePicker",
  data() {
    return {
      options: ["priceCheap", "priceAverage", "priceExpensive"],
      selected: this.$store.getters.price
    };
  },
  mixins: [Ui],
  mounted() {
    this.eventHub.$on("pricePickerShow", this.show);
    this.eventHub.$on("pricePickerHide", this.hide);
  },
  methods: {
    // @vuese
    // Shows modal dialog
    // @arg `void`
    show() {
      this.selected = this.$store.getters.price;
      this.$modal.show("pricePickerDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("pricePickerDialog");
    },
    // @vuese
    // Saves selected values to local store and suggest UI update
    // @arg `void`
    save() {
      this.$store.commit("price", this.selected);
      // @vuese
      // Suggest fetching new plans because user has commited changes
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
    },
    // @vuese
    // saves selected value to instance
    // @arg `String` {option} picked value
    pick(option) {
      this.selected = option;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";

</style>

<style scoped lang="scss">
@media (min-width: 800px) {
  // #pricePickerDialog::v-deep .vm--modal {
  //   overflow: visible !important;
  //   left: 243px !important;
  //   top: 216px !important;
  // }
}
</style>
