var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.plan)?_c('div',{staticClass:"plan-detail no-scrollbar"},[_c('div',{staticClass:"plan-header"},[_c('span',{staticClass:"name"},[_c('span',{staticClass:"info"},[_c('span',{staticClass:"time",domProps:{"innerHTML":_vm._s(_vm.formatDt(_vm.plan.start.dateTime, _vm.plan.end.dateTime))}}),_c('span',{staticClass:"pull-right"},[_c('span',{staticClass:"distance"},[_vm._v(_vm._s(_vm.printLengthFromMeters(_vm.plan.stats.distanceMeters)))]),_vm._v(", "),_c('span',{staticClass:"duration"},[_vm._v(_vm._s(_vm.printDurationFromSeconds(_vm.plan.stats.durationSeconds)))]),(
              undefined !== _vm.plan.stats.priceAndPayment.priceInCzk &&
              _vm.plan.stats.priceAndPayment.priceInCzk > 0.0
            )?[_vm._v(", "),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm.printPrice(_vm.plan.stats.priceAndPayment.priceInCzk)))])]:_vm._e()],2)]),_c('span',_vm._l((_vm.plan.segments),function(segment){return _c('span',{key:segment.id,staticClass:"icon-wrapper",style:('background-color: ' +
            _vm.getIconStyle(segment.transportMode, segment.segmentType, segment.transferType)),attrs:{"data-icon-type":_vm.getIconType(segment)}},[('DROPOFF' == segment.segmentType)?_c('font-awesome-icon',{attrs:{"title":'Drop-off',"icon":'parking'}}):('PICKUP' == segment.segmentType && segment.transferType)?_c('font-awesome-icon',{attrs:{"title":'Transfer',"icon":'caret-right'}}):('PICKUP' == segment.segmentType)?_c('font-awesome-icon',{attrs:{"title":'Pick-up',"icon":'caret-up'}}):('vector' == _vm.getIconType(segment))?_c('img',{attrs:{"title":segment.transportMode,"alt":segment.transportMode,"src":_vm.getIcon(segment).src,"width":"15","height":"15"}}):_c('font-awesome-icon',{attrs:{"title":segment.transportMode,"icon":_vm.getIcon(segment)}})],1)}),0),_c('pre',[_vm._v(_vm._s(_vm.plan.id))]),_c('a',{staticClass:"btn-update",class:{ updating: _vm.updating },on:{"click":function($event){return _vm.clickUpdate(_vm.plan)}}},[_vm._v(" "+_vm._s(_vm.$t("components.PlanDetail.update"))+" "),_c('font-awesome-icon',{attrs:{"icon":'circle-notch',"spin":""}})],1),_c('br'),_c('a',{staticClass:"btn-execute",class:{ executing: _vm.executing },on:{"click":function($event){return _vm.clickExecute(_vm.plan)}}},[_vm._v(" "+_vm._s(_vm.$t("components.PlanDetail.execute"))+" "),_c('font-awesome-icon',{attrs:{"icon":'circle-notch',"spin":""}})],1)])]),_c('div',{staticClass:"plan-body"},[_c('ul',[_vm._l((_vm.plan.segments),function(segment,i){return _c('li',{key:i,staticClass:"segment",class:{ switched: segment._switched }},[_c('span',{staticClass:"icon-wrapper",style:('background-color: ' +
            _vm.getIconStyle(segment.transportMode, segment.segmentType, segment.transferType)),attrs:{"data-type":segment.segmentType}},[('DROPOFF' == segment.segmentType)?_c('font-awesome-icon',{attrs:{"title":'Drop-off',"icon":'parking'}}):('PICKUP' == segment.segmentType && segment.transferType)?_c('font-awesome-icon',{attrs:{"title":'Transfer',"icon":'caret-right'}}):('PICKUP' == segment.segmentType)?_c('font-awesome-icon',{attrs:{"title":'Pick-up',"icon":'caret-up'}}):('vector' == _vm.getIconType(segment))?_c('img',{attrs:{"title":segment.transportMode,"alt":segment.transportMode,"src":_vm.getIcon(segment).src,"width":"15","height":"15"}}):_c('font-awesome-icon',{attrs:{"title":segment.transportMode,"icon":_vm.getIcon(segment)}})],1),('PT' == segment.transportMode)?_c('div',{staticClass:"journey-name"},[_vm._v(" "+_vm._s(segment.rideDetails.ptDetails.route.ptVehicle)+" "+_vm._s(segment.rideDetails.ptDetails.route.routeShortName)+" ("+_vm._s(segment.rideDetails.ptDetails.route.routeId)+") ")]):('DROPOFF' == segment.segmentType)?_c('div',{staticClass:"journey-name"},[_vm._v(" Drop-off ")]):('PICKUP' == segment.segmentType && segment.transferType)?_c('div',{staticClass:"journey-name"},[_vm._v(" Transfer ")]):('PICKUP' == segment.segmentType)?_c('div',{staticClass:"journey-name"},[_vm._v(" Pick-up ")]):_c('div',{staticClass:"journey-name"},[_vm._v(" "+_vm._s(segment.transportMode[0] + segment.transportMode.toLowerCase().substring(1))+" ")]),_c('span',{staticClass:"btn-info pointer",on:{"click":function($event){return _vm.showSegment(segment)}}},[_c('font-awesome-icon',{attrs:{"title":"Info","icon":"info-circle"}})],1),('DROPOFF' == segment.segmentType)?_c('div',{staticClass:"small"},[(
              segment.dropOffDetails.carParkingDetails &&
              segment.dropOffDetails.carParkingDetails.carParking !== null
            )?[_vm._v(" "+_vm._s(segment.dropOffDetails.carParkingDetails.carParking.name)+" "),(
                segment.dropOffDetails.carParkingDetails.carParkingMeasurement
              )?[_vm._v(" ("+_vm._s(segment.dropOffDetails.carParkingDetails.carParkingMeasurement .availableSpotCount)+"/"+_vm._s(segment.dropOffDetails.carParkingDetails.carParkingMeasurement .totalSpotCount)+" available) ")]:_vm._e(),(segment.dropOffDetails.carParkingDetails.carParking.name)?[_vm._v(" - ")]:_vm._e(),_vm._v(" "+_vm._s(segment.dropOffDetails.carParkingDetails.carParking .addressFormatted)+" ")]:(
              segment.bicycleParkingDetails &&
              segment.bicycleParkingDetails !== null
            )?[_vm._v(" "+_vm._s(segment.dropOffDetails.bicycleParkingDetails.bicycleParking.name)+" "),(
                segment.dropOffDetails.bicycleParkingDetails.bicycleParking
                  .name
              )?[_vm._v(" - ")]:_vm._e(),_vm._v(" "+_vm._s(segment.dropOffDetails.bicycleParkingDetails.bicycleParking .addressFormatted)+" ")]:_vm._e()],2):('PICKUP' == segment.segmentType)?_c('div',{staticClass:"small"},[(
              segment.pickupDetails &&
              segment.pickupDetails.sharedVehicleDetails &&
              'string' ==
                typeof segment.pickupDetails.sharedVehicleDetails.vehicle
                  .vehicleId
            )?[_vm._v(" Vehicle "),_c('code',[_vm._v(_vm._s(segment.pickupDetails.sharedVehicleDetails.vehicle.vehicleId))])]:_vm._e()],2):_c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.printLengthFromMeters( segment.stats.distanceMeters, _vm.smartUnitLvlFromMeters(segment.stats.distanceMeters) ))+", "+_vm._s(_vm.printDurationFromSeconds(segment.stats.durationSeconds))+" ")]),('PT' == segment.transportMode && (_vm.appConfig.app.features.include('prevSegment') || _vm.appConfig.app.features.include('nextSegment')))?_c('div',[(_vm.appConfig.app.features.include('prevSegment'))?_c('a',{staticClass:"prev btn-prevnext",on:{"click":function($event){return _vm.previous({
                current: segment,
                previous: _vm.plan.segments[i - 1],
                next: _vm.plan.segments[i + 1],
              })}}},[_c('font-awesome-icon',{attrs:{"icon":"angle-left","title":"Previous connection"}}),_vm._v(" Prev")],1):_vm._e(),(_vm.appConfig.app.features.include('nextSegment'))?_c('a',{staticClass:"next btn-prevnext pull-right",on:{"click":function($event){return _vm.next({
                current: segment,
                previous: _vm.plan.segments[i - 1],
                next: _vm.plan.segments[i + 1],
              })}}},[_vm._v("Next "),_c('font-awesome-icon',{attrs:{"icon":"angle-right","title":"Next connection"}})],1):_vm._e(),_c('PtStops',{attrs:{"stops":segment.rideDetails.ptDetails.stopTimes.slice(
                segment.rideDetails.ptDetails.getOnStopIndex,
                segment.rideDetails.ptDetails.getOffStopIndex + 1
              ),"startIndex":segment.rideDetails.ptDetails.getOnStopIndex + 1}})],1):_vm._e()])}),_c('li',{},[_c('span',{staticClass:"icon-wrapper",style:('background-color: #349a51')},[_c('font-awesome-icon',{attrs:{"icon":'flag-checkered'}})],1)])],2)]),_c('GenericDialog',{ref:"dialog",attrs:{"title":'Plan ' + _vm.plan.id,"body":_vm.dialogBody}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }