<template>
  <modal
    name="timePickerDialog"
    ref="timePickerDialog"
    class="vue-dialog"
    width="400px"
    height="auto"
    :shiftX="0.5"
    :shiftY="0.25"
    id="timePickerDialog"
  >
    <div class="vue-dialog-content">

      <div class="vue-dialog-content-title">{{ $t("components.TimePicker.dialog.title") }}</div>
      <div class="line-15px"></div>
      <div class="opts">
        <input ref="date" type="text" role="date" placeholder="yyyy-mm-dd" v-on:change="pick" :value="date" />
        <input ref="time" type="time" placeholder="hh:mm" v-on:change="pick" :value="time" />
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button v-on:click="hide()" type="button" class="vue-dialog-button">
        {{ $t("components.CarsharingPicker.buttons.cancel") }}
      </button>
      <button
        v-on:click="
          save();
          hide();
        "
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.CarsharingPicker.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";
import System from "../mixins/System.vue";

// Displays a modal dialog with time and date selectors for planned trip
// @group Dialogs
export default {
  name: "TimePicker",
  data() {
    return {
      selected: this.$store.getters.time,
    };
  },
  mixins: [Ui, System],
  computed: {
    date() {
      let o = '';
      o += this.selected.getFullYear();
      o += '-';
      o += ('0' + (this.selected.getMonth() + 1)).slice(-2);
      o += '-';
      o += ('0' + this.selected.getDate()).slice(-2);

      return o;
    },
    time() {
      let o = '';
      o += ('0' + this.selected.getHours()).slice(-2);
      o += ':';
      o += ('0' + (this.selected.getMinutes())).slice(-2);

      return o;
    }
  },
  mounted() {
    this.eventHub.$on("timePickerShow", this.show);
    this.eventHub.$on("timePickerHide", this.hide);
  },
  methods: {
    // @vuese
    // Shows modal dialog
    // @arg `void`
    show() {
      this.selected = this.$store.getters.time;
      this.$modal.show("timePickerDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("timePickerDialog");
    },
    // @vuese
    // Saves selected values to local store and suggest UI update
    // @arg `void`
    save() {
      this.$store.commit("time", this.selected);
      // @vuese
      // Suggest fetching new plans because user has commited changes
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
    },
    // @vuese
    // Save values from date and time inputs to instance 
    // @arg `void`
    pick() {
      let dt = new Date;
      if (this.$refs.date.value.length) {
        if (this.$refs.date.value.match(/\d{4}-\d{2}-\d{2}/)) {
          dt = new Date(this.$refs.date.value);
        } // end if
      } // end if
      
      let time = '12:00'.split(':');
      if (this.$refs.time.value.length) time = this.$refs.time.value.split(':');

      dt.setHours(time[0]);
      dt.setMinutes(time[1]);

      this.selected = dt;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";
</style>

<style scoped lang="scss">
@media (min-width: 800px) {
  // #timePickerDialog::v-deep .vm--modal {
  //   overflow: visible !important;
  //   left: 243px !important;
  //   top: 583px !important;

  //   [data-icon="caret-left"] {
  //     top: 52px;
  //   }
  // }
}
input {
  background-color: #349a511a;
  border: 1px solid gray;
  padding: 6px 12px;
  border-radius: 3px;
  margin: 0;

  &[type="date"], &[role="date"] {
    margin: 0 10px 0 0;
    border: 1px solid gray !important;
    width: 80px !important;
    font-weight: normal !important;
  }
}
</style>
