<template>
  <modal name="operatorDetailDialog" ref="operatorDetailDialog" class="vue-dialog" width="450px" height="auto"
    :shiftX="0.5" :shiftY="0.25" id="operatorDetailDialog" @opened="onAfterShow()">
    <div class="vue-dialog-content">
      <div class="vue-dialog-content-title">
        {{
          modalTitle ? modalTitle : $t("components.OperatorDetail.dialog.title")
        }}
      </div>
      <div class="line-15px"></div>
      <div class="opts">
        <table>
          <thead>
            <tr v-if="ptStations" class="section-row">
              <td :colspan="3 + extraColumns()">
                <div class="heading">
                  Vehicles
                </div>
              </td>
            </tr>
            <tr>
              <th></th>
              <th>
                <font-awesome-icon :icon="['fas', icon ? icon : 'car']"></font-awesome-icon>
              </th>
              <th v-if="geofencing">
                <font-awesome-icon :icon="['fas', 'vector-square']"></font-awesome-icon>
              </th>
              <th v-if="stations">
                <font-awesome-icon :icon="['fas', 'warehouse']"></font-awesome-icon>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="operatorId in Object.keys(operators)" v-bind:key="operatorId">
              <td>
                {{ operators[operatorId].name }}
                <span class="punkt" :style="{
                  'background-color': initiator.smartColor(
                    'none',
                    operatorId
                  ),
                }"></span>
              </td>
              <td>
                <button v-on:click="toggle(operatorId)" class="btn" :data-selected="selectedVehicles.indexOf(operators[operatorId].id) >= 0
                  ? 'true'
                  : 'false'
                  ">
                  <font-awesome-icon data-for="not-selected" :icon="['fas', 'square']"></font-awesome-icon>
                  <font-awesome-icon data-for="selected" :icon="['fas', 'check-square']"></font-awesome-icon>
                </button>
              </td>
              <td v-if="geofencing">
                <button v-on:click="toggleGeofencing(operatorId)" class="btn" :data-selected="selectedGeofencing.indexOf(operators[operatorId].id) >= 0
                  ? 'true'
                  : 'false'
                  ">
                  <font-awesome-icon data-for="not-selected" :icon="['fas', 'square']"></font-awesome-icon>
                  <font-awesome-icon data-for="selected" :icon="['fas', 'check-square']"></font-awesome-icon>
                </button>
              </td>
              <td v-if="stations">
                <button v-on:click="toggleStation(operatorId)" class="btn" :data-selected="selectedStations.indexOf(operators[operatorId].id) >= 0
                  ? 'true'
                  : 'false'
                  ">
                  <font-awesome-icon data-for="not-selected" :icon="['fas', 'square']"></font-awesome-icon>
                  <font-awesome-icon data-for="selected" :icon="['fas', 'check-square']"></font-awesome-icon>
                </button>
              </td>
            </tr>

            <tr class="footer">
              <td>Toggle all / none</td>
              <td>
                <a v-on:click="checkAll('vehicles')">all</a>/<a v-on:click="checkNone('vehicles')">none</a>
              </td>
              <td v-if="geofencing">
                <a v-on:click="checkAll('geofencing')">all</a>/<a v-on:click="checkNone('geofencing')">none</a>
              </td>
              <td v-if="stations">
                <a v-on:click="checkAll('stations')">all</a>/<a v-on:click="checkNone('stations')">none</a>
              </td>
            </tr>
          </tbody>
          <tfoot v-if="filters || ptStations">
            <tr v-if="filters" class="filters-row1">
              <td>Route ID</td>
              <td :colspan="2 + extraColumns()" class="filter-input">
                <input type="text" v-model="filterModel.routeId" />
              </td>
            </tr>
            <tr v-if="filters" class="filters-row2">
              <td>Trip ID</td>
              <td :colspan="2 + extraColumns()" class="filter-input">
                <input type="text" v-model="filterModel.tripId" />
              </td>
            </tr>
            <tr v-if="ptStations" class="section-row">
              <td :colspan="3 + extraColumns()">
                <div class="heading">
                  Stations
                </div>
              </td>
            </tr>
            <tr v-if="ptStations" class="filters-row2">
              <td>Display</td>
              <td :colspan="2 + extraColumns()" class="filter-input">
                <span class="toggle" :data-state="ptStationsEnabled ? 'on' : 'off'"
                  v-on:click="ptStationsEnabled = !ptStationsEnabled"
                  v-tooltip="$t('components.OperatorDetail.buttons.toggle')">
                  <span class="ball"></span>
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="far-away">
        <p class="alert alert-danger">
          <span>
            Map too far away
          </span>
          <br />
          <br />
          <small>
            The map needs to be zoomed in for it to display vehicle and station markers due to performance reasons.
          </small>
        </p>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button v-on:click="hide()" type="button" class="vue-dialog-button">
        {{ $t("components.OperatorDetail.buttons.cancel") }}
      </button>
      <button v-on:click="
        save();
      hide();
      " type="button" class="vue-dialog-button vue-dialog-button-success">
        {{ $t("components.OperatorDetail.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";
import System from "../mixins/System.vue";

// Modal dialog component for selecting the accepted range of walking for planned trip
// @group Dialogs
export default {
  name: "OperatorDetail",
  data() {
    return {
      operators: {},
      selectedVehicles: [],
      selectedGeofencing: [],
      geofencing: null,
      stations: null,
      selectedStations: [],
      toggleBtn: null,
      icon: null,
      initiator: null,
      modalTitle: null,
      filters: false,
      filterModel: {
        routeId: "",
        tripId: "",
      },
      ptStations: null,
      ptStationsEnabled: null,
    };
  },
  mixins: [Ui, System],
  mounted() {
    const self = this;

    this.eventHub.$on("operatorDetailShow", this.show);
    this.eventHub.$on("operatorDetailHide", this.hide);

    this.eventHub.$on("toggledStations", (data) => {
      self[`${data.mode}StationsEnabled`] = data.enabled;
    });

  },
  methods: {
    // @vuese
    // Shows modal dialog
    // @arg `void`
    show(data) {
      this.operators = data.operators;
      this.geofencing = data.geofencing;
      this.selectedVehicles = data.selectedVehicles;
      this.selectedGeofencing = data.selectedGeofencing;
      this.toggleBtn = data.toggle;
      this.icon = data.icon;
      this.initiator = data.initiator;
      this.modalTitle = data.title;
      this.filters = data.filters ? data.filters : false;
      this.ptStations = data.ptStations;
      this.stations = data.stations;
      this.selectedStations = data.selectedStations;
      this.ptStationsEnabled = data.ptStationsEnabled;

      this.$modal.show("operatorDetailDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("operatorDetailDialog");
    },
    toggle(operatorId) {
      const self = this;

      if (this.selectedVehicles.indexOf(operatorId) >= 0) {
        this.selectedVehicles = this.selectedVehicles.filter((item) => {
          return item != operatorId;
        });
      } else {
        this.selectedVehicles.push(operatorId);
      } // end if-else
    },
    toggleGeofencing(operatorId) {
      const self = this;

      if (this.selectedGeofencing.indexOf(operatorId) >= 0) {
        this.selectedGeofencing = this.selectedGeofencing.filter((item) => {
          return item != operatorId;
        });
      } else {
        this.selectedGeofencing.push(operatorId);
      } // end if-else
    },
    toggleStation(operatorId) {
      const self = this;

      if (this.selectedStations.indexOf(operatorId) >= 0) {
        this.selectedStations = this.selectedStations.filter((item) => {
          return item != operatorId;
        });
      } else {
        this.selectedStations.push(operatorId);
      } // end if-else
    },
    // @vuese
    // Saves selected values to local store and suggest UI update
    // @arg `void`
    save() {
      this.toggleBtn.onUpdate({
        selectedVehicles: this.selectedVehicles,
        selectedGeofencing: this.selectedGeofencing,
        selectedStations: this.selectedStations,
        filterModel: this.filterModel,
        ptStationsEnabled: this.ptStationsEnabled,
      });
    },
    checkAll(what) {
      if ("vehicles" == what) {
        this.selectedVehicles = Object.keys(this.operators);
      } else if ("stations" == what) {
        this.selectedStations = Object.keys(this.stations);
      } else {
        this.selectedGeofencing = Object.keys(this.operators);
      } // end if-else
    },
    checkNone(what) {
      let interest = null;
      switch (what) {
        case "stations":
          interest = "selectedStations";
          break;
        case "geofencing":
          interest = "selectedGeofencing";
          break;
        case "vehicles":
        default:
          interest = "selectedVehicles";
          break;
      } // end switch
      this[interest] = [];
    },
    extraColumns() {
      let cols = 0;

      if (this.geofencing) {
        cols++;
      } // end if

      if (this.stations) {
        cols++;
      } // end if

      return cols;
    },
    onAfterShow() {
      this.$refs.operatorDetailDialog.$el.dataset.isFarAway = this.initiator.$parent.isFarAway();
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";
</style>

<style scoped lang="scss">
table {
  font-size: 13px;
  width: 100%;

  thead,
  tbody {
    tr {

      th,
      td {
        text-align: center;

        &:first-of-type {
          text-align: left;
        }
      }
    }
  }

  thead {
    tr {
      th {
        border-bottom: 1px solid white;
        padding: 2px 2px 10px 2px;
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;

        .punkt {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          float: right;
        }
      }

      &.footer {
        td {
          padding-top: 12px;
          padding-bottom: 12px;
          border-top: 1px solid #eeeeee;
        }
      }
    }
  }

  tfoot {
    tr:first-of-type {
      td {
        // border-top: 1px solid #eeeeee;
        padding-top: 10px;
      }
    }

    td {
      // padding-top: 20px;

      &.filter-input {
        max-width: 50px;
        text-align: center;

        input {
          width: 80px !important;
          height: 19px !important;
          margin: 3px;
        }
      }
    }

    tr.filters-row2,
    tr.filters-row3 {
      td {
        padding-top: 0;
        height: 35px;
        vertical-align: middle;
      }
    }
  }

  .section-row {
    td {
      .heading {
        padding: 10px 0 10px 10px;
        margin: 10px 10px 5px 0;
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #eeeeee;
        color: black;
      }
    }
  }

  tr:not(.section-row)>td:first-of-type {
    padding-left: 10px;
  }

  .toggle {
    cursor: pointer;
    border: 2px solid gray;
    border-radius: 15px;
    width: 40px;
    height: 21px;
    display: inline-block;
    z-index: 1;

    .ball {
      border-radius: 50%;
      margin: 2px;
      width: 17px;
      height: 17px;
    }

    &[data-state="on"] {
      border-color: #349a51;

      .ball {
        background-color: #349a51;
        float: right;
      }
    }

    &[data-state="off"] {
      .ball {
        background-color: lightgray;
        float: left;
      }
    }
  }
}

.btn {
  min-height: 30px;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  border: 0;
  margin: 2px 0;

  &[data-selected="true"] {
    [data-for="not-selected"] {
      display: none;
    }
  }

  &[data-selected="false"] {
    [data-for="selected"] {
      display: none;
    }
  }

  svg {
    &[data-for="selected"] {
      color: #349a51;
    }

    color: #b3c5c2;
    font-size: 16px;
  }

  .hover {
    display: none;
  }

  &.active {
    background-color: white;

    .inactive {
      display: none;
    }

    [data-icon] {
      color: #277a3e;
    }
  }

  &:not(.active) {
    .active {
      display: none;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.far-away {
  display: none;
}

[data-is-far-away="true"] {
  .far-away {
    display: block;
  }
}

.alert {
  border-radius: 5px;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 15px;
  margin-top: 10px;

  &.alert-danger {
    background: var(--toastlog-error) !important;
    border-color: var(--toastlog-error-border) !important;
    box-shadow: var(--toastlog-error-shadow) 0 2px 6px;
    color: white;
  }

  small {
    font-size: 11px;
    font-family: monospace;
  }
}

#operatorDetailDialog .vue-dialog-content {
  max-height: 90vh;
  overflow: scroll;
}
</style>
