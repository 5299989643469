<script>
import L from "leaflet";

// Helps to manage layers; including skipping unused ones and optimizing their ordering
// @group Map/Mixins
export default {
  name: "LayerManager",
  mounted() {
    this.eventHub.$on("clearPlans", this.clear);
    this.eventHub.$on("clearSegments", this.clearSegments);
    this.eventHub.$on("pushPlans", list => {
      this.pushPlans(list);
    });
    this.eventHub.$on("pushSegments", list => {
      this.pushSegments(list);
    });
  },
  data() {
    return {
      plans: [],
      allLayers: [
        "background",
        "highlight",
        "trajectory",
        "traffic",
        "surface",
        "steepness",
        "signs",
        "structures",
        "osmLinks",
        "arrows",
        "origin",
        "destination",
      ],
      highlightLayers: ["highlight", "origin", "destination", "signs", "structures", "osmLinks", "arrows", "background", "traffic",],
      specialLayers: ["surface", "steepness"],
      segments: []
    };
  },
  methods: {
    clear() {
      this.plans.forEach(plan => {
        this.eventHub.$emit("removeLayer", plan.id);
      });
      this.plans = [];
    },
    pushPlans(plans) {
      const activePlan = this.$store.getters.activePlan;

      const list = [];

      plans.forEach(plan => {
        if (activePlan == plan.id) {
          this.allLayers.forEach(layerAlias => {
            if (plan[layerAlias]) {
              if (this.$store.getters.planMode == "transparent") {
                if ("trajectory" == layerAlias) {
                  plan[layerAlias].setStyle({ opacity: 0.66 });
                  list.push(plan[layerAlias]);
                }
              } else {
                if (
                  "trajectory" == layerAlias &&
                  this.$store.getters.planMode != "trajectory"
                ) {
                  /* Duh. */
                } else {
                  if (this.specialLayers.indexOf(layerAlias) >= 0) {
                    if (this.$store.getters.planMode == layerAlias) {
                      list.push(plan[layerAlias]);
                    } 
                  } else {
                    list.push(plan[layerAlias]);
                  } 
                } 
              } 
            } 
          });
        } else {
          if (this.$store.getters.planMode != "transparent") {
            this.allLayers.forEach(layerAlias => {
              if (plan[layerAlias]) {
                if (
                  this.highlightLayers.indexOf(layerAlias) == -1 &&
                  this.specialLayers.indexOf(layerAlias) == -1
                ) {
                  list.push(plan[layerAlias]);
                } 
              } 
            });
          } 
        } 
      });

      const collection = L.featureGroup(list);
      collection.id = `plans`;
      this.plans.push(collection);
      this.eventHub.$emit("addLayer", collection);
    },
    clearSegments() {
      this.segments.forEach(segment => {
        this.eventHub.$emit("removeLayer", segment.id);
      });
      this.segments = [];
    },
    pushSegments(list) {
      this.segments.push(list);
      this.eventHub.$emit("addLayer", list);
    }
  }
};
</script>