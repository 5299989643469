var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vehicles",style:(_vm.componentStyle)},[(_vm.allowedVehicleOverlays.includes('publicTransport'))?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('components.Vehicles.tooltip.pt')),expression:"$t('components.Vehicles.tooltip.pt')"}],staticClass:"btn",class:{ active: _vm.status.pt },on:{"click":function($event){return _vm.toggle('pt')}}},[_c('font-awesome-icon',{staticClass:"active",attrs:{"icon":"train"}}),_c('font-awesome-icon',{staticClass:"inactive",attrs:{"icon":"train"}})],1),_c('OperatorsToggle',{ref:"ptDetail",attrs:{"geofencing":false,"operators":_vm.prepareFakePtOperators(),"initiator":this,"icon":'bus',"title":_vm.$t('components.OperatorDetail.dialog.titlePt'),"filters":true,"ptStations":true,"mode":'pt'}})]:_vm._e(),(_vm.allowedVehicleOverlays.includes('sharedBikes'))?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('components.Vehicles.tooltip.bikesharing')),expression:"$t('components.Vehicles.tooltip.bikesharing')"}],staticClass:"btn",class:{ active: _vm.status.bikesharing },on:{"click":function($event){return _vm.toggle('bikesharing')}}},[_c('font-awesome-icon',{staticClass:"active",attrs:{"icon":"biking"}}),_c('font-awesome-icon',{staticClass:"inactive",attrs:{"icon":"biking"}})],1),(this.$store.getters.operators &&
  Object.keys(this.$store.getters.operators.shared_bike).length
  )?_c('OperatorsToggle',{ref:"bikesharingDetail",attrs:{"geofencing":true,"operators":this.$store.getters.operators.shared_bike,"initiator":this,"icon":'biking',"stations":this.$store.getters.operators.shared_bike,"mode":'bikesharing'}}):_vm._e()]:_vm._e(),(_vm.allowedVehicleOverlays.includes('sharedMopeds'))?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('components.Vehicles.tooltip.mopedsharing')),expression:"$t('components.Vehicles.tooltip.mopedsharing')"}],staticClass:"btn",class:{ active: _vm.status.mopedsharing },on:{"click":function($event){return _vm.toggle('mopedsharing')}}},[_c('font-awesome-icon',{staticClass:"active",attrs:{"icon":"motorcycle"}}),_c('font-awesome-icon',{staticClass:"inactive",attrs:{"icon":"motorcycle"}})],1),(this.$store.getters.operators &&
  Object.keys(this.$store.getters.operators.shared_moped).length
  )?_c('OperatorsToggle',{ref:"mopedsharingDetail",attrs:{"geofencing":true,"operators":this.$store.getters.operators.shared_moped,"initiator":this,"icon":'motorcycle',"mode":'mopedsharing'}}):_vm._e()]:_vm._e(),(_vm.allowedVehicleOverlays.includes('sharedScooters'))?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('components.Vehicles.tooltip.scootersharing')),expression:"$t('components.Vehicles.tooltip.scootersharing')"}],staticClass:"btn",class:{ active: _vm.status.scootersharing },on:{"click":function($event){return _vm.toggle('scootersharing')}}},[_c('font-awesome-icon',{staticClass:"active",attrs:{"icon":"ring"}}),_c('font-awesome-icon',{staticClass:"inactive",attrs:{"icon":"ring"}})],1),(this.$store.getters.operators &&
  Object.keys(this.$store.getters.operators.shared_scooter).length
  )?_c('OperatorsToggle',{ref:"scootersharingDetail",attrs:{"geofencing":true,"operators":this.$store.getters.operators.shared_scooter,"initiator":this,"icon":'ring',"mode":'scootersharing'}}):_vm._e()]:_vm._e(),(_vm.allowedVehicleOverlays.includes('sharedCars'))?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('components.Vehicles.tooltip.carsharing')),expression:"$t('components.Vehicles.tooltip.carsharing')"}],staticClass:"btn",class:{ active: _vm.status.carsharing },on:{"click":function($event){return _vm.toggle('carsharing')}}},[_c('font-awesome-icon',{staticClass:"active",attrs:{"icon":"car"}}),_c('font-awesome-icon',{staticClass:"inactive",attrs:{"icon":"car"}})],1),(this.$store.getters.operators &&
  Object.keys(this.$store.getters.operators.shared_car).length
  )?_c('OperatorsToggle',{ref:"carsharingDetail",attrs:{"geofencing":true,"operators":this.$store.getters.operators.shared_car,"initiator":this,"icon":'car',"mode":'carsharing'}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }