<template>
  <modal
    name="bikesharingPickerDialog"
    ref="bikesharingPickerDialog"
    class="vue-dialog"
    width="800px"
    height="auto"
    :shiftX="0.5"
    :shiftY="0.25"
    id="bikesharingPickerDialog"
    opened="opened"
  >
    <div class="vue-dialog-content">

      <div class="vue-dialog-content-title">
        {{ $t("components.BikesharingPicker.dialog.title") }}
      </div>
      <div class="line-15px"></div>
      <div class="columns">
        <div class="column left">
          <div class="opts">
            <p class="subheading is-first">
              Which bike sharing providers you want to use?
            </p>
            <ul>
              <li
                v-for="option in options"
                :key="option"
                v-on:click="toggle(option)"
                :class="{ active: selected.indexOf(option) >= 0 }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">{{ operators.shared_bike[option].name }}</div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">Riding speed</p>
            <ul>
              <li
                v-for="option in sharedBikeSpeed.options"
                :key="option"
                v-on:click="sharedBikeSpeed.selected = option"
                :class="{ active: sharedBikeSpeed.selected == option }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{
                    $t(`components.BikesharingPicker.option.${option}.title`)
                  }}
                </div>
                <div class="line-10px"></div>
                <div class="light gray desc">
                  {{
                    $t(
                      `components.BikesharingPicker.option.${option}.description`
                    )
                  }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="column right">
          <div class="opts" style="margin-top: 32px;">
            <div class="values">
              <span class="left">How far do you want to cycle?</span>
              <span class="right bold pull-right">{{
                kms > 0 ? prettyKms(kms) : "No riding"
              }}</span>
            </div>
            <vue-slider ref="slider" v-model="kms" v-bind="sliderOpts" />
          </div>
          <div class="opts">
            <p class="subheading">Avoid steep climbs</p>
            <ul>
              <li
                v-on:click="avoidSteepClimbs = ! avoidSteepClimbs"
                :class="{ active: avoidSteepClimbs }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ avoidSteepClimbs ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">Only electric vehicles</p>
            <ul>
              <li
                v-on:click="onlyElectric = ! onlyElectric"
                :class="{ active: onlyElectric }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ onlyElectric ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">Best route only</p>
            <ul>
              <li
                v-on:click="returnBestOnlyRoute = ! returnBestOnlyRoute"
                :class="{ active: returnBestOnlyRoute }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ returnBestOnlyRoute ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">Blocked parking IDs</p>
            <input
              type="text"
              v-model="blockedParkingIds"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button v-on:click="hide()" type="button" class="vue-dialog-button">
        {{ $t("components.BikesharingPicker.buttons.cancel") }}
      </button>
      <button
        v-on:click="
          save();
          hide();
        "
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.BikesharingPicker.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";
import System from "../mixins/System.vue";

// Modal dialog component for picking BikeSharing provider(s)
// @group Dialogs
export default {
  name: "BikesharingPicker",
  mixins: [Ui, System],
  data() {
    return {
      options: [],
      selected: this.$store.getters.bikesharing,
      sharedBikeSpeed: {
        options: ["rideSlow", "rideAverage", "rideFast"],
        selected: "rideFast",
      },
      kms: 10,
      sliderOpts: {
        dotSize: 14,
        width: "auto",
        height: 4,
        contained: false,
        direction: "ltr",
        data: null,
        min: 0,
        max: 75,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: "active",
        tooltipPlacement: "top",
        tooltipFormatter: void 0,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        process: true,
        dotStyle: void 0,
        railStyle: void 0,
        processStyle: void 0,
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
      avoidSteepClimbs: true,
      onlyElectric: false,
      returnBestOnlyRoute: true,
      blockedParkingIds: "",
    };
  },
  mounted() {
    this.eventHub.$on("bikesharingPickerShow", this.show);
    this.eventHub.$on("bikesharingPickerHide", this.hide);
  },
  computed: {
    operators() {
      return this.$store.getters.operators;
    },
  },
  methods: {
    // @vuese
    // Shows modal dialog
    // @arg `void`
    show() {
      this.options = Object.keys(this.operators.shared_bike);

      this.selected = this.$store.getters.bikesharing;
      this.$modal.show("bikesharingPickerDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("bikesharingPickerDialog");
    },
    // @vuese
    // Saves selected values to local store and suggest UI update
    // @arg `void`
    save() {
      this.$store.commit("bikesharing", this.selected);

      // 1.5
      this.$store.commit("avoidSteepClimbsBS", this.avoidSteepClimbs);
      this.$store.commit("returnBestOnlyRouteBS", this.returnBestOnlyRoute);
      this.$store.commit("onlyElectricBS", this.onlyElectric);
      this.$store.commit("blockedParkingIdsBS", this.blockedParkingIds.toString().trim().replace(' ', '').split(','));
      this.$store.commit("cyclingBS", this.sharedBikeSpeed.selected);
      this.$store.commit("cyclingKmsBS", this.kms);
      

      // @vuese
      // Suggest fetching new plans because user has changed one or more options
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
    },
    // @vuese
    // Checks/unchecks clicked values and saves them to the instance
    // @arg `String` {option} selected value
    toggle(option) {
      const index = this.selected.indexOf(option);
      if (index >= 0) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(option);
      }
    },
    // @vuese
    // Callback called after each modal opening
    // @arg `void`
    opened() {
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";

#bikesharingPickerDialog.vue-dialog {
  .vue-dialog-content {
    li {
      padding-left: 30px;

      [data-icon="check"] {
        top: 0;
        left: -10px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.vue-slider {
  margin: 30px 0 20px 0;
}

@media (min-width: 800px) {
  // #bikesharingPickerDialog::v-deep .vm--modal {
  //   overflow: visible !important;
  //   left: 253px !important;
  //   top: 370px !important;

  //   [data-icon="bikeet-left"] {
  //     top: 205px;
  //   }
  // }
}
</style>
