<template>
  <span class="viapoint-del-row">
    <a
      class="viapoint-del"
      v-on:click="delVia()"
      v-tooltip="'Del waypoint'"
      ><font-awesome-icon
        class="via"
        :icon="['fas', 'minus-square']"
      ></font-awesome-icon
    ></a>
  </span>
</template>

<script>
export default {
  name: "ViaDel",
  props: ["index"],
  data() {
    return {};
  },
  mounted() {
    this.eventHub.$on(`delWaypoint_${this.index}`, () => {
      this.delVia();
    });
  },
  methods: {
    delVia() {
      let via = this.$store.getters.via;
      via.splice(this.index, 1);
      this.$store.commit("via", via);
      this.eventHub.$emit("waypointPinDestroy", { index: this.index });
    },
  },
};
</script>

<style scoped lang="scss">
.viapoint-del-row {
  display: block;
  position: relative;
  height: 0;

  .viapoint-del {
    position: absolute;
    right: -18px;
    top: -42px;
    font-size: 18px;
    border-radius: 50%;
    color: #7b7b7b;
    font-weight: normal;
    text-decoration: none;
    padding: 4px 10px;
    z-index: 3;
    cursor: pointer;

    &:hover {
      background-color: #349a511a;
      color: black;
    }
  }
}
</style>