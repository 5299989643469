<template>
  <modal
    name="cyclingPickerDialog"
    ref="cyclingPickerDialog"
    class="vue-dialog"
    width="800px"
    height="auto"
    :shiftX="0.5"
    :shiftY="0.25"
    id="cyclingPickerDialog"
  >
    <div class="vue-dialog-content">

      <div class="vue-dialog-content-title">
        {{ $t("components.CyclingPicker.dialog.title") }}
      </div>
      <div class="line-15px"></div>

      <div class="columns">
        <div class="column left">
          <div class="opts">
            <div class="values">
              <span class="left">How far do you want to cycle?</span>
              <span class="right bold pull-right">{{
                kms > 0 ? prettyKms(kms) : "No riding"
              }}</span>
            </div>
            <vue-slider ref="slider" v-model="kms" v-bind="options.kms" />
          </div>
          <div class="opts">
            <p class="subheading is-first">How fast do you ride?</p>
            <ul>
              <li
                v-for="option in options.cycling"
                :key="option"
                v-on:click="pick(option)"
                :class="{ active: selected.cycling == option }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ $t(`components.CyclingPicker.option.${option}.title`) }}
                </div>
                <div class="line-10px"></div>
                <div class="light gray desc">
                  {{
                    $t(`components.CyclingPicker.option.${option}.description`)
                  }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="column right">
          <div class="opts">
            <p class="subheading">Avoid steep climbs</p>
            <ul>
              <li
                v-on:click="options.avoidSteepClimbs = ! options.avoidSteepClimbs"
                :class="{ active: options.avoidSteepClimbs }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ options.avoidSteepClimbs ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">Avoid stairs</p>
            <ul>
              <li
                v-on:click="options.avoidStairsCycling = ! options.avoidStairsCycling"
                :class="{ active: options.avoidStairsCycling }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ options.avoidStairsCycling ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">I need to park my bicycle</p>
            <ul>
              <li
                v-on:click="options.parkBicycle = ! options.parkBicycle"
                :class="{ active: options.parkBicycle }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ options.parkBicycle ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">Bring my bicycle to public transport</p>
            <ul>
              <li
                v-on:click="options.bicycleInPt = ! options.bicycleInPt"
                :class="{ active: options.bicycleInPt }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ options.bicycleInPt ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">Blocked parking IDs</p>
            <input
              type="text"
              v-model="options.blockedParkingIds"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button v-on:click="hide()" type="button" class="vue-dialog-button">
        {{ $t("components.CyclingPicker.buttons.cancel") }}
      </button>
      <button
        v-on:click="
          save();
          hide();
        "
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.CyclingPicker.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";
import System from "../mixins/System.vue";

// Modal dialog component for picking the amount of cycling visitor is willing to do while on a trip
// @group Dialogs
export default {
  name: "CyclingPicker",
  data() {
    return {
      options: {
        // cycling: ["cyclingLittle", "cyclingAverage", "cyclingLot"],
        cycling: ["cycleSlow", "cycleAverage", "cycleFast"],
        // bikesharing: [],
        // scootersharing: [],
        kms: {
          dotSize: 14,
          width: "auto",
          height: 4,
          contained: false,
          direction: "ltr",
          data: null,
          min: 0,
          max: 75,
          interval: 1,
          disabled: false,
          clickable: true,
          duration: 0.5,
          adsorb: false,
          lazy: false,
          tooltip: "active",
          tooltipPlacement: "top",
          tooltipFormatter: void 0,
          useKeyboard: false,
          keydownHook: null,
          dragOnClick: false,
          enableCross: true,
          fixed: false,
          minRange: void 0,
          maxRange: void 0,
          order: true,
          marks: false,
          dotOptions: void 0,
          process: true,
          dotStyle: void 0,
          railStyle: void 0,
          processStyle: void 0,
          tooltipStyle: void 0,
          stepStyle: void 0,
          stepActiveStyle: void 0,
          labelStyle: void 0,
          labelActiveStyle: void 0,
        },
        avoidSteepClimbs: false,
        avoidStairsCycling: false,
        parkBicycle: true,
        bicycleInPt: false,
        blockedParkingIds: "",
      },
      selected: {
        cycling: this.$store.getters.cycling,
        // bikesharing: this.$store.getters.bikesharing,
        // scootersharing: this.$store.getters.scootersharing,
      },
      kms: this.$store.getters.cyclingKms,
    };
  },
  mixins: [Ui, System],
  mounted() {
    this.eventHub.$on("cyclingPickerShow", this.show);
    this.eventHub.$on("cyclingPickerHide", this.hide);
  },
  computed: {
    operators() {
      return this.$store.getters.operators;
    },
  },
  methods: {
    // @vuese
    // Shows modal dialog
    // @arg `void`
    show() {
      this.selected.cycling = this.$store.getters.cycling;
      this.$modal.show("cyclingPickerDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("cyclingPickerDialog");
    },
    // @vuese
    // Saves selected values to local store and suggest UI update
    // @arg `void`
    save() {
      this.$store.commit("cycling", this.selected.cycling);
      this.$store.commit("cyclingKms", this.kms);

      // 1.5
      this.$store.commit("avoidSteepClimbsB", this.options.avoidSteepClimbs);
      this.$store.commit("parkBicycle", this.options.parkBicycle);
      this.$store.commit("bicycleInPt", this.options.bicycleInPt);
      this.$store.commit("avoidStairsCycling", this.options.avoidStairsCycling);
      this.$store.commit("blockedParkingIdsB", this.options.blockedParkingIds.toString().trim().replace(' ', '').split(','));

      // this.$store.commit("bikesharing", this.selected.bikesharing);
      // this.$store.commit("scootersharing", this.selected.scootersharing);
      // @vuese
      // Suggest fetching new plans because user has changed one or more options
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
    },
    // @vuese
    // saves selected value to instance
    // @arg `String` {option} picked value
    pick(option) {
      this.selected.cycling = option;
    },
    toggleBikesharing(option) {
      const index = this.selected.bikesharing.indexOf(option);
      if (index >= 0) {
        this.selected.bikesharing.splice(index, 1);
      } else {
        this.selected.bikesharing.push(option);
      }
    },
    toggleScootersharing(option) {
      const index = this.selected.scootersharing.indexOf(option);
      if (index >= 0) {
        this.selected.scootersharing.splice(index, 1);
      } else {
        this.selected.scootersharing.push(option);
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";
</style>

<style scoped lang="scss">
.vue-slider {
  margin: 30px 0 20px 0;
}
#cyclingPickerDialog {
    .vue-dialog-content {
        height: 550px;
    }
}
@media (min-width: 800px) {
  // #cyclingPickerDialog::v-deep .vm--modal {
  //   overflow: visible !important;
  //   left: 243px !important;
  //   top: 213px !important;
  // }
}
</style>
