<template>
  <div class="cmd">
    <div
      class="btn"
      v-on:click="openModal()"
      v-tooltip="'Custom request'"
    >
      <font-awesome-icon icon="terminal" class="active" />
      <font-awesome-icon icon="terminal" class="inactive" />
    </div>
  </div>
</template>

<script>

// Toggles layer showing (PT, Carsharing, Bikesharing) cmd; handles automatic refresh of positions
// @group Map
export default {
  name: "Cmd",
  computed: {
    plans() {
      return this.$store.getters.plans;
    },
  },
  data() {
    return {
      
    };
  },
  methods: {
    openModal() {
      this.eventHub.$emit("cmdDialogShow");
    }
  },
};
</script>

<style scoped lang="scss">
.cmd {
  position: absolute;
  width: 50px;
  height: 52px;
  // border: 1px solid #f1f5f1;
  border-radius: 24px;
  // background-color: #f3fff6;
  bottom: 20px;
  right: 20px;

  .btn {
    width: 40px;
    height: 40px;
    box-shadow: rgb(69 91 99 / 10%) 0px 6px 12px;
    border-radius: 50% !important;
    background-color: #f8f8f8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #efefef;
    margin: 10px 0 0 4px;

    .hover {
      display: none;
    }
    &.active {
      background-color: white;

      .inactive {
        display: none;
      }

      [data-icon] {
        color: #277a3e;
      }
    }
    &:not(.active) {
      .active {
        display: none;
      }
    }

    &:first-of-type {
      margin-top: 5px;
    }

    [data-icon] {
      color: #b3c5c2;
      font-size: 20px;
    }

    &:hover {
      [data-icon],
      img {
        filter: brightness(90%);
      }
    }
  }
}
</style>
