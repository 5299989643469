var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.plans.length)?_c('div',{staticClass:"plan-picker"},[_c('p',{domProps:{"innerHTML":_vm._s(
      _vm.plans.length
        ? _vm.$t('components.PlanPicker.message.success', { cnt: _vm.plans.length })
        : _vm.$t('components.PlanPicker.message.nothing')
    )}}),_c('div',{staticClass:"plans-wrapper"},_vm._l((_vm.plans),function(plan){return _c('div',{key:plan.id,staticClass:"plan pointer",class:{
        active: _vm.selected == plan.id,
        'bike-friendly': plan._.type == 'bikeFriendly',
        fast: plan._.type == 'fast',
      },on:{"click":function($event){return _vm.pick(plan)}}},[_c('span',{staticClass:"name"},[_c('span',{staticClass:"info"},[_c('span',{staticClass:"time",domProps:{"innerHTML":_vm._s(_vm.formatDt(plan.start.dateTime, plan.end.dateTime))}}),_c('span',{staticClass:"pull-right"},[_c('span',{staticClass:"distance"},[_vm._v(_vm._s(_vm.printLengthFromMeters(plan.stats.distanceMeters)))]),_vm._v(", "),_c('span',{staticClass:"duration"},[_vm._v(_vm._s(_vm.printDurationFromSeconds(plan.stats.durationSeconds)))]),(
                undefined !== plan.stats.priceAndPayment.priceInCzk &&
                plan.stats.priceAndPayment.priceInCzk > 0.0
              )?[_vm._v(", "),_c('span',{staticClass:"price"},[_vm._v(_vm._s(_vm.printPrice(plan.stats.priceAndPayment.priceInCzk)))])]:_vm._e()],2)]),_c('span',_vm._l((plan.segments),function(segment){return _c('span',{key:segment.id,staticClass:"icon-wrapper",style:('background-color: ' +
              _vm.getIconStyle(segment.transportMode, segment.segmentType, segment.transferType)),attrs:{"data-icon-type":_vm.getIconType(segment)}},[('DROPOFF' == segment.segmentType)?_c('font-awesome-icon',{attrs:{"title":'Drop-off',"icon":'parking'}}):('PICKUP' == segment.segmentType && segment.transferType)?_c('font-awesome-icon',{attrs:{"title":'Transfer',"icon":'angle-right'}}):('PICKUP' == segment.segmentType)?_c('font-awesome-icon',{attrs:{"title":'Pick-up',"icon":'angle-up'}}):('vector' == _vm.getIconType(segment))?_c('img',{attrs:{"title":segment.transportMode,"alt":segment.transportMode,"src":_vm.getIcon(segment).src,"width":"15","height":"15"}}):_c('font-awesome-icon',{attrs:{"title":segment.transportMode,"icon":_vm.getIcon(segment)}})],1)}),0),_c('div',{staticClass:"plan-id"},[_vm._v(" "+_vm._s(plan.id)+" ")])])])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }