<script>

// @vuese
// Helper for dynamically coloring plans on map
// @group Map/Mixins
export default {
  name: "GeojsonStyler",
  methods: {
    stress(layer) {
      let val = layer.feature.properties.stress;
      let opacity = val / 5;
      let color = this.getGeojsonColor(opacity);
      return this.finalize(color);
    },
    surface(layer) {
      let color = "#FFFFFF";
      switch (layer.feature.properties.surface) {
        case "EXCELLENT":
          color = "#909090";
          break;
        case "GOOD":
          color = "#BCBCBC";
          break;
        case "INTERMEDIATE":
          color = "#FFDB7F";
          break;
        case "BAD":
          color = "#AC8400";
          break;
        case "HORRIBLE":
          color = "#EE0000";
          break;
        case "IMPASSABLE":
          color = "#5E0000";
          break;
      } 
      return this.finalize(color);
    },
    slope(layer) {
      let limit = 12.0;
      let val = layer.feature.properties.slope;
      if (val > limit) val = limit;
      let opacity = val / limit;

      let color = this.getGeojsonColor(opacity);
      return this.finalize(color);
    },
    finalize(color) {
      return {
        fillColor: color,
        color: color,
        weight: this.appConfig.map.plan.weight.geoJson
      };
    },
    getGeojsonColor(value) {
      var hue = ((1 - value) * 120).toString(10);
      return ["hsl(", hue, ",100%,50%)"].join("");
    }
  }
};
</script>