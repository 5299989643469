<template>
  <a
    v-on:click="pickDestination"
    ref="pickDestination"
    v-tooltip="$t('components.Panel.tooltip.pickDestination')"
    v-html="$parent.descriptor(destination)"
  ></a>
</template>

<script>
export default {
  name: "DestinationBtn",
  props: ['destination'],
  data() {
    return {};
  },
  methods: {
    pickDestination() {
      // @vuese
      // Signal to show destination marker configuration modal
      // @arg `void`
      this.eventHub.$emit("destinationPickerShow");
    },
  },
};
</script>