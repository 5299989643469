<template>
  <modal
    name="genericDialog"
    ref="genericDialog"
    class="vue-dialog"
    width="400px"
    height="auto"
    :shiftX="0.5"
    :shiftY="0.25"
    id="genericDialog"
  >
    <div class="vue-dialog-content">
      <div class="vue-dialog-content-title">{{ title }}</div>
      <div class="line-15px"></div>
      <div class="body" v-html="body"></div>
    </div>
    <div class="vue-dialog-buttons">
      <button
        v-on:click="hide()"
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.GenericDialog.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";

// Modal dialog component for selecting the accepted range of walking for planned trip
// @group Dialogs
export default {
  name: "GenericDialog",
  props: ["title", "body"],
  data() {
    return {};
  },
  mixins: [Ui],
  mounted() {
    this.eventHub.$on("genericDialogShow", this.show);
    this.eventHub.$on("genericDialogHide", this.hide);
  },
  methods: {
    show() {
      this.$modal.show("genericDialog");
    },
    hide() {
      this.$modal.hide("genericDialog");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";
</style>

<style scoped lang="scss">

</style>
