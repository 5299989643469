<template>
  <div class="osm" :class="{usable: usable}">
    <div :class="{active: active}" class="btn" v-on:click="toggleOsm()" v-tooltip="'Toggle OSM links'">
      <font-awesome-icon icon="link" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Osm",
  data() {
    return {

    };
  },
  computed: {
    active() {
      return this.$store.getters.osm;
    },
    usable() {
      return this.$store.getters.osmUsable;
    }
  },
  methods: {
    toggleOsm() {
      this.$store.commit('osm', ! this.$store.getters.osm);
      this.eventHub.$emit("repaint");
    },
  },
};
</script>

<style scoped lang="scss">
.osm {
  position: absolute;
  width: 50px;
  height: 52px;
  // border: 1px solid #f1f5f1;
  border-radius: 24px;
  // background-color: #f3fff6;
  bottom: 120px;
  right: 20px;
  &:not(.usable) {
    pointer-events: none;
    opacity: .5;
    filter: blur(1px);
  }

  .btn {
    width: 40px;
    height: 40px;
    box-shadow: rgb(69 91 99 / 10%) 0px 6px 12px;
    border-radius: 50% !important;
    background-color: #f8f8f8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #efefef;
    margin: 10px 0 0 4px;

    .hover {
      display: none;
    }
    &.active {
      background-color: white;

      .inactive {
        display: none;
      }

      [data-icon] {
        color: #277a3e;
      }
    }
    &:not(.active) {
      .active {
        display: none;
      }
    }

    &:first-of-type {
      margin-top: 5px;
    }

    [data-icon] {
      color: #b3c5c2;
      font-size: 20px;
    }

    &:hover {
      [data-icon],
      img {
        filter: brightness(90%);
      }
    }
  }
}
</style>
