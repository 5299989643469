<template>
  <div class="plan-not-found" v-if="visible" :class="{ active: active }">
    <p class="alert alert-danger">
      <span v-html="msg"></span>
      <template v-if="reason">
        <br />
        <br />
        <small>
          > {{ reason }}
        </small>
      </template>
    </p>
  </div>
</template>

<script>
import Converter from "../mixins/Converter.vue";

// Handles switching between different fetched plans
// @group Map
export default {
  name: "PlanNotFound",
  mixins: [Converter],
  mounted() {
    this.eventHub.$on("getPlans", () => {
      this.hide();
    });
    this.eventHub.$on("planNotFound", (payload) => {
      if (payload.reason) {
        this.reason = payload.reason;
      } else {
        this.reason = null;
      } // end if-else

      this.show();
    });
  },
  data() {
    return {
      visible: false,
      active: false,
      reason: null,
      msg: "Could not find any routes with current settings.",
    };
  },
  computed: {
  //   msg() {
  //     let o = "Could not find any routes with current settings.";
  //     if (this.reason) {
  //       o += "<br />";
  //       o += "<br />";
  //       o += `<small>${this.reason} </small>`;
  //     } // end if
  //     return o;
  //   },
  },
  methods: {
    show() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      } // end if
      this.visible = true;
      this.active = true;
    },
    hide() {
      this.active = false;
      this.timeout = setTimeout(() => {
        this.visible = false;
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";

.plan-not-found {
  padding-bottom: 15px;

  .alert {
    border-radius: 5px;
    padding: 20px 30px;
    font-weight: 500;
    font-size: 15px;

    &.alert-danger {
      background: var(--toastlog-error) !important;
      border-color: var(--toastlog-error-border) !important;
      box-shadow: var(--toastlog-error-shadow) 0 2px 6px;
      color: white;
    }

    small {
      font-size: 11px;
      font-family: monospace;
    }
  }

  &.active {
    animation: fadein 1s;
    animation-fill-mode: forwards;
  }
  &:not(.active) {
    animation: fadeout 1s;
    animation-fill-mode: forwards;
    // opacity: 0;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
</style>
