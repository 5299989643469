<template>
  <div
    id="logger"
    class="logger"
    :class="{ minimized: minimized, maximized: !minimized }"
  >
    <div class="inner">
      <ul class="logs" v-if="!minimized">
        <li
          v-for="(log, i) in logs"
          v-bind:key="i"
          class="toastify animate__slideInUp"
          :class="'toast-' + log.type"
        >
          <font-awesome-icon
            v-if="'error' == log.type"
            :icon="['fas', 'times-circle']"
          ></font-awesome-icon>

          <font-awesome-icon
            v-else-if="'warning' == log.type"
            :icon="['fas', 'exclamation-triangle']"
          ></font-awesome-icon>

          <font-awesome-icon
            v-else-if="'network' == log.type"
            :icon="['fas', 'satellite-dish']"
          ></font-awesome-icon>

          <font-awesome-icon
            v-else
            :icon="['fas', 'comment-alt']"
          ></font-awesome-icon>

          <span class="msg" v-html="log.message"></span>

          <br v-if="log.request || log.details" />
          <template v-if="log.request">
            <a style="margin-right: 15px;" v-on:click="showDialog(log.request, 'request')" href="#">Request<font-awesome-icon
                class="ico-modal"
                :icon="['fas', 'info-circle']"
              ></font-awesome-icon>
            </a>
          </template>

          <template v-if="log.details">
            <a style="margin-right: 15px;" v-on:click="showDialog(log.details)" href="#">Response<font-awesome-icon
                class="ico-modal"
                :icon="['fas', 'external-link-alt']"
              ></font-awesome-icon>
            </a>
            <a
              v-on:click="copyToClipboard(log.details)"
              href="#"
              >Copy<font-awesome-icon
                class="ico-modal"
                :icon="['fas', 'copy']"
              ></font-awesome-icon>
            </a>
          </template>

          <template v-if="log.url">
            <br />
            {{ log.url }}{{ log.line ? ":" + log.line : "" }}
          </template>

          <font-awesome-icon
            v-on:click="remove(i)"
            :icon="['fas', 'times']"
            class="close"
          ></font-awesome-icon>
        </li>
      </ul>
      <ul v-else class="preview">
        <li
          v-on:click="minMax()"
          v-for="(type, j) in ['info', 'warning', 'error', 'network']"
          v-bind:key="j"
          class="toastify animate__slideInUp"
          :class="'toast-' + type"
        >
          {{ getCnt(type) }}
        </li>
      </ul>
    </div>
    <a class="toastify clear" href="#" v-on:click="minMax()"
      >{{ minimized ? "Show logs" : "Hide logs" }} ({{ logs.length }})</a
    >
  </div>
</template>

<script>
import Ui from "./mixins/Ui.vue";

export default {
  name: "Logger",
  mixins: [Ui],
  mounted() {
    const self = this;
    window.onerror = function (message, url, line, column, error) {
      if (error && error.message) {
        message = `Uncaught error: ${error.message}`;
      } // end if

      if (message.toLowerCase().indexOf("geolocation") >= -1) {
        message += `<br />Geolocation requires HTTPS`;
      }

      if (url) {
        let match = url.match(/(?<start>.*)(?<end>\?.*$)/);

        if (match.groups) {
          url = match.groups.start;
        } // end if

        if (url.length > 25) {
          url = ".." + url.slice(url.length - 25);
        } // end if
      } // end if

      self.message({
        type: "error",
        message: message,
        url: url,
        line: line,
        column: column,
        error: error,
      });
    };

    window.onunhandledrejection = function (errorEvent) {
      self.message({
        type: "error",
        message: errorEvent.reason,
        url: null,
        line: null,
        column: null,
        error: errorEvent,
      });
    };

    this.listen();
    this.message({
      type: "info",
      message: "App started",
    });

    // Debug / Develop only:
    // this.demo();
  },
  data() {
    return {
      logs: [],
      minimized: true,
    };
  },
  methods: {
    message(msg) {
      this.logs.push(msg);
    },
    remove(index) {
      this.logs.splice(index, 1);
    },
    listen() {
      const self = this;

      this.$http.interceptors.request.use(
        function (config) {

          config.metadata = { startTime: new Date() };
          self.message({
            type: "network",
            message: `Starting ${config.method.toUpperCase()} <a href="${
              config.url
            }" target="_blank">${config.url}</a>`,
          });
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );

      this.$http.interceptors.response.use(
        (response) => {
          response.config.metadata.endTime = new Date();
          response.duration =
            response.config.metadata.endTime -
            response.config.metadata.startTime;

          const responseRaw = JSON.parse(JSON.stringify(response.data));
          let request = null;
          if (response.config.data) {
            request = JSON.parse(response.config.data);
          } // end if

          self.message({
            type: "info",
            message: `Network request finished in ${(
              response.duration / 1000
            ).toFixed(2)}s with status ${response.status}`,
            details: responseRaw,
            request: request
          });
          return response;
        },
        (error) => {
          let msg = `Network Error: Calling ${error.config.method.toUpperCase()} <a href="${
            error.config.url
          }" target="_blank">${
            error.config.url
          }</a> failed.<br />Response code unknown. Check <a href="https://www.google.com/search?q=how+to+open+chrome+devtools" target="_blank">console</a> for CORS issues.`;
          let url = null;

          if (error.config.method && error.response) {
            msg = `Network Error: ${error.config.method.toUpperCase()} request failed with status code ${
              error.response.status
            } failed.`;
            url = error.config.url;
          } // end if

          self.message({
            type: "warning",
            message: msg,
            url: url,
            error: error,
          });
        }
      );

      this.eventHub.$on("logMessage", (msg) => {
        if (undefined === msg.type) msg.type = "info";
        self.message(msg);
      });

      this.eventHub.$on("getPlans", () => {
        self.message({
          type: "info",
          message: "Fetching plans",
        });
      });
      this.eventHub.$on("fetchNext", () => {
        self.message({
          type: "info",
          message: "Fetching next connection",
        });
      });
      this.eventHub.$on("fetchPrevious", () => {
        self.message({
          type: "info",
          message: "Fetching previous connection",
        });
      });
      this.eventHub.$on("updatePlan", () => {
        self.message({
          type: "info",
          message: "Updating plan..",
        });
      });
      this.eventHub.$on("planUpdated", () => {
        self.message({
          type: "info",
          message: "Plan updated",
        });
      });
      this.eventHub.$on("executePlan", () => {
        self.message({
          type: "info",
          message: "Executing plan..",
        });
      });
      this.eventHub.$on("planExecuted", () => {
        self.message({
          type: "info",
          message: "Plan executed",
        });
      });
      this.eventHub.$on("suggestUpdate", () => {
        self.message({
          type: "info",
          message: "Configuration changes detected",
        });
      });

      this.eventHub.$on("TODO", () => {
        self.message({
          type: "network",
          message: "Fetching plans",
        });
      });
    },
    clearAll() {
      this.logs = [];
    },
    minMax() {
      this.minimized = !this.minimized;
    },
    getCnt(type) {
      var cnt = 0;
      this.logs.forEach((log) => {
        if (log.type == type) {
          cnt++;
        }
      });
      return cnt;
    },
    demo() {
      setTimeout(function () {
        throw new Error("This is an example error message");
      }, 0);

      this.message({
        type: "warning",
        message: "This is a warning",
      });
      this.message({
        type: "info",
        message: "Hello world!",
      });
      this.message({
        type: "network",
        message: "Network request started",
      });
    },
    showDialog(details, mode) {
      this.eventHub.$emit("responseDialogShow", {details: details, mode: mode});
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/toast.scss";
</style>

<style scoped lang="scss">
.logger {
  pointer-events: none;

  &:hover {
    opacity: 1;
    transition: opacity 0.3s;
  }
  &:not(:hover) {
    opacity: 0.75;
    transition: opacity 0.3s;
  }

  @media screen and (max-width: 1350px) {
    transform: scale(0.75);
    transform-origin: bottom right;
  }

  z-index: 5;
  position: absolute;
  width: 500px;
  height: auto;
  bottom: 10px;
  right: 120px;

  ul.logs {
    pointer-events: all;
    li {
      padding-right: 25px;

      svg {
        opacity: 0.66;
      }

      .close {
        cursor: pointer;
        color: black;
        position: absolute;
        right: 8px;
        top: 11px;
        &:hover {
          filter: brightness(80%);
        }
      }
      .msg {
        margin-left: 10px;
        word-break: break-word;
        position: relative;
        top: -1px;
      }
      .ico-modal {
        position: relative;
        left: 5px;
        top: 0px;
        font-size: 12px;
      }
    }
    position: relative;
    top: -30px;
  }

  ul.preview {
    width: auto;
    float: right;
    margin-bottom: -10px;
    margin-right: -20px;

    li {
      width: 30px;
      height: 30px;
      min-width: 0;
      text-align: center;
      float: left;
      padding: 5px 0px;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
  }

  .clear {
    pointer-events: all;
    position: absolute;
    right: 167px;
    bottom: -10px;
    font-size: 14px;
    padding: 5px 5px;
    text-align: center;
  }
}
</style>