<template>
  <modal
    name="carPickerDialog"
    ref="carPickerDialog"
    class="vue-dialog"
    width="800px"
    height="auto"
    :shiftX="0.5"
    :shiftY="0.25"
    id="carPickerDialog"
  >
    <div class="vue-dialog-content">

      <div class="vue-dialog-content-title">
        {{ $t("components.CarPicker.dialog.title") }}
      </div>
      <div class="line-15px"></div>
      <div class="columns">
        <div class="column left">
          <div class="opts">
            <div class="values">
              <span class="left">How far do you want to drive?</span>
              <span class="right bold pull-right">{{
                kms > 0 ? prettyKms(kms) : "No driving"
              }}</span>
            </div>
            <vue-slider ref="slider" v-model="kms" v-bind="options.kms" />
          </div>
          <div class="opts">
            <p class="subheading compact">Parking zones</p>
            <ul>
              <li
                v-for="option in options.parkingZoneTypes.options"
                :key="option"
                v-on:click="toggleParkingZone(option)"
                :class="{
                  active:
                    options.parkingZoneTypes.selected.indexOf(option) >= 0,
                }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  <template v-if="$t(`components.CarPicker.option.${option}.title`) == `components.CarPicker.option.${option}.title`">
                    {{ option }}
                  </template>
                  <template v-else>
                    {{ $t(`components.CarPicker.option.${option}.title`) }}
                  </template>
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading compact">Use toll free roads</p>
            <ul>
              <li
                v-on:click="
                  options.useTollFreeRoads = !options.useTollFreeRoads
                "
                :class="{ active: options.useTollFreeRoads }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ options.useTollFreeRoads ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading compact">I need to park my car</p>
            <ul>
              <li
                v-on:click="options.parkCar = !options.parkCar"
                :class="{ active: options.parkCar }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  {{ options.parkCar ? "Yes" : "No" }}
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading compact">Price per km</p>
            <input
              type="number"
              min="0"
              max="2147483647"
              v-model="options.pricePerKmInCzk"
            />
          </div>
          <div class="opts">
            <p class="subheading compact">Blocked parking IDs</p>
            <input type="text" v-model="options.blockedParkingIds" />
          </div>
          <div class="opts">
            <p class="subheading compact">Max parking price / hour</p>
            <input
              type="number"
              min="0"
              max="2147483647"
              v-model="options.maxParkingPriceCzkPerHour"
            />
          </div>
          <div class="opts">
            <p class="subheading compact">Parking time (minutes)</p>
            <input
              type="number"
              min="0"
              max="2147483647"
              v-model="options.expectedParkingTimeMinutes"
            />
          </div>
        </div>
        <div class="column right">
          <div class="opts">
            <p class="subheading">Parking types</p>
            <ul>
              <li
                v-for="option in options.carParkingTypes.options"
                :key="option"
                v-on:click="toggleParkingType(option)"
                :class="{
                  active: options.carParkingTypes.selected.indexOf(option) >= 0,
                }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  <template v-if="$t(`components.CarPicker.option.${option}.title`) == `components.CarPicker.option.${option}.title`">
                    {{ option }}
                  </template>
                  <template v-else>
                    {{ $t(`components.CarPicker.option.${option}.title`) }}
                  </template>
                </div>
              </li>
            </ul>
          </div>
          <div class="opts">
            <p class="subheading">Parking providers</p>
            <ul>
              <li
                v-for="option in options.parkingProviders.options"
                :key="option"
                v-on:click="toggleParkingProvider(option)"
                :class="{
                  active:
                    options.parkingProviders.selected.indexOf(option) >= 0,
                }"
                class="pointer"
              >
                <font-awesome-icon icon="check"></font-awesome-icon>
                <div class="bold">
                  <template v-if="$t(`components.CarPicker.option.${option}.title`) == `components.CarPicker.option.${option}.title`">
                    {{ option }}
                  </template>
                  <template v-else>
                    {{ $t(`components.CarPicker.option.${option}.title`) }}
                  </template>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="vue-dialog-buttons">
      <button v-on:click="hide()" type="button" class="vue-dialog-button">
        {{ $t("components.CarPicker.buttons.cancel") }}
      </button>
      <button
        v-on:click="
          save();
          hide();
        "
        type="button"
        class="vue-dialog-button vue-dialog-button-success"
      >
        {{ $t("components.CarPicker.buttons.okay") }}
      </button>
    </div>
  </modal>
</template>

<script>
import Ui from "../mixins/Ui.vue";
import System from "../mixins/System.vue";

// Modal dialog component for selecting the accepted range of driving for planned trip
// @group Dialogs
export default {
  name: "CarPicker",
  data() {
    return {
      options: {
        driving: ["driveSlow", "driveAverage", "driveFast"],
        kms: {
          dotSize: 14,
          width: "auto",
          height: 4,
          contained: false,
          direction: "ltr",
          data: null,
          min: 0,
          max: 150,
          interval: 1,
          disabled: false,
          clickable: true,
          duration: 0.5,
          adsorb: false,
          lazy: false,
          tooltip: "active",
          tooltipPlacement: "top",
          tooltipFormatter: void 0,
          useKeyboard: false,
          keydownHook: null,
          dragOnClick: false,
          enableCross: true,
          fixed: false,
          minRange: void 0,
          maxRange: void 0,
          order: true,
          marks: false,
          dotOptions: void 0,
          process: true,
          dotStyle: void 0,
          railStyle: void 0,
          processStyle: void 0,
          tooltipStyle: void 0,
          stepStyle: void 0,
          stepActiveStyle: void 0,
          labelStyle: void 0,
          labelActiveStyle: void 0,
        },
        pricePerKmInCzk: this.$store.getters.pricePerKmInCzk,
        useTollFreeRoads: this.$store.getters.useTollFreeRoads,
        parkCar: this.$store.getters.parkCar,
        parkingZoneTypes: {
          options: this.$store.getters.parkingZoneTypesValues,
          selected: this.$store.getters.parkingZoneTypes,
        },
        parkingProviders: {
          options: [],
          selected: []
        },
        carParkingTypes: {
          options: this.$store.getters.carParkingTypesValues,
          selected: this.$store.getters.carParkingTypes,
        },
        maxParkingPriceCzkPerHour: this.$store.getters.maxParkingPriceCzkPerHour,
        expectedParkingTimeMinutes: this.$store.getters.expectedParkingTimeMinutes,
        blockedParkingIds: this.$store.getters.blockedParkingIdsC,
      },
      selected: {
        driving: this.$store.getters.driving,
      },
      kms: this.$store.getters.drivingKms,
    };
  },
  computed: {
    operators() {
      return this.$store.getters.operators;
    },
  },
  watch: {},
  mixins: [Ui, System],
  mounted() {
    this.eventHub.$on("carPickerShow", this.show);
    this.eventHub.$on("carPickerHide", this.hide);
  },
  methods: {
    // @vuese
    // Shows modal dialog
    // @arg `void`
    show() {
      this.selected.driving = this.$store.getters.driving;
      this.options.carParkingTypes.selected = this.$store.getters.carParkingTypes;
      this.options.carParkingTypes.options = this.$store.getters.carParkingTypesValues;
      this.options.parkingZoneTypes.selected = this.$store.getters.parkingZoneTypes;
      this.options.parkingZoneTypes.options = this.$store.getters.parkingZoneTypesValues;
      this.options.parkingProviders.options = Object.keys(this.operators.parking);
      this.options.parkingProviders.selected = this.$store.getters.parkingProviders;
      this.$modal.show("carPickerDialog");
    },
    // @vuese
    // Hides modal dialog
    // @arg `void`
    hide() {
      this.$modal.hide("carPickerDialog");
    },
    // @vuese
    // Saves selected values to local store and suggest UI update
    // @arg `void`
    save() {
      this.$store.commit("driving", this.selected.driving);
      this.$store.commit("drivingKms", this.kms);

      // 1.5
      this.$store.commit("maxParkingPriceCzkPerHour", this.options.maxParkingPriceCzkPerHour);
      this.$store.commit("expectedParkingTimeMinutes", this.options.expectedParkingTimeMinutes);
      this.$store.commit("blockedParkingIdsC", this.options.blockedParkingIds.toString().trim().replace(' ', '').split(','));
      this.$store.commit("pricePerKmInCzk", this.options.pricePerKmInCzk);
      this.$store.commit("useTollFreeRoads", this.options.useTollFreeRoads);
      this.$store.commit("parkCar", this.options.parkCar);
      this.$store.commit("parkingZoneTypes", this.options.parkingZoneTypes.selected);
      this.$store.commit("carParkingTypes", this.options.carParkingTypes.selected);
      this.$store.commit("parkingProviders", this.options.parkingProviders.selected);

      // @vuese
      // Suggest fetching new plans because user has changed one or more options
      // @arg `void`
      this.eventHub.$emit("suggestUpdate");
    },
    // @vuese
    // saves selected value to instance
    // @arg `String` {option} picked value
    pick(option) {
      this.selected.driving = option;
    },
    toggleParkingZone(option) {
      const index = this.options.parkingZoneTypes.selected.indexOf(option);
      if (index >= 0) {
        this.options.parkingZoneTypes.selected.splice(index, 1);
      } else {
        this.options.parkingZoneTypes.selected.push(option);
      }
    },
    toggleParkingProvider(option) {
      const index = this.options.parkingProviders.selected.indexOf(option);
      if (index >= 0) {
        this.options.parkingProviders.selected.splice(index, 1);
      } else {
        this.options.parkingProviders.selected.push(option);
      }
    },
    toggleParkingType(option) {
      const index = this.options.carParkingTypes.selected.indexOf(option);
      if (index >= 0) {
        this.options.carParkingTypes.selected.splice(index, 1);
      } else {
        this.options.carParkingTypes.selected.push(option);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/generic.scss";
@import "@/scss/text.scss";
@import "@/scss/dialog.scss";
</style>

<style scoped lang="scss">
.vue-slider {
  margin: 30px 0 20px 0;
}
@media (min-width: 800px) {
  // #carPickerDialog::v-deep .vm--modal {
  //   overflow: visible !important;
  //   left: 243px !important;
  //   top: 153px !important;
  // }
}
</style>
